/* eslint-disable react/no-array-index-key */

import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { collectionStyle } from './Index';
import { months, origins } from 'views/Catalog/ArticlePrivateCatalog';

const styles = StyleSheet.create({
  name: {
    fontFamily: 'Lato Bold',
    fontSize: 14,
    marginBottom: 10,
    textTransform: 'uppercase',
  },
  container: {
    flex: 1,
    paddingTop: 30,
    paddingLeft: 15,
  },
  leftColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  title: {
    fontSize: 11,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  description: {
    fontSize: 11,
    color: '#65748B',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '4px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '4px',
  },
});

const Information = ({ article }) => (
  <View style={styles.container}>
    <Text style={styles.name}>{article && article.name}</Text>
    <View style={styles.leftColumn}>
      <View style={styles.row}>
        <Text style={styles.title}>Nombre responsable - </Text>
        <Text style={styles.description}>
          {article.responsibleNameNoRef
            ? 'Sin responsable'
            : article.responsibleFirstname + ' ' + article.responsibleLastname}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Colección - </Text>
        <Text style={styles.description}>
          {article && article.collection
            ? collectionStyle[article.collection].name
            : 'Sin datos'}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Nº de inventario - </Text>
        <Text style={styles.description}>
          {article && article.inventaryNumber}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Procedencia - </Text>
        <Text style={styles.description}>{origins[article.origin]}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Año/mes de procedencia - </Text>
        <Text style={styles.description}>
          {article.originDateNoRef
            ? 'Sin referencia de fecha'
            : article.originYear + '/' + months[article.originMonth]}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>
          Asociación a informes de sitio /contexto -{' '}
        </Text>
        <Text style={styles.description}>
          {article.associationContext ? 'Sí' : 'No'}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Año de ingreso MHAQ - </Text>
        <Text style={styles.description}>
          {article.yearEntry ? article.yearEntry : 'Sin referencia'}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Nº de caja - </Text>
        <Text style={styles.description}>{article.inventaryBox}</Text>
      </View>
      <View style={styles.column}>
        <Text style={styles.title}>Ubicación actual MHAQ - </Text>
        <Text style={styles.description}>
          {article.logUbications[0].observations}
        </Text>
      </View>
    </View>
  </View>
);

export default Information;
