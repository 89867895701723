import React, { useState } from 'react';
import { Box, FormControl, Button, Divider } from '@mui/material';
import {
  ContainerBox,
  Section,
  SubSection,
} from 'components/StylesForm/StylesForm';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import InputText from 'components/Input/InputText';
import CheckboxCustom from 'components/Checkbox/Checkbox';
import SelectText from 'components/Select/Select';
import RadioButton from 'components/RadioButton/RadioButton';
import {
  origin,
  originMonth,
  actualLocation,
  actualLocationItems,
  getActualLocation,
  getActualLocationItem,
} from './articlesArray';
import DateText from 'components/DateText/DateText';
import { todayDate } from 'utils/date';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { format, parseISO, isValid } from 'date-fns';
import ModalContext from 'components/Modal/Modal';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { v4 as uuidv4 } from 'uuid';

const ParentHidden = styled.div`
  padding-top: 1rem;
`;

const VisibleHidden = styled.div`
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: 0.3s;

  ${ParentHidden}:hover & {
    opacity: 1;
  }
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  title: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 20,
    paddingLeft: '16px',
  },
  formTitle: {
    paddingRight: '16px',
    textAlign: 'right',
  },
  textCenter: {
    textAlign: 'center',
  },
  gridLayout: {
    marginBottom: '32px',
  },
  selectErrorLayout: {
    marginBottom: '44px',
  },
  gridEditLog: {
    marginTop: '2rem',
    marginLeft: '4rem',
    marginBottom: '2rem',
  },
  checkboxLayout: {
    display: 'flex',
  },
  divider: {
    backgroundColor: '#EB8242',
  },
  subSectionTitle: {
    fontSize: 18,
    color: 'white',
  },
  subFormTitle: {
    paddingRight: '16px',
  },
  subTitle: {
    fontWeight: '500',
    fontSize: 18,
  },
  child: {
    display: 'flex',
    opacity: 0,
  },
}));

const AdministrativeInformation = ({
  values,
  setFieldValue,
  handleChange,
  errors,
  touched,
  setFieldTouched,
}) => {
  const classes = useStyles();
  const [editingLog, setEditingLog] = useState('');
  const [deletingLog, setDeletingLog] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const deleteLog = () => {
    const temp = values.logUbications.filter((l) => l.id !== deletingLog);
    setFieldValue('logUbications', temp);
    setDeletingLog('');
    setOpenDeleteModal(false);
  };

  return (
    <>
      <Box style={{ marginBottom: '1rem' }}>
        <Section title="información administrativa">
          <Box>
            <Grid container alignItems="center" className={classes.gridLayout}>
              <Grid item md={3}>
                <Typography className={classes.formTitle}>
                  Nombre del proyecto/causa/ingreso:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <InputText
                  variant="outlined"
                  placeholder="Nombre del proyecto"
                  required
                  fullWidth
                  name="name"
                  autoComplete="name"
                  value={values.name}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridLayout}>
              <Grid item md={3}>
                <Typography className={classes.formTitle}>
                  Nombre responsable:
                </Typography>
              </Grid>
              <Grid item container spacing={3} md={9}>
                <Grid item md={4}>
                  <InputText
                    variant="outlined"
                    placeholder="Nombre"
                    required
                    fullWidth
                    id="responsibleFirstname"
                    name="responsibleFirstname"
                    autoComplete="responsibleFirstname"
                    value={values.responsibleFirstname}
                    disabled={values.responsibleNameNoRef}
                  />
                </Grid>
                <Grid item md={4}>
                  <InputText
                    variant="outlined"
                    placeholder="Apellido"
                    required
                    fullWidth
                    id="responsibleLastname"
                    name="responsibleLastname"
                    autoComplete="responsibleLastname"
                    value={values.responsibleLastname}
                    disabled={values.responsibleNameNoRef}
                  />
                </Grid>
                <Grid
                  className={classes.checkboxLayout}
                  item
                  alignItems="center"
                  xs={6}
                  sm={3}
                  md={3}
                >
                  <CheckboxCustom
                    name="responsibleNameNoRef"
                    checked={values.responsibleNameNoRef}
                    value={values.responsibleNameNoRef}
                    label="Sin referencias"
                    onChange={(e) => {
                      setFieldValue('responsibleFirstname', null);
                      setFieldValue('responsibleLastname', null);
                      handleChange(e);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              className={
                errors.origin && touched.origin
                  ? classes.selectErrorLayout
                  : classes.gridLayout
              }
            >
              <Grid item md={3}>
                <Typography className={classes.formTitle}>
                  Procedencia:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <SelectText
                    placeholder="Seleccione procedencia"
                    required
                    fullWidth
                    value={values.origin}
                    name="origin"
                    list={origin}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </FormControl>
                <ErrorMessage name="origin" />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              className={
                (errors.originYear && touched.originYear) ||
                (errors.originMonth && touched.originMonth)
                  ? classes.selectErrorLayout
                  : classes.gridLayout
              }
            >
              <Grid item md={3}>
                <Typography className={classes.formTitle}>
                  Año/mes de procedencia:
                </Typography>
              </Grid>
              <Grid item container spacing={3} md={9}>
                <Grid item md={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputText
                      disabled={values.originDateNoRef}
                      variant="outlined"
                      placeholder="Año procedencia"
                      required
                      fullWidth
                      name="originYear"
                      value={values.originYear}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <SelectText
                      placeholder="Seleccione mes"
                      disabled={values.originDateNoRef}
                      required
                      fullWidth
                      name="originMonth"
                      list={originMonth}
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                  </FormControl>
                  <ErrorMessage name="originMonth" />
                </Grid>
                <Grid
                  className={classes.checkboxLayout}
                  item
                  alignItems="center"
                  xs={6}
                  sm={3}
                  md={3}
                >
                  <CheckboxCustom
                    name="originDateNoRef"
                    checked={values.originDateNoRef}
                    value={values.originDateNoRef}
                    label="Sin referencias"
                    onChange={(e) => {
                      setFieldValue('originYear', null);
                      setFieldValue('originMonth', null);
                      handleChange(e);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridLayout}>
              <Grid item md={3}>
                <Typography className={classes.formTitle}>
                  Asociación a informes de sitio /contexto:
                </Typography>
              </Grid>
              <Grid item md={1}>
                <RadioButton
                  checked={values.associationContext === 'true'}
                  name="associationContext"
                  type="radio"
                  value="true"
                  label="Si"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Grid>
              <Grid item md={1}>
                <RadioButton
                  checked={values.associationContext === 'false'}
                  name="associationContext"
                  type="radio"
                  value="false"
                  label="No"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridLayout}>
              <Grid item md={3}>
                <Typography className={classes.formTitle}>
                  Nº inventario:
                </Typography>
              </Grid>
              <Grid item md={3}>
                <InputText
                  variant="outlined"
                  placeholder="1A2B3C45D6"
                  required
                  fullWidth
                  name="inventaryNumber"
                  autoComplete="inventaryNumber"
                  value={values.inventaryNumber}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridLayout}>
              <Grid item md={3}>
                <Typography className={classes.formTitle}>Nº caja:</Typography>
              </Grid>
              <Grid item md={3}>
                <InputText
                  type="number"
                  variant="outlined"
                  placeholder="123456"
                  required
                  fullWidth
                  name="inventaryBox"
                  autoComplete="inventaryBox"
                  value={values.inventaryBox}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              className={
                errors.yearEntry && touched.yearEntry
                  ? classes.selectErrorLayout
                  : classes.gridLayout
              }
            >
              <Grid item md={3}>
                <Typography className={classes.formTitle}>
                  Año de ingreso MHAQ:
                </Typography>
              </Grid>
              <Grid item container spacing={3} md={9}>
                <Grid item md={4}>
                  <InputText
                    disabled={values.yearEntryNoRef}
                    variant="outlined"
                    placeholder="Año"
                    required
                    fullWidth
                    name="yearEntry"
                    autoComplete="yearEntry"
                    value={values.yearEntry}
                  />
                </Grid>
                <Grid
                  className={classes.checkboxLayout}
                  item
                  alignItems="center"
                  xs={6}
                  sm={3}
                  md={3}
                >
                  <CheckboxCustom
                    name="yearEntryNoRef"
                    checked={values.yearEntryNoRef}
                    value={values.yearEntryNoRef}
                    label="Sin referencias"
                    onChange={(e) => {
                      setFieldValue('yearEntry', null);
                      handleChange(e);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <SubSection title="Ubicación física">
              <Box>
                <Grid
                  container
                  alignItems="center"
                  className={classes.gridLayout}
                >
                  <Grid item md={3}>
                    <Typography className={classes.formTitle}>
                      Ubicación actual MHAQ:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <SelectText
                        required
                        fullWidth
                        name="actualLocation"
                        list={actualLocation}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        onBlur={(event) => {
                          if (event.target.value === 4) {
                            setFieldValue('logDate', todayDate);
                            setFieldValue(
                              'log',
                              `El objeto se encuentra en préstamo.`
                            );
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {values.actualLocation !== 4 && (
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                  >
                    <Grid item md={3}>
                      <Typography className={classes.formTitle}>
                        Detalle ubicación actual MHQA:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <SelectText
                          placeholder={
                            !values.actualLocation
                              ? 'Seleccione ubicación primero'
                              : 'Seleccione'
                          }
                          displayEmpty
                          disabled={
                            values.actualLocation === 4 ||
                            !values.actualLocation
                          }
                          required
                          fullWidth
                          name="actualLocationItem"
                          list={
                            values.actualLocation
                              ? actualLocationItems[values.actualLocation]
                              : []
                          }
                          onChange={(event) => {
                            handleChange(event);
                          }}
                          onBlur={(event) => {
                            setFieldValue('logDate', todayDate);
                            setFieldValue(
                              'log',
                              `El objeto se encuentra ${getActualLocation(
                                values.actualLocation
                              )} detalladamente en ${getActualLocationItem(
                                values.actualLocation,
                                values.actualLocationItem
                              )}.`
                            );
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {((values.actualLocation && values.actualLocationItem) ||
                  values.actualLocation === 4) && (
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                  >
                    <Grid item md={3}>
                      <Typography className={classes.formTitle}>
                        Bitácora actual:
                      </Typography>
                    </Grid>
                    <Grid item container spacing={2} md={9} alignItems="center">
                      <Grid item md="auto">
                        <DateText
                          id="date"
                          label="Fecha"
                          type="date"
                          name="logDate"
                          value={values.logDate}
                          sx={{ width: 220 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <InputText
                          variant="outlined"
                          multiline
                          maxRows={6}
                          required
                          fullWidth
                          name="log"
                          value={values.log}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(event) => {
                            handleChange(event);
                          }}
                        />
                      </Grid>
                      <Grid item md={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => {
                            if (
                              values.logUbications &&
                              values.logUbications.length > 0
                            ) {
                              const temp = values.logUbications;
                              if (values.actualLocation === 4) {
                                temp.push({
                                  id: uuidv4(),
                                  ubication: values.actualLocation,
                                  observations: values.log,
                                  option: 'En préstamo',
                                  date: values.logDate,
                                });
                              } else {
                                temp.push({
                                  id: uuidv4(),
                                  ubication: values.actualLocation,
                                  observations: values.log,
                                  option: getActualLocationItem(
                                    values.actualLocation,
                                    values.actualLocationItem
                                  ),
                                  date: values.logDate,
                                });
                              }
                              setFieldTouched('logUbications', false);
                              setFieldValue('logUbications', temp);
                            } else {
                              setFieldTouched('logUbications', false);
                              if (values.actualLocation === 4) {
                                setFieldValue('logUbications', [
                                  {
                                    id: uuidv4(),
                                    ubication: values.actualLocation,
                                    observations: values.log,
                                    option: 'En préstamo',
                                    date: values.logDate,
                                  },
                                ]);
                              } else {
                                setFieldValue('logUbications', [
                                  {
                                    id: uuidv4(),
                                    ubication: values.actualLocation,
                                    observations: values.log,
                                    option: getActualLocationItem(
                                      values.actualLocation,
                                      values.actualLocationItem
                                    ),
                                    date: values.logDate,
                                  },
                                ]);
                              }
                            }
                            setFieldValue('actualLocation', '');
                            setFieldValue('actualLocationItem', '');
                            setFieldValue('log', '');
                            setFieldValue('logDate', '');
                          }}
                        >
                          Agregar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <ContainerBox title="Historial de bitácoras">
                  <Box sx={{ overflow: 'scroll', maxHeight: '400px' }}>
                    {values.logUbications &&
                      values.logUbications.length > 0 &&
                      values.logUbications.map((log) => (
                        <React.Fragment key={log.id}>
                          {editingLog !== log.id && (
                            <ParentHidden>
                              <Grid
                                container
                                alignItems="flex-start"
                                className={classes.gridLayout}
                              >
                                <Grid
                                  item
                                  md={2}
                                  className={classes.textCenter}
                                >
                                  {format(parseISO(log.date), 'dd-MM-yyyy')}
                                </Grid>
                                <Grid item md={8}>
                                  {log.observations}
                                </Grid>
                                <Grid
                                  item
                                  md={2}
                                  className={classes.textCenter}
                                >
                                  <VisibleHidden>
                                    <IconButton
                                      aria-label="edit"
                                      onClick={() => {
                                        setFieldValue('logDateEdit', log.date);
                                        setFieldValue(
                                          'logEdit',
                                          log.observations
                                        );
                                        setEditingLog(log.id);
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => {
                                        setDeletingLog(log.id);
                                        setOpenDeleteModal(true);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </VisibleHidden>
                                </Grid>
                              </Grid>
                            </ParentHidden>
                          )}
                          {editingLog === log.id && (
                            <Grid
                              container
                              alignItems="center"
                              className={classes.gridEditLog}
                            >
                              <Grid
                                item
                                container
                                spacing={2}
                                md={12}
                                alignItems="center"
                              >
                                <Grid item md="auto">
                                  <DateText
                                    id="date"
                                    label="Fecha"
                                    type="date"
                                    name="logDateEdit"
                                    value={values.logDateEdit}
                                    sx={{ width: 220 }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item md={7}>
                                  <InputText
                                    variant="outlined"
                                    multiline
                                    maxRows={6}
                                    required
                                    fullWidth
                                    name="logEdit"
                                    value={values.logEdit}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(event) => {
                                      handleChange(event);
                                    }}
                                  />
                                </Grid>
                                <Grid item md={2}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => {
                                      if (
                                        isValid(parseISO(values.logDateEdit)) &&
                                        values.logEdit
                                      ) {
                                        const temp = values.logUbications;
                                        const edit = temp.find(
                                          (t) => t.id === editingLog
                                        );
                                        edit.date = values.logDateEdit;
                                        edit.observations = values.logEdit;
                                        setFieldTouched('logUbications', false);
                                        setFieldValue('logUbications', temp);
                                        setEditingLog('');
                                      }
                                    }}
                                  >
                                    Editar
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          <Divider
                            className={classes.divider}
                            variant="middle"
                          />
                        </React.Fragment>
                      ))}
                    {values.logUbications && values.logUbications.length === 0 && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: '2rem',
                        }}
                      >
                        <Typography variant="h6" color="#8b8b8b">
                          Esta ficha no cuenta con bitácoras.
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </ContainerBox>
              </Box>
            </SubSection>
          </Box>
        </Section>
      </Box>
      <ModalContext
        open={openDeleteModal}
        callback={() => deleteLog()}
        onClose={() => setOpenDeleteModal(false)}
        title="Eliminando bitácora"
        buttonTitle="Eliminar"
        content={
          <>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              ¿Estás seguro que deseas eliminar la bitácora? Si eliminas la
              bitácora seleccionada no habrá vuelta atrás.
            </Typography>
          </>
        }
      />
    </>
  );
};

export default AdministrativeInformation;
