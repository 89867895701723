import React, { useCallback, useContext, useState } from 'react';

export const ArticleDataContext = React.createContext({
  id: '',
  name: '',
  responsibleFirstname: null,
  responsibleLastname: null,
  responsibleNameNoRef: false,
  origin: null,
  originYear: null,
  originMonth: null,
  originDateNoRef: false,
  associationContext: false,
  inventaryNumber: '',
  inventaryBox: '',
  actualLocation: '',
  actualLocationItem: '',
  yearEntry: null,
  yearEntryNoRef: false,
  logDate: '',
  log: '',
  logDateEdit: '',
  logEdit: '',
  logUbications: [],

  siteName: null,
  siteCode: null,
  comune: null,
  locality: null,
  hasCoordinates: false,
  coordinateType: null,
  coordinateInfo: {
    utmEast: '',
    utmNorth: '',
    datum: '',
    datumNoRef: false,
    altitude: '',
    altitudeNoRef: false,
    latitude: '',
    longitude: '',
  },
  functionalities: [],
  otherFunctionality: null,
  localizationUnit: null,
  localizationUnitNoRef: false,
  localizationLayer: null,
  localizationLayerNoRef: false,
  localizationLevel: null,
  localizationLevelNoRef: false,
  localizationObservations: null,
  hasFuneralContext: false,
  isGuy: null,
  grave: '',
  graveNoRef: false,
  numberGuy: null,
  numberGuyNoRef: false,
  responsibleFuneralName: null,
  obtainingDateYear: null,
  obtainingDateNoRef: false,
  obtainingDateMonth: null,
  obtainingDateDay: null,
  period: null,
  periodNoRef: false,
  culturalAscription: null,
  culturalAscriptionNoRef: false,
  absoluteChronology: 'false',
  temporalityType: null,
  temporalityYearsBase: null,
  temporalityYearsSigma: null,
  temporalityCalibrated: 'false',
  temporalityCodeLab: null,
  temporalityCodeLabNoRef: false,
  temporalityMaterialDated: null,
  temporalityMaterialDatedNoRef: false,
  temporalityReferences: null,
  temporalityReferencesNoRef: false,
  observationsContext: null,

  collection: null,
  otherCollection: null,
  otherCollectionButton: 'false',
  otherCollectionDescription: null,
  recordCondition: null,
  material: [],
  materialDetails: [],
  otherMaterialDetails: '',
  typeManufacture: null,
  dimensions: {
    height: null,
    lenght: null,
    width: null,
    diameter: null,
    thickness: null,
  },
  typeFinding: null,
  observationsFinding: null,
  individual: null,
  materialIndividual: null,
  otherMaterialIndividual: '',
  completeness: null,
  otherCompleteness: null,
  numberIndividual: null,
  numberIndividualNoApply: false,
  sexIndividual: null,
  ageIndividual: null,
  ageIndividualNoApply: false,

  conservationState: null,
  completenessIdentification: null,
  sampling: false,
  samplingAssociateProject: null,
  samplingResponsibleInvestigator: null,
  samplingDate: null,
  observationsIdentification: null,
  hasPhotographyMaterial: null,
  hasPhotographyLabel: null,
  setArticleAttribute: (attribute, value) => {},
  setArticleData: () => {},
  clear: () => {},
});

export const ArticleDataContextConsumer = ArticleDataContext.Consumer;

const initialData = {
  id: '',
  name: '',
  responsibleFirstname: null,
  responsibleLastname: null,
  responsibleNameNoRef: false,
  origin: null,
  originYear: null,
  originMonth: null,
  originDateNoRef: false,
  associationContext: false,
  inventaryNumber: '',
  inventaryBox: '',
  actualLocation: '',
  actualLocationItem: '',
  yearEntry: null,
  yearEntryNoRef: false,
  logDate: '',
  log: '',
  logDateEdit: '',
  logEdit: '',
  logUbications: [],

  siteName: null,
  siteCode: null,
  comune: null,
  locality: null,
  hasCoordinates: false,
  coordinateType: null,
  coordinateInfo: {
    utmEast: '',
    utmNorth: '',
    datum: '',
    datumNoRef: false,
    altitude: '',
    altitudeNoRef: false,
    latitude: '',
    longitude: '',
  },
  functionalities: [],
  otherFunctionality: null,
  localizationUnit: null,
  localizationUnitNoRef: false,
  localizationLayer: null,
  localizationLayerNoRef: false,
  localizationLevel: null,
  localizationLevelNoRef: false,
  localizationObservations: null,
  hasFuneralContext: false,
  isGuy: null,
  grave: '',
  graveNoRef: false,
  numberGuy: null,
  numberGuyNoRef: false,
  responsibleFuneralName: null,
  obtainingDateYear: null,
  obtainingDateNoRef: false,
  obtainingDateMonth: null,
  obtainingDateDay: null,
  period: null,
  periodNoRef: false,
  culturalAscription: null,
  culturalAscriptionNoRef: false,
  absoluteChronology: 'false',
  temporalityType: null,
  temporalityYearsBase: null,
  temporalityYearsSigma: null,
  temporalityCalibrated: 'false',
  temporalityCodeLab: null,
  temporalityCodeLabNoRef: false,
  temporalityMaterialDated: null,
  temporalityMaterialDatedNoRef: false,
  temporalityReferences: null,
  temporalityReferencesNoRef: false,
  observationsContext: null,

  collection: null,
  otherCollection: null,
  otherCollectionButton: 'false',
  otherCollectionDescription: null,
  recordCondition: null,
  material: [],
  materialDetails: [],
  otherMaterialDetails: '',
  typeManufacture: null,
  dimensions: {
    height: null,
    lenght: null,
    width: null,
    diameter: null,
    thickness: null,
  },
  typeFinding: null,
  observationsFinding: null,
  individual: null,
  materialIndividual: null,
  otherMaterialIndividual: '',
  completeness: null,
  otherCompleteness: null,
  numberIndividual: null,
  numberIndividualNoApply: false,
  sexIndividual: null,
  ageIndividual: null,
  ageIndividualNoApply: false,

  conservationState: null,
  completenessIdentification: null,
  sampling: false,
  samplingAssociateProject: null,
  samplingResponsibleInvestigator: null,
  samplingDate: null,
  observationsIdentification: null,
  hasPhotographyMaterial: null,
  hasPhotographyLabel: null,
};

const savedArticleData =
  JSON.parse(localStorage.getItem('articleData')) || initialData;

const saveArticleData = (value) => {
  localStorage.setItem('articleData', JSON.stringify(value));
};

export const useArticleDataContext = () => useContext(ArticleDataContext);

export const ArticleDataContextProvider = ({ children }) => {
  const [articleData, setArticleData] = useState(savedArticleData);
  const setArticleAttribute = useCallback(
    (attribute, value) => {
      setArticleData((prevArticleData) => {
        const newArticleData = {
          ...prevArticleData,
          [attribute]: value,
        };
        saveArticleData(newArticleData);

        return newArticleData;
      });
    },
    [setArticleData]
  );

  const contextValues = {
    ...articleData,
  };

  return (
    <ArticleDataContext.Provider
      value={{
        ...contextValues,
        clear: () => {
          setArticleData(initialData);
          setArticleAttribute('id', '');
        },
        setArticleData,
        setArticleAttribute,
      }}
    >
      {children}
    </ArticleDataContext.Provider>
  );
};
