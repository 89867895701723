import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { Section, SubSection } from 'components/StylesForm/StylesForm';
import {
  Alert,
  Button,
  Divider,
  Fab,
  Grid,
  Skeleton,
  Snackbar,
  Typography,
} from '@mui/material';
import RadioButton from 'components/RadioButton/RadioButton';
import InputText from 'components/Input/InputText';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import MoodBadOutlinedIcon from '@mui/icons-material/MoodBadOutlined';
import {
  PdfContainer,
  RadioLabel,
  RadioPercentageContainer,
} from './MaterialIdentification';
import DateText from 'components/DateText/DateText';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import TableComponent from 'components/Table/Table';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import { useArticleDataContext } from 'contexts/ArticleDataContext';
import { getData } from 'api/queries';
import { Card } from './ArticlesHome';
import ClearIcon from '@mui/icons-material/Clear';
import ModalContext from 'components/Modal/Modal';
import { useDeletePhotoMutation } from 'api/mutations';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  title: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 20,
    paddingLeft: '16px',
  },
  formTitle: {
    paddingRight: '16px',
    textAlign: 'right',
  },
  gridLayout: {
    marginBottom: '32px',
  },
  checkboxLayout: {
    display: 'flex',
  },
  divider: {
    backgroundColor: '#EB8242',
  },
  subSectionTitle: {
    fontSize: 18,
    color: 'white',
  },
  subFormTitle: {
    paddingRight: '16px',
  },
  subTitle: {
    fontWeight: '500',
    fontSize: 18,
  },
}));

const ObjectIdentification = ({
  values,
  setFieldValue,
  handleChange,
  stepNumber,
  uploadPdfSampling,
  uploadLabelPhoto,
  uploadMaterialPhoto,
}) => {
  const classes = useStyles();
  const [skeletonLabelPhotos, setSkeletonLabelPhotos] = useState();
  const [skeletonMaterialPhotos, setSkeletonMaterialPhotos] = useState();
  const [selectedFile, setSelectedFiles] = useState(true);
  const [files, setFiles] = useState([]);
  const [labelPhotos, setLabelPhotos] = useState([]);
  const [materialPhotos, setMaterialPhotos] = useState([]);
  const { id, collection } = useArticleDataContext();
  const [showDeleteLabel, setShowDeleteLabel] = useState(false);
  const [showDeleteMaterial, setShowDeleteMaterial] = useState(false);
  const [deletedIdPhoto, setDeletedIdPhoto] = useState(false);
  const [openDeleteLabelPhotoModal, setOpenDeleteLabelPhotoModal] =
    useState(false);
  const [openDeleteMaterialPhotoModal, setOpenDeleteMaterialPhotoModal] =
    useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState({
    message: '',
    type: '',
  });
  const onCloseAlert = () => setOpenAlert(false);
  const [deletePhotoMutation] = useDeletePhotoMutation(deletedIdPhoto);

  const getFilesByArticle = async () => {
    await getData(`/uploads?article=${id}&type=sampling`).then((response) => {
      setFiles(response);
    });
  };

  const getLabelPhotosByArticle = async () => {
    setSkeletonLabelPhotos(true);
    await getData(`/uploads?article=${id}&type=label_photography`).then(
      (response) => {
        setLabelPhotos(response);
        setSkeletonLabelPhotos(false);
      }
    );
  };

  const getMaterialPhotosByArticle = async () => {
    setSkeletonMaterialPhotos(true);
    await getData(`/uploads?article=${id}&type=material_photography`).then(
      (response) => {
        setMaterialPhotos(response);
        setSkeletonMaterialPhotos(false);
      }
    );
  };

  useEffect(() => {
    getFilesByArticle();
    getLabelPhotosByArticle();
    getMaterialPhotosByArticle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteImages = async (deleteId, callback, setModal) => {
    await deletePhoto()
      .then(() => {
        setMessageAlert({
          message: `La imagen se ha eliminado correctamente.`,
          type: 'success',
        });
        setOpenAlert(true);
        setModal(false);
        callback();
      })
      .catch((e) => {
        setMessageAlert({
          message: 'Ha ocurrido un error al guardar la colección.',
          type: 'error',
        });
      });
  };

  const deletePhoto = () => {
    return deletePhotoMutation({});
  };

  return (
    <>
      <Box sx={{ marginBottom: '1rem' }}>
        <Section title="Identificación del objeto">
          <SubSection title="Estado de conservación">
            <Grid
              container
              alignItems="center"
              className={classes.gridLayout}
              style={{ marginTop: '2rem' }}
            >
              <Grid item md={2}>
                <RadioButton
                  checked={Number(values.conservationState) === 1}
                  name="conservationState"
                  type="radio"
                  value="1"
                  label={
                    <div>
                      Muy bueno <EmojiEmotionsOutlinedIcon />
                    </div>
                  }
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </Grid>
              <Grid item md={2}>
                <RadioButton
                  checked={Number(values.conservationState) === 2}
                  name="conservationState"
                  type="radio"
                  value="2"
                  label={
                    <div>
                      Bueno <SentimentSatisfiedOutlinedIcon />
                    </div>
                  }
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </Grid>
              <Grid item md={2}>
                <RadioButton
                  checked={Number(values.conservationState) === 3}
                  name="conservationState"
                  type="radio"
                  value="3"
                  label={
                    <div>
                      Regular <SentimentSatisfiedIcon />
                    </div>
                  }
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </Grid>
              <Grid item md={2}>
                <RadioButton
                  checked={Number(values.conservationState) === 4}
                  name="conservationState"
                  type="radio"
                  value="4"
                  label={
                    <div>
                      Malo <SentimentDissatisfiedOutlinedIcon />
                    </div>
                  }
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </Grid>
              <Grid item md={2}>
                <RadioButton
                  checked={Number(values.conservationState) === 5}
                  name="conservationState"
                  type="radio"
                  value="5"
                  label={
                    <div>
                      Muy malo <MoodBadOutlinedIcon />
                    </div>
                  }
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </Grid>
            </Grid>
            {collection !== 3 && (
              <>
                <Divider />
                <Grid
                  container
                  alignItems="center"
                  className={classes.gridLayout}
                  style={{ marginTop: '2rem' }}
                >
                  <Grid item md={2}>
                    <Typography className={classes.subFormTitle}>
                      Completitud:
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <RadioPercentageContainer>
                      <RadioButton
                        checked={values.completenessIdentification === '1'}
                        name="completenessIdentification"
                        type="radio"
                        value="1"
                        label="Completo"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                      <RadioLabel
                        active={
                          values.completenessIdentification === '1'
                            ? true
                            : false
                        }
                      >
                        100%
                      </RadioLabel>
                    </RadioPercentageContainer>
                  </Grid>
                  <Grid item md={2}>
                    <RadioPercentageContainer>
                      <RadioButton
                        checked={values.completenessIdentification === '2'}
                        name="completenessIdentification"
                        type="radio"
                        value="2"
                        label="Semicompleto"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                      <RadioLabel
                        active={
                          values.completenessIdentification === '2'
                            ? true
                            : false
                        }
                      >
                        75%
                      </RadioLabel>
                    </RadioPercentageContainer>
                  </Grid>
                  <Grid item md={2}>
                    <RadioPercentageContainer>
                      <RadioButton
                        checked={values.completenessIdentification === '3'}
                        name="completenessIdentification"
                        type="radio"
                        value="3"
                        label="Incompleto"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                      <RadioLabel
                        active={
                          values.completenessIdentification === '3'
                            ? true
                            : false
                        }
                      >
                        50%
                      </RadioLabel>
                    </RadioPercentageContainer>
                  </Grid>
                  <Grid item md={2}>
                    <RadioPercentageContainer>
                      <RadioButton
                        checked={values.completenessIdentification === '4'}
                        name="completenessIdentification"
                        type="radio"
                        value="4"
                        label="Altamente incompleto"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                      <RadioLabel
                        active={
                          values.completenessIdentification === '4'
                            ? true
                            : false
                        }
                      >
                        50% - 25%
                      </RadioLabel>
                    </RadioPercentageContainer>
                  </Grid>
                  <Grid item md="auto" style={{ marginBottom: '28px' }}>
                    <RadioPercentageContainer>
                      <RadioButton
                        checked={values.completenessIdentification === '5'}
                        name="completenessIdentification"
                        type="radio"
                        value="5"
                        label="No aplica"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                    </RadioPercentageContainer>
                  </Grid>
                </Grid>
              </>
            )}
          </SubSection>
          <SubSection title="Toma de muestras">
            <Grid container alignItems="center" className={classes.gridLayout}>
              <Grid item md={1}>
                <RadioButton
                  checked={values.sampling === 'true'}
                  name="sampling"
                  type="radio"
                  value="true"
                  label="Si"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Grid>
              <Grid item md={1}>
                <RadioButton
                  checked={values.sampling === 'false'}
                  name="sampling"
                  type="radio"
                  value="false"
                  label="No"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Grid>
            </Grid>
            {values.sampling === 'true' && (
              <>
                <Grid
                  container
                  alignItems="center"
                  className={classes.gridLayout}
                >
                  <Grid item md={2}>
                    <Typography className={classes.subFormTitle}>
                      Proyecto asociado
                    </Typography>
                  </Grid>
                  <Grid item container md={6}>
                    <Grid item md={6}>
                      <InputText
                        variant="outlined"
                        required
                        fullWidth
                        name="samplingAssociateProject"
                        value={values.samplingAssociateProject}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  className={classes.gridLayout}
                >
                  <Grid item md={2}>
                    <Typography className={classes.subFormTitle}>
                      Investigador responsable:
                    </Typography>
                  </Grid>
                  <Grid item container md={6}>
                    <Grid item md={6}>
                      <InputText
                        variant="outlined"
                        required
                        fullWidth
                        name="samplingResponsibleInvestigator"
                        value={values.samplingResponsibleInvestigator}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  className={classes.gridLayout}
                >
                  <Grid item md={2}>
                    <Typography className={classes.subFormTitle}>
                      Fecha
                    </Typography>
                  </Grid>
                  <Grid item container md={6}>
                    <Grid item md={6}>
                      <DateText
                        label="Fecha"
                        type="date"
                        name="samplingDate"
                        value={values.samplingDate}
                        sx={{ width: 220 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <Typography
                  component="div"
                  style={{
                    marginTop: '2rem',
                    fontWeight: 500,
                    display: 'flex',
                  }}
                >
                  <MenuBookOutlinedIcon
                    color="primary"
                    style={{ marginRight: '8px' }}
                  />{' '}
                  Bitacora de toma de muestras
                </Typography>
                <Box sx={{ width: '60%', marginTop: '2rem' }}>
                  <TableComponent rows={files}></TableComponent>
                </Box>
                <PdfContainer style={{ marginBottom: '2rem' }}>
                  <Typography
                    variant="h6"
                    component="div"
                    style={{ marginBottom: '4px' }}
                  >
                    Subir PDF: {selectedFile && selectedFile[0]?.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    style={{ fontStyle: 'italic', marginBottom: '1rem' }}
                  >
                    Cambiar nombre del documento
                  </Typography>
                  <InputText
                    variant="outlined"
                    required
                    fullWidth
                    name="pdfNameSampling"
                    value={values.pdfNameSampling}
                  />
                  <Typography
                    variant="body2"
                    component="div"
                    style={{ fontStyle: 'italic', marginTop: '10px' }}
                  >
                    El archivo solo puede ser en formato .pdf y no puede superar
                    1GB (se puede demorar hasta 60 segundos en subir el
                    archivo).
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      marginTop: '1rem',
                      justifyContent: 'space-between',
                    }}
                  >
                    <label htmlFor="uploadPhotoSampling">
                      <input
                        style={{ display: 'none' }}
                        id="uploadPhotoSampling"
                        name="uploadPhotoSampling"
                        type="file"
                        onChange={(e) => {
                          setSelectedFiles(e.target.files);
                        }}
                      />
                      <Fab
                        style={{ zIndex: 0 }}
                        color="primary"
                        component="span"
                        aria-label="add"
                        variant="extended"
                      >
                        <UploadFileIcon sx={{ mr: 1 }} /> Examinar archivo
                      </Fab>
                    </label>
                    <Button
                      variant="contained"
                      endIcon={<AddIcon />}
                      onClick={() => {
                        uploadPdfSampling(
                          selectedFile,
                          setSelectedFiles,
                          getFilesByArticle
                        );
                      }}
                    >
                      Agregar pdf
                    </Button>
                  </Box>
                </PdfContainer>
              </>
            )}
          </SubSection>
          <SubSection title="Fotografía material">
            <Grid container alignItems="center" className={classes.gridLayout}>
              <Grid item md={2}>
                <Typography className={classes.subFormTitle}>
                  Foto etiqueta:
                </Typography>
              </Grid>
              <Grid item md={1}>
                <RadioButton
                  checked={values.hasPhotographyLabel === 'true'}
                  name="hasPhotographyLabel"
                  type="radio"
                  value="true"
                  label="Si"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Grid>
              <Grid item md={1}>
                <RadioButton
                  checked={values.hasPhotographyLabel === 'false'}
                  name="hasPhotographyLabel"
                  type="radio"
                  value="false"
                  label="No"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Grid>
            </Grid>
            {values.hasPhotographyLabel === 'true' && (
              <>
                <Grid
                  container
                  alignItems="center"
                  className={classes.gridLayout}
                >
                  <Grid item md={2}>
                    <label htmlFor="uploadPhotoLabel">
                      <input
                        style={{ display: 'none' }}
                        id="uploadPhotoLabel"
                        name="uploadPhotoLabel"
                        type="file"
                        onChange={(e) => {
                          setSkeletonLabelPhotos(true);
                          uploadLabelPhoto(
                            e.target.files,
                            getLabelPhotosByArticle
                          );
                          document.getElementById('uploadPhotoLabel').value =
                            null;
                        }}
                      />
                      <Fab
                        style={{ zIndex: 0 }}
                        color="primary"
                        component="span"
                        aria-label="add"
                        variant="extended"
                      >
                        <UploadFileIcon sx={{ mr: 1 }} /> Agregar foto
                      </Fab>
                    </label>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    maxHeight: '340px',
                    overflow: 'auto',
                    overflowY: 'scroll',
                    padding: '16px',
                    backgroundColor: '#F0F0F0',
                    borderRadius: '8px',
                  }}
                >
                  {labelPhotos.length > 0 && !skeletonLabelPhotos && (
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      {labelPhotos.map((photo, index) => {
                        return (
                          <Grid item xs={2} sm={3} md={3} key={index}>
                            <Card
                              style={{
                                height: '200px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '1rem',
                                position: 'relative',
                              }}
                              onMouseOver={() => setShowDeleteLabel(photo.id)}
                            >
                              {showDeleteLabel === photo.id && (
                                <Fab
                                  size="small"
                                  color="third"
                                  aria-label="add"
                                  style={{
                                    position: 'absolute',
                                    top: '-10px',
                                    right: '-12px',
                                    width: '35px',
                                    height: '35px',
                                    transition: '0.3s',
                                    zIndex: 1,
                                  }}
                                  onClick={() => {
                                    setDeletedIdPhoto(photo.id);
                                    setOpenDeleteLabelPhotoModal(true);
                                  }}
                                >
                                  <ClearIcon />
                                </Fab>
                              )}
                              <img
                                style={{ maxHeight: '100px' }}
                                src={
                                  process.env.REACT_APP_URL_STORAGE + photo.url
                                }
                                alt="Imagenes"
                              />
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                  {labelPhotos.length === 0 && !skeletonLabelPhotos && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        fontSize: '16px',
                        color: '#8d8d8d',
                        fontWeight: '500',
                        height: '5rem',
                      }}
                    >
                      Aún no se han agregado fotos de etiqueta, prueba añadiendo
                      una.
                    </Box>
                  )}
                  {skeletonLabelPhotos && (
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      {Array.from(Array(4)).map((_, index) => (
                        <Grid item xs={6} sm={3} md={3} key={index}>
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height={200}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Box>
                <Box
                  sx={{
                    paddingTop: '1rem',
                    paddingLeft: '1rem',
                    fontStyle: 'italic',
                    color: '#979797',
                  }}
                >
                  *Las fotos de etiqueta agregadas se verán en su máximo tamaño
                  en el apartado de colecciones.
                </Box>
              </>
            )}
            <Grid
              container
              alignItems="center"
              className={classes.gridLayout}
              style={{ marginTop: '2rem' }}
            >
              <Grid item md={2}>
                <Typography className={classes.subFormTitle}>
                  Foto de material:
                </Typography>
              </Grid>
              <Grid item md={1}>
                <RadioButton
                  checked={values.hasPhotographyMaterial === 'true'}
                  name="hasPhotographyMaterial"
                  type="radio"
                  value="true"
                  label="Si"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Grid>
              <Grid item md={1}>
                <RadioButton
                  checked={values.hasPhotographyMaterial === 'false'}
                  name="hasPhotographyMaterial"
                  type="radio"
                  value="false"
                  label="No"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Grid>
            </Grid>
            {values.hasPhotographyMaterial === 'true' && (
              <>
                <Grid
                  container
                  alignItems="center"
                  className={classes.gridLayout}
                >
                  <Grid item md={2}>
                    <label htmlFor="uploadPhotoMaterial">
                      <input
                        accept="image/png, image/jpg, image/jpeg, image/JPG"
                        style={{ display: 'none' }}
                        id="uploadPhotoMaterial"
                        name="uploadPhotoMaterial"
                        type="file"
                        onChange={(e) => {
                          setSkeletonMaterialPhotos(true);
                          uploadMaterialPhoto(
                            e.target.files,
                            getMaterialPhotosByArticle
                          );
                          document.getElementById('uploadPhotoMaterial').value =
                            null;
                        }}
                      />
                      <Fab
                        style={{ zIndex: 0 }}
                        color="primary"
                        component="span"
                        aria-label="add"
                        variant="extended"
                      >
                        <UploadFileIcon sx={{ mr: 1 }} /> Agregar foto
                      </Fab>
                    </label>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    maxHeight: '340px',
                    overflow: 'auto',
                    overflowY: 'scroll',
                    padding: '16px',
                    backgroundColor: '#F0F0F0',
                    borderRadius: '8px',
                  }}
                >
                  {materialPhotos.length > 0 && !skeletonMaterialPhotos && (
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      {materialPhotos.map((photo, index) => {
                        return (
                          <Grid item xs={2} sm={3} md={3} key={index}>
                            <Card
                              style={{
                                height: '200px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '1rem',
                                position: 'relative',
                              }}
                              onMouseOver={() =>
                                setShowDeleteMaterial(photo.id)
                              }
                            >
                              {showDeleteMaterial === photo.id && (
                                <Fab
                                  size="small"
                                  color="third"
                                  aria-label="add"
                                  style={{
                                    position: 'absolute',
                                    top: '-10px',
                                    right: '-12px',
                                    width: '35px',
                                    height: '35px',
                                    transition: '0.3s',
                                    zIndex: 1,
                                  }}
                                  onClick={() => {
                                    setDeletedIdPhoto(photo.id);
                                    setOpenDeleteMaterialPhotoModal(true);
                                  }}
                                >
                                  <ClearIcon />
                                </Fab>
                              )}
                              <img
                                style={{ maxHeight: '100px' }}
                                src={
                                  process.env.REACT_APP_URL_STORAGE + photo.url
                                }
                                alt="Imagenes"
                              />
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                  {materialPhotos.length === 0 && !skeletonMaterialPhotos && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        fontSize: '16px',
                        color: '#8d8d8d',
                        fontWeight: '500',
                        height: '5rem',
                      }}
                    >
                      Aún no se han agregado fotos de material, prueba añadiendo
                      una.
                    </Box>
                  )}
                  {skeletonMaterialPhotos && (
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      {Array.from(Array(4)).map((_, index) => (
                        <Grid item xs={6} sm={3} md={3} key={index}>
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height={200}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Box>
                <Box
                  sx={{
                    paddingTop: '1rem',
                    paddingLeft: '1rem',
                    fontStyle: 'italic',
                    color: '#979797',
                  }}
                >
                  *Las fotos de material agregadas se verán en su máximo tamaño
                  en el apartado de colecciones.
                </Box>
              </>
            )}
          </SubSection>
          <SubSection title="Observaciones generales">
            <Grid container alignItems="center" className={classes.gridLayout}>
              <Grid item md="auto" style={{ marginBottom: '8px' }}>
                <Typography color="#979797" fontStyle="italic">
                  Escriba en el recuadro sus observaciones considere poner su
                  nombre y fecha al final del texto
                </Typography>
              </Grid>
              <Grid item md={12}>
                <InputText
                  variant="outlined"
                  multiline
                  rows={6}
                  required
                  fullWidth
                  name="observationsIdentification"
                  value={values.observationsIdentification}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </Grid>
            </Grid>
          </SubSection>
        </Section>
      </Box>
      <ModalContext
        open={openDeleteLabelPhotoModal}
        callback={() => {
          deleteImages(
            deletedIdPhoto,
            getLabelPhotosByArticle,
            setOpenDeleteLabelPhotoModal
          );
        }}
        onClose={() => setOpenDeleteLabelPhotoModal(false)}
        title="Eliminando fotografía de etiqueta"
        buttonTitle="Eliminar"
        content={
          <>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              ¿Estás seguro que deseas eliminar la fotografía? Si eliminas la
              fotografía seleccionada no habrá vuelta atrás.
            </Typography>
          </>
        }
      />
      <ModalContext
        open={openDeleteMaterialPhotoModal}
        callback={() =>
          deleteImages(
            deletedIdPhoto,
            getMaterialPhotosByArticle,
            setOpenDeleteMaterialPhotoModal
          )
        }
        onClose={() => setOpenDeleteMaterialPhotoModal(false)}
        title="Eliminando fotografía de material"
        buttonTitle="Eliminar"
        content={
          <>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              ¿Estás seguro que deseas eliminar la fotografía? Si eliminas la
              fotografía seleccionada no habrá vuelta atrás.
            </Typography>
          </>
        }
      />
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={onCloseAlert}
      >
        <Alert
          severity={messageAlert.type}
          sx={{ width: '100%' }}
          onClose={onCloseAlert}
        >
          {messageAlert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ObjectIdentification;
