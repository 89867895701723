import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import css from '@styled-system/css';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonIcon from '@mui/icons-material/Person';
import { Box } from '@mui/system';
import { Form, Formik } from 'formik';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import InputText from '../../components/Input/InputText';
import isValidEmail from '../../utils/isValidEmail';
import { useUserDataContext } from '../../contexts/UserDataContext';
import { postData } from '../../api/queries';
import { Hidden } from '@mui/material';
import logo from 'assets/images/logo.png';
import portada from 'assets/images/portada.png';
import ModalContext from 'components/Modal/Modal';

const Container = styled.div(
  css({
    display: 'flex',
    backgroundColor: '#FCF6EC',
    padding: '5rem',
    flexDirection: 'column',
    position: 'relative',
    minHeight: '100%',
  })
);

const Divider = styled.div(
  css({
    width: '100%',
    backgroundColor: '#DDD4C5',
    height: '45px',
    borderRadius: '10px',
  })
);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setLogin, endLogin, saveUserData } = useUserDataContext();
  const [loginError, setLoginError] = useState(false);
  const [openRecoveryModal, setRecoveryModal] = useState(false);

  return (
    <>
      <Hidden smDown={true}>
        <Container>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <img
              src={logo}
              alt="Logo museo"
              style={{
                width: '12rem',
                position: 'absolute',
                top: '1rem',
                right: '1rem',
              }}
            />
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: '28rem' }}>
              <Typography variant="h3" color="#999388" fontWeight="500">
                Bienvenido
              </Typography>
              <Typography variant="h5" color="#263238" mb="1rem">
                Al sistema de registro de colecciones del{' '}
                <Typography variant="h5" fontWeight="500">
                  Museo Histórico Arqueológico de Quillota.
                </Typography>{' '}
                Aquí encontrarás un completo catálogo de las colecciones que
                tenemos en custodia.
              </Typography>
              <Typography variant="h4" color="#EB8242" mb="1rem">
                ¡Te invitamos a recorrerlo!
              </Typography>
            </Box>
            <Box sx={{ position: 'relative', width: 'calc(100% - 28rem)' }}>
              <img
                style={{
                  width: '40rem',
                  position: 'absolute',
                  top: '-4rem',
                  right: '5rem',
                }}
                src={portada}
                alt="portada"
              />
            </Box>
          </Box>
          <Divider></Divider>
          <Grid container spacing={0} mt="2rem">
            <Grid item md={8}>
              <Typography variant="h4" color="#C2B08F" mb="8px">
                ¿Eres nuevo?
              </Typography>
              <Typography variant="h5" color="#263238" mb="8px">
                Regístrate en simples pasos.
              </Typography>
              <Button
                onClick={() => history.push('/registro')}
                variant="contained"
                color="primary"
                endIcon={<PersonAddAltIcon />}
              >
                Quiero registrarme
              </Button>
            </Grid>
            <Grid item md={4} justifyContent="flex-end">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: '20px',
                  padding: '1rem 2rem',
                  background: 'white',
                  boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
                }}
              >
                <Typography variant="h5" color="initial" align="center">
                  Iniciar sesión
                </Typography>
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                    remember: '',
                  }}
                  initialErrors={{
                    email: '',
                    password: '',
                  }}
                  validate={(values) => {
                    const errors = {};

                    if (!values.email) {
                      errors.email = 'El correo es un campo requerido.';
                    } else if (!isValidEmail(values.email)) {
                      errors.email = 'No es un correo válido.';
                    }

                    if (!values.password) {
                      errors.password = 'La contraseña es un campo requerido.';
                    }

                    return errors;
                  }}
                  onSubmit={async (values) => {
                    localStorage.clear();
                    sessionStorage.clear();
                    await postData('/users/login/', {
                      email: values.email,
                      password: values.password,
                    })
                      .then((data) => {
                        setLoginError(false);
                        if (data.statusCode === 400) {
                          setLoginError(true);
                          return false;
                        }
                        if (data.statusCode === 403) {
                          setRecoveryModal(true);
                          return false;
                        }
                        if (data.accessToken) {
                          setLogin(data.accessToken);
                          saveUserData(data.user);
                          if (data.isStaff) {
                            window.location.assign('/museo/articulos');
                          } else {
                            window.location.assign('/museo/catalogo');
                          }
                        }
                        endLogin();
                      })
                      .catch((e) => {
                        setLoginError(true);
                      });
                  }}
                >
                  {({ values, isSubmitting, handleSubmit, isValid }) => {
                    return (
                      <Form onSubmit={handleSubmit} className={classes.form}>
                        <InputText
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          label="Correo electrónico"
                          name="email"
                          autoComplete="email"
                          value={values.email}
                        />
                        <InputText
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          label="Contraseña"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                        />
                        {loginError && (
                          <div style={{ color: '#D14343' }}>
                            Credenciales incorrectas.
                          </div>
                        )}
                        <Button
                          style={{ marginTop: '1rem', marginBottom: '1rem' }}
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={isSubmitting || !isValid}
                          endIcon={<PersonIcon />}
                        >
                          Iniciar sesión
                        </Button>
                        <Grid container>
                          <Grid item xs>
                            <Link href="/recuperar" variant="body2">
                              Olvidaste tu contraseña?
                            </Link>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Hidden>
      <Hidden smUp={true}>
        <Container
          style={{
            padding: '2rem',
            alignItems: 'center',
          }}
        >
          <img
            style={{ width: '13rem', marginBottom: '2rem' }}
            src={logo}
            alt="Logo museo"
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '20px',
              padding: '1rem 2rem',
              background: 'white',
              boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
            }}
          >
            <Typography variant="h5" color="initial" align="center">
              Iniciar sesión
            </Typography>
            <Formik
              initialValues={{
                email: '',
                password: '',
                remember: '',
              }}
              validate={(values) => {
                const errors = {};

                if (!values.email) {
                  errors.email = 'El correo es un campo requerido.';
                } else if (!isValidEmail(values.email)) {
                  errors.email = 'No es un correo válido.';
                }

                if (!values.password) {
                  errors.password = 'La contraseña es un campo requerido.';
                }

                return errors;
              }}
            >
              {({ values, isSubmitting, isValid, setSubmitting }) => {
                const handleSubmit = async () => {
                  localStorage.clear();
                  sessionStorage.clear();
                  await postData('/users/login/', {
                    email: values.email,
                    password: values.password,
                  })
                    .then((data) => {
                      setLoginError(false);
                      if (data.statusCode === 400) {
                        setSubmitting(false);
                        setLoginError(true);
                        return false;
                      }
                      if (data.statusCode === 403) {
                        setSubmitting(false);
                        setRecoveryModal(true);
                        return false;
                      }
                      if (data.accessToken) {
                        setLogin(data.accessToken);
                        saveUserData(data.user);
                        if (data.isStaff) {
                          window.location.assign('/museo/articulos');
                        } else {
                          window.location.assign('/museo/catalogo');
                        }
                      }
                      endLogin();
                    })
                    .catch((e) => {
                      setSubmitting(false);
                      setLoginError(true);
                    });
                };
                return (
                  <Form className={classes.form}>
                    <InputText
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Correo electrónico"
                      name="email"
                      autoComplete="email"
                      value={values.email}
                    />
                    <InputText
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Contraseña"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                    />
                    <Button
                      style={{ marginTop: '1rem', marginBottom: '1rem' }}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={isSubmitting || !isValid}
                      endIcon={<PersonIcon />}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Iniciar sesión
                    </Button>
                    {loginError && (
                      <div style={{ color: '#D14343' }}>
                        Credenciales incorrectas.
                      </div>
                    )}
                    <Grid container>
                      <Grid item xs>
                        <Link href="/recuperar" variant="body2">
                          Olvidaste tu contraseña?
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '0.5rem' }}>
                      <Grid item xs>
                        <Typography variant="body2">
                          ¿No tienes cuenta?{' '}
                          <Link href="/registro" variant="body2">
                            Cree una.
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Container>
      </Hidden>
      <ModalContext
        open={openRecoveryModal}
        onClose={() => setRecoveryModal(false)}
        callback={() => history.push('/recuperar')}
        title={`Activa tu cuenta`}
        buttonTitle={'Recuperar'}
        content={
          <>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Tu cuenta no está activada. Revisa tu correo electrónico en caso
              de que tengas un link de activación. Sino, recupera tu activación
              de cuenta entrando en el siguiente botón.
            </Typography>
          </>
        }
      />
    </>
  );
};

export default Home;
