import React from 'react';
import { ErrorMessage as FormikErrorMessage } from 'formik';
import styled from 'styled-components';
import css from '@styled-system/css';

const ErrorMessageContainer = styled.div(
  css({
    position: 'relative',
  })
);

export const ErrorMessageElement = styled.div(
  css({
    color: '#d32f2f',
    fontSize: '0.75rem',
    position: 'absolute',
    fontWeight: 400,
    marginLeft: '14px',
    marginTop: '3px',
  })
);

const ErrorMessage = ({ name }) => (
  <FormikErrorMessage
    name={name}
    key={name}
    render={(errorMessage) => (
      <ErrorMessageContainer>
        <ErrorMessageElement>{errorMessage}</ErrorMessageElement>
      </ErrorMessageContainer>
    )}
  />
);

export default ErrorMessage;
