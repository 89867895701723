import { TextField } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

const InputText = (props) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <TextField
      {...field}
      {...props}
      helperText={errorText.length > 0 ? errorText : ''}
      error={!!errorText}
    />
  );
};

export default InputText;
