import React from 'react';
import { View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from 'assets/images/logo.png';
import gobiernoLogo from 'assets/images/gobierno_logo.png';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
    alignItems: 'stretch',
  },
  detailColumn: {
    flexDirection: 'row',
  },
  logo: {
    width: '100px',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    marginBottom: '8px',
  },
  logoGobierno: {
    width: '50px',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    marginBottom: '8px',
  },
  linkColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  name: {
    fontSize: 24,
  },
  subtitle: {
    fontSize: 10,
    justifySelf: 'flex-end',
  },
  link: {
    fontSize: 10,
    color: 'black',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
});

export const collectionStyle = {
  1: '#FEB460',
  2: '#bc0b0b',
  3: '#A9C3B6',
  4: '#8FB8CA',
  5: '#6D8B74',
  6: '#CCCCCC',
};

const Header = ({ article }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        borderBottomWidth: 2,
        borderBottomColor:
          article && article.collection
            ? collectionStyle[article.collection]
            : '#112131',
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
      }}
    >
      <View style={styles.detailColumn}>
        <Image style={styles.logo} src={logo} />
      </View>
      <View style={styles.linkColumn}>
        <Image style={styles.logoGobierno} src={gobiernoLogo} />
      </View>
    </View>
  );
};

export default Header;
