import { Select, MenuItem } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import Input from '@mui/material/Input';

const SelectMultiple = (props) => {
  const [field, meta] = useField(props);
  const { list, value } = props;
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <Select
      {...field}
      {...props}
      value={value}
      input={<Input />}
      error={!!errorText}
    >
      {list.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectMultiple;
