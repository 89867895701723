import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LogoutIcon from '@mui/icons-material/Logout';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import logo from 'assets/images/logo.png';
import communeLogo from 'assets/images/commune_logo.png';
import gobiernoLogo from 'assets/images/gobierno_logo.png';
import { Box } from '@mui/system';
import TuneIcon from '@mui/icons-material/Tune';
import { useUserDataContext } from 'contexts/UserDataContext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  fab: {
    height: 40,
    width: 40,
  },
  input: {
    backgroundColor: '#FFF',
    height: '2.5rem',
    margin: 0,
    borderRadius: '8px',
  },
}));

const ToolbarResponsive = ({ isMobile }) => {
  const { setUserAttribute, isStaff } = useUserDataContext();
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  let currentUrlParams = new URLSearchParams(window.location.search);

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {isStaff && (
        <>
          <List>
            <ListItem
              onClick={() => history.push('/museo/articulos')}
              button
              key="Inicio"
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Inicio" />
            </ListItem>
          </List>
          <Divider />
        </>
      )}
      <List>
        <ListItem
          onClick={() => history.push('/museo/catalogo')}
          button
          key="Catálogo"
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Catálogo" />
        </ListItem>
        <ListItem
          onClick={() => {
            localStorage.clear();
            sessionStorage.clear();
            history.push('/');
          }}
          button
          key="Cerrar sesión"
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Cerrar sesión" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar
          style={{
            paddingTop: '10px',
            paddingBottom: '10px',
            justifyContent: isMobile ? 'flex-end' : '',
          }}
        >
          {isMobile ? (
            ''
          ) : (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer('left', true)}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton>
          )}
          {isMobile && location.pathname.includes('museo/catalogo/filtros') && (
            <IconButton
              onClick={() => {
                let filtersQuery = '';

                if (currentUrlParams.get('page')) {
                  filtersQuery = `page=${currentUrlParams.get('page')}`;
                }

                if (currentUrlParams.get('query')) {
                  filtersQuery = `${filtersQuery}&query=${currentUrlParams.get(
                    'query'
                  )}`;
                }

                if (currentUrlParams.get('functionality')) {
                  filtersQuery = `${filtersQuery}&functionality=${currentUrlParams.get(
                    'functionality'
                  )}`;
                }

                if (currentUrlParams.get('material')) {
                  filtersQuery = `${filtersQuery}&material=${currentUrlParams.get(
                    'material'
                  )}`;
                }

                if (currentUrlParams.get('collection')) {
                  filtersQuery = `${filtersQuery}&collection=${currentUrlParams.get(
                    'collection'
                  )}`;
                }
                setUserAttribute('direction', -1);
                history.push({
                  pathname: `${match.url}/catalogo`,
                  search: `?${filtersQuery}`,
                  state: {
                    fromButtonBack: true,
                  },
                });
              }}
              color="secondary"
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          )}
          {isMobile && location.pathname.includes('museo/catalogo/articulo') && (
            <IconButton
              onClick={() => {
                const params = history.location?.state?.currentUrlParams;
                let filtersQuery = '';

                if (params.page) {
                  filtersQuery = `page=${params.page}`;
                }

                if (params.query) {
                  filtersQuery = `${filtersQuery}&query=${params.query}`;
                }

                if (params.functionality) {
                  filtersQuery = `${filtersQuery}&functionality=${params.functionality}`;
                }

                if (params.material) {
                  filtersQuery = `${filtersQuery}&material=${params.material}`;
                }

                if (params.collection) {
                  filtersQuery = `${filtersQuery}&collection=${params.collection}`;
                }
                setUserAttribute('direction', -1);
                history.push({
                  pathname: `${match.url}/catalogo`,
                  search: `?${filtersQuery}`,
                  state: {
                    fromButtonBack: true,
                  },
                });
              }}
              color="secondary"
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          )}
          {isMobile && location.pathname === '/museo/catalogo' && (
            <Box sx={{ width: '40px', height: '40px' }}></Box>
          )}
          <Typography
            sx={{
              display: { md: 'block' },
              fontSize: { md: '20px', sm: '20px', xs: '16px' },
            }}
            className={classes.title}
            variant="h6"
            noWrap
          >
            Sistema de registro de colecciones
          </Typography>
          <Box
            sx={{ display: { xs: 'none', md: 'flex' } }}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={logo}
              alt=""
              style={{ width: '85px', marginRight: '1.5rem' }}
            />
            <img
              src={communeLogo}
              alt=""
              style={{ width: '100px', marginRight: '1.5rem' }}
            />
            <img src={gobiernoLogo} alt="" style={{ width: '60px' }} />
          </Box>
          {isMobile && location.pathname === '/museo/catalogo' && (
            <>
              <IconButton
                onClick={() => {
                  let filtersQuery = '';

                  if (currentUrlParams.get('page')) {
                    filtersQuery = `page=${currentUrlParams.get('page')}`;
                  }

                  if (currentUrlParams.get('query')) {
                    filtersQuery = `${filtersQuery}&query=${currentUrlParams.get(
                      'query'
                    )}`;
                  }

                  if (currentUrlParams.get('functionality')) {
                    filtersQuery = `${filtersQuery}&functionality=${currentUrlParams.get(
                      'functionality'
                    )}`;
                  }

                  if (currentUrlParams.get('material')) {
                    filtersQuery = `${filtersQuery}&material=${currentUrlParams.get(
                      'material'
                    )}`;
                  }

                  if (currentUrlParams.get('collection')) {
                    filtersQuery = `${filtersQuery}&collection=${currentUrlParams.get(
                      'collection'
                    )}`;
                  }

                  setUserAttribute('direction', 1);
                  history.push({
                    pathname: `${match.url}/catalogo/filtros`,
                    search: `?${filtersQuery}`,
                  });
                }}
                color="secondary"
              >
                <TuneIcon />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
        {list('left')}
      </Drawer>
      <Box
        className={clsx(classes.content)}
        sx={{ height: { xs: '60px', md: '75px' } }}
      >
        <div className={classes.drawerHeader} />
      </Box>
    </div>
  );
};

export default ToolbarResponsive;
