import { useEffect } from 'react';
import {
  useQuery as rqUseQuery,
  useMutation as rqUseMutation,
} from 'react-query';

class FetchError extends Error {
  constructor(res, message) {
    super(message);
    this.res = res;
  }
}

const baseUrl = `${process.env.REACT_APP_GATEWAY_URL}`;

export const query = (path = '/', options = {}) => {
  return fetch(`${baseUrl}${path}`, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong');
      }
    })
    .then((data) => {
      if (process.env.NODE_ENV === 'development') {
        console.log({ path, body: options.body, data });
      }
      return data;
    });
};

export const useQuery = (queryName, path = '/', argOptions = {}) => {
  const { queryOptions = {}, ...options } = argOptions;
  if (options.body) {
    options.body = JSON.stringify(options.body);
  }

  const query = rqUseQuery(
    queryName,
    () => {
      const token = localStorage.getItem('token');
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return fetch(`${baseUrl}${path}`, options).then(async (response) => {
        const data = await response.json();

        if (process.env.NODE_ENV === 'development') {
          console.log({ path, body: options.body, data });
        }

        if (response.ok) {
          return data;
        } else {
          throw new FetchError(response);
        }
      });
    },
    queryOptions
  );

  const stringQueryName = queryName.toString();

  useEffect(() => {
    if (query.isError) {
      console.log('error', stringQueryName);
    }
  }, [query.isError, stringQueryName]);

  return {
    ...query,
    isLoading: query.isError || query.isLoading,
  };
};

export const useMutation = (path = '/', options = {}) => {
  const mutation = rqUseMutation(
    (body) => {
      let finalBody = body ? JSON.stringify(body) : undefined;
      if (options.unencoded) {
        const urlBody = new URLSearchParams();
        for (const property in body.body) {
          urlBody.append(property, body.body[property]);
        }
        finalBody = urlBody;
      }

      const headers = { 'Content-Type': 'application/json' };
      const token = localStorage.getItem('token');
      if (token) {
        headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      }

      return fetch(`${baseUrl}${path}`, {
        body: finalBody,
        method: options?.method || 'POST',
        headers,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json();
          }
        })
        .then((data) => {
          if (process.env.NODE_ENV === 'development') {
            console.log({ path, data });
          }
          return data;
        });
    },
    {
      onError: (error) => {
        if (options.onError) {
          options.onError(error);
        }
      },
    }
  );

  return mutation;
};

export const useDeleteMutation = (path = '/', options = {}) => {
  const mutation = rqUseMutation(
    (body) => {
      const headers = { 'Content-Type': 'application/json' };
      const token = localStorage.getItem('token');
      if (token) {
        headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      }

      return fetch(`${baseUrl}${path}`, {
        method: 'DELETE',
        headers,
      })
        .then((response) => {
          if (response.ok) {
            return response;
          } else {
            return response;
          }
        })
        .then((data) => {
          if (process.env.NODE_ENV === 'development') {
            console.log({ path, data });
          }
          return data;
        });
    },
    {
      onError: (error) => {
        if (options.onError) {
          options.onError(error);
        }
      },
    }
  );

  return mutation;
};
