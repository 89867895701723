import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Title from './Title';
import {
  ageList,
  completeness,
  genderList,
  materialsIndividual,
} from 'views/Catalog/ArticleCatalog';

const styles = StyleSheet.create({
  container: {
    paddingTop: '32px',
  },
  leftColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  title: {
    fontSize: 11,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  description: {
    fontSize: 11,
    color: '#65748B',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '4px',
    flex: '1',
  },
});

const Identification = ({ article }) => (
  <View style={styles.container}>
    <Title article={article}>
      Identificación {article.collection !== 3 ? 'del material' : ''}
    </Title>
    {article.collection !== 3 ? (
      <>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Material - </Text>
            <Text style={styles.description}>
              {article.collection &&
                article.collection !== 3 &&
                article.material &&
                article.material.map((m, index) => {
                  const rowLen = article.material.length;
                  return (
                    <>{rowLen === index + 1 ? `${m.name}` : `${m.name}, `}</>
                  );
                })}
              {!article.collection && 'Sin datos'}
            </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Tipo de manufactura - </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.description}>
              {article.typeManufacture ? article.typeManufacture : 'Sin datos'}
            </Text>
          </View>
        </View>
      </>
    ) : (
      <>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Material - </Text>
            <Text style={styles.description}>
              {article.materialIndividual !== '3'
                ? materialsIndividual[article.materialIndividual]
                : article.materialIndividual === '3'
                ? article.otherMaterialIndividual
                : 'Sin datos'}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>Completitud - </Text>
            <Text style={styles.description}>
              {article.completeness !== '4'
                ? completeness[article.completeness]
                : article.completeness === '4'
                ? article.otherCompleteness
                : 'Sin datos'}
            </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Sexo - </Text>
            <Text style={styles.description}>
              {article.sexIndividual && genderList[article.sexIndividual]}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>Edad - </Text>
            <Text style={styles.description}>
              {article.ageIndividual && ageList[article.ageIndividual]}
            </Text>
          </View>
        </View>
      </>
    )}
  </View>
);

export default Identification;
