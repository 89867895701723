import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import { Box } from '@mui/system';
import logo from 'assets/images/logo.png';
import communeLogo from 'assets/images/commune_logo.png';
import gobiernoLogo from 'assets/images/gobierno_logo.png';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { postData } from 'api/queries';
import { useLocation } from 'react-router-dom';

const Toolbar = styled('div')({
  height: '74px',
  background: '#EB8242',
  boxShadow:
    '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  position: 'fixed',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '1rem',
});

const CardContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
  borderRadius: '8px',
  padding: '2rem',
  maxWidth: '640px',
  minHeight: '400px',
  background: 'white',
});

const Container = styled('div')({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
  padding: '2rem',
  height: '100%',
  backgroundColor: '#fcf6ec',
  paddingTop: '106px',
});

const Verify = ({ isMobile }) => {
  const history = useHistory();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [isVerify, setIsVerify] = useState(null);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  const verifyAccount = async () => {
    setOpenBackdrop(true);
    try {
      await postData('/users/verify/', {
        token,
      }).then((data) => {
        if (data.statusCode === 400) {
          setIsVerify(false);
          setOpenBackdrop(false);
          return false;
        }
        setIsVerify(true);
        setOpenBackdrop(false);
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (!token) {
      history.push('/');
    } else {
      verifyAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Toolbar
        style={{
          justifyContent: isMobile ? 'center' : 'end',
        }}
      >
        <Box
          sx={{ display: 'flex' }}
          style={{
            width: isMobile ? '100%' : '',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={logo}
            alt=""
            style={{ width: '100px', marginRight: '1.5rem' }}
          />
          <img
            src={communeLogo}
            alt=""
            style={{ width: '100px', marginRight: '1.5rem' }}
          />
          <img src={gobiernoLogo} alt="" style={{ width: '60px' }} />
        </Box>
      </Toolbar>
      <Container>
        {isVerify && (
          <CardContainer>
            <p style={{ fontSize: '20px', marginBottom: '1rem' }}>
              ¡Activación de cuenta con éxito!
            </p>
            <p>
              Ya puedes acceder a nuestro portal, disfruta de la visita al
              Sistema de registro de colecciones del Museo Histórico
              Arqueológico de Quillota.
            </p>
            <span
              style={{ position: 'absolute', bottom: '2rem', right: '2rem' }}
            >
              <Button
                onClick={() => history.push('/')}
                variant="contained"
                color="primary"
              >
                Acceder
              </Button>
            </span>
          </CardContainer>
        )}
        {isVerify === false && (
          <CardContainer>
            <p style={{ fontSize: '20px', marginBottom: '1rem' }}>
              ¡La activación de cuenta ha expirado!
            </p>
            <p>
              Entra al <b> apartado de recuperación</b> en caso de que necesites
              generar un nuevo link de activación.
            </p>
            <span
              style={{ position: 'absolute', bottom: '2rem', right: '2rem' }}
            >
              <Button
                onClick={() => history.push('/recuperar')}
                variant="contained"
                color="primary"
              >
                Recuperar cuenta
              </Button>
            </span>
          </CardContainer>
        )}
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Verify;
