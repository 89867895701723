import { Select, MenuItem } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

const SelectText = (props) => {
  const [field, meta] = useField(props);
  const { list, placeholder } = props;
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <Select displayEmpty {...field} {...props} error={!!errorText}>
      <MenuItem key={0} value={null} disabled>
        {placeholder}
      </MenuItem>
      {list.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};

SelectText.defaultProps = {
  placeholder: 'Seleccione',
};

export default SelectText;
