import React, { useEffect, useState } from 'react';
import { Avatar, Box, FormControl, Skeleton, Tooltip } from '@mui/material';
import { Section, SubSection } from 'components/StylesForm/StylesForm';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import InputText from 'components/Input/InputText';
import CheckboxCustom from 'components/Checkbox/Checkbox';
import SelectText from 'components/Select/Select';
import RadioButton from 'components/RadioButton/RadioButton';
import { originMonth } from './articlesArray';
import Divider from '@mui/material/Divider';
import { Card, collectionStyle } from './ArticlesHome';
import { getData } from 'api/queries';
import emptyState from 'assets/images/empty_state.png';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  title: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 20,
    paddingLeft: '16px',
  },
  formTitle: {
    paddingRight: '16px',
    textAlign: 'right',
  },
  gridLayout: {
    marginBottom: '32px',
  },
  locationLayout: {
    paddingTop: '32px',
  },
  checkboxLayout: {
    display: 'flex',
  },
  divider: {
    backgroundColor: '#EB8242',
  },
  subSectionTitle: {
    fontSize: 18,
    color: 'white',
  },
  subFormTitle: {
    paddingRight: '16px',
  },
  subTitle: {
    fontWeight: '500',
    fontSize: 18,
  },
}));

const OriginalContext = ({ values, setFieldValue, handleChange }) => {
  const classes = useStyles();
  const [skeletonAsociatedArticles, setSkeletonAsociatedArticles] = useState();
  const [asociatedArticles, setAsociatedArticles] = useState([]);

  const getIndividualAsociated = async (asociatedNumber) => {
    setSkeletonAsociatedArticles(true);
    await getData(`/articles/associated_guy/${asociatedNumber}`).then(
      (response) => {
        setAsociatedArticles(response);
        setSkeletonAsociatedArticles(false);
      }
    );
  };

  useEffect(() => {
    if (
      values.isGuy &&
      values.isGuy === 'false' &&
      values.numberGuy &&
      values.numberGuy.length > 0
    ) {
      getIndividualAsociated(values.numberGuy);
    } else if (
      values.isGuy &&
      values.isGuy === 'true' &&
      values.inventaryNumber &&
      values.inventaryNumber.length > 0
    ) {
      getIndividualAsociated(values.inventaryNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box style={{ marginBottom: '1rem' }}>
      <Section title="CONTEXTO ORIGINAL">
        <Box>
          <Grid container alignItems="center" className={classes.gridLayout}>
            <Grid item md={3}>
              <Typography className={classes.formTitle}>
                Nombre del sitio:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <InputText
                variant="outlined"
                placeholder="Nombre del sitio"
                required
                fullWidth
                id="siteName"
                name="siteName"
                autoComplete="siteName"
                value={values.siteName}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" className={classes.gridLayout}>
            <Grid item md={3}>
              <Typography className={classes.formTitle}>
                Código sitio:
              </Typography>
            </Grid>
            <Grid item md={4}>
              <InputText
                variant="outlined"
                placeholder="Código sitio"
                required
                fullWidth
                id="siteCode"
                name="siteCode"
                autoComplete="siteCode"
                value={values.siteCode}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" className={classes.gridLayout}>
            <Grid item md={3}>
              <Typography className={classes.formTitle}>Comuna:</Typography>
            </Grid>
            <Grid item md={3}>
              <InputText
                variant="outlined"
                placeholder="Comuna"
                required
                fullWidth
                id="comune"
                name="comune"
                autoComplete="comune"
                value={values.comune}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" className={classes.gridLayout}>
            <Grid item md={3}>
              <Typography className={classes.formTitle}>Localidad:</Typography>
            </Grid>
            <Grid item container spacing={3} md={9}>
              <Grid item md={4}>
                <InputText
                  variant="outlined"
                  placeholder="Localidad"
                  required
                  fullWidth
                  name="locality"
                  value={values.locality}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems="center" className={classes.gridLayout}>
            <Grid item md={3}>
              <Typography className={classes.formTitle}>
                Coordenadas:
              </Typography>
            </Grid>
            <Grid item md={1}>
              <RadioButton
                checked={values.hasCoordinates === 'true'}
                name="hasCoordinates"
                type="radio"
                value="true"
                label="Si"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Grid>
            <Grid item md={1}>
              <RadioButton
                checked={values.hasCoordinates === 'false'}
                name="hasCoordinates"
                type="radio"
                value="false"
                label="No"
                onChange={(e) => {
                  setFieldValue('coordinateType', null);
                  setFieldValue('utmEast', '');
                  setFieldValue('utmNorth', '');
                  setFieldValue('datum', '');
                  setFieldValue('datumNoRef', false);
                  setFieldValue('altitude', '');
                  setFieldValue('altitudeNoRef', false);
                  setFieldValue('latitude', '');
                  setFieldValue('longitude', '');
                  handleChange(e);
                }}
              />
            </Grid>
          </Grid>
          {values.hasCoordinates === 'true' && (
            <SubSection title="Tipo de coordenadas">
              <Box>
                <Grid
                  container
                  alignItems="center"
                  className={classes.gridLayout}
                >
                  <Grid item md={2}>
                    <RadioButton
                      checked={Number(values.coordinateType) === 1}
                      name="coordinateType"
                      type="radio"
                      value="1"
                      label="UTM"
                      onChange={(e) => {
                        setFieldValue('utmNorth', '');
                        setFieldValue('utmEast', '');
                        handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <RadioButton
                      checked={Number(values.coordinateType) === 2}
                      name="coordinateType"
                      type="radio"
                      value="2"
                      label="Geográfica"
                      onChange={(e) => {
                        setFieldValue('utmEast', '');
                        setFieldValue('utmNorth', '');
                        setFieldValue('datum', '');
                        setFieldValue('altitude', '');
                        setFieldValue('datumNoRef', false);
                        setFieldValue('altitudeNoRef', false);
                        handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <RadioButton
                      checked={Number(values.coordinateType) === 3}
                      name="coordinateType"
                      type="radio"
                      value="3"
                      label="Sin referencia"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </Grid>
                </Grid>
                {Number(values.coordinateType) === 1 && (
                  <>
                    <Grid
                      container
                      alignItems="center"
                      className={classes.gridLayout}
                    >
                      <Grid
                        item
                        alignItems="center"
                        container
                        spacing={3}
                        md={12}
                      >
                        <Grid item md={2}>
                          <Typography className={classes.subFormTitle}>
                            UTM Este:
                          </Typography>
                        </Grid>
                        <Grid item md={4}>
                          <InputText
                            variant="outlined"
                            required
                            fullWidth
                            name="utmEast"
                            autoComplete="utmEast"
                            value={values.utmEast}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <Typography className={classes.subFormTitle}>
                            UTM Norte:
                          </Typography>
                        </Grid>
                        <Grid item md={4}>
                          <InputText
                            variant="outlined"
                            required
                            fullWidth
                            name="utmNorth"
                            autoComplete="utmNorth"
                            value={values.utmNorth}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      className={classes.gridLayout}
                    >
                      <Grid item md={2}>
                        <Typography className={classes.subFormTitle}>
                          Datum:
                        </Typography>
                      </Grid>
                      <Grid item container spacing={3} md={9}>
                        <Grid item md={4}>
                          <InputText
                            variant="outlined"
                            required
                            fullWidth
                            placeholder={
                              values.datumNoRef ? 'Indeterminado' : ''
                            }
                            name="datum"
                            autoComplete="datum"
                            value={values.datum}
                            disabled={values.datumNoRef}
                          />
                        </Grid>
                        <Grid
                          className={classes.checkboxLayout}
                          item
                          alignItems="center"
                          xs={6}
                          sm={3}
                          md={3}
                        >
                          <CheckboxCustom
                            name="datumNoRef"
                            value={values.datumNoRef}
                            label="Indeterminado"
                            onChange={(e) => {
                              setFieldValue('datum', '');
                              handleChange(e);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      className={classes.gridLayout}
                    >
                      <Grid item md={2}>
                        <Typography className={classes.subFormTitle}>
                          Altitud:
                        </Typography>
                      </Grid>
                      <Grid item container spacing={3} md={9}>
                        <Grid item md={4}>
                          <InputText
                            variant="outlined"
                            required
                            fullWidth
                            placeholder={
                              values.altitudeNoRef ? 'Sin referencia' : ''
                            }
                            id="altitude"
                            name="altitude"
                            autoComplete="altitude"
                            value={values.altitude}
                            disabled={values.altitudeNoRef}
                          />
                        </Grid>
                        <Grid
                          className={classes.checkboxLayout}
                          item
                          alignItems="center"
                          xs={6}
                          sm={3}
                          md={3}
                        >
                          <CheckboxCustom
                            name="altitudeNoRef"
                            label="Sin referencia"
                            value={values.altitudeNoRef}
                            onChange={(e) => {
                              setFieldValue('altitude', '');
                              handleChange(e);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                {values.coordinateType === '2' && (
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                  >
                    <Grid
                      item
                      alignItems="center"
                      container
                      spacing={3}
                      md={12}
                    >
                      <Grid item md="auto">
                        <Typography className={classes.subFormTitle}>
                          Latitud:
                        </Typography>
                      </Grid>
                      <Grid item md={4}>
                        <InputText
                          variant="outlined"
                          required
                          fullWidth
                          name="latitude"
                          autoComplete="latitude"
                          value={values.latitude}
                        />
                      </Grid>
                      <Grid item md="auto">
                        <Typography className={classes.subFormTitle}>
                          Longitud:
                        </Typography>
                      </Grid>
                      <Grid item md={4}>
                        <InputText
                          variant="outlined"
                          required
                          fullWidth
                          name="longitude"
                          autoComplete="longitude"
                          value={values.longitude}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </SubSection>
          )}
          <SubSection title="Funcionalidad del sitio">
            <Box>
              <Grid
                container
                alignItems="center"
                className={classes.gridLayout}
              >
                <Grid
                  className={classes.checkboxLayout}
                  item
                  alignItems="center"
                  md={2}
                >
                  <CheckboxCustom
                    disabled={
                      values.functionalities.includes(4) ||
                      values.functionalities.includes('4')
                    }
                    name="functionalities"
                    label="Funerario"
                    value={1}
                    checked={
                      values.functionalities.find(
                        (func) => func === 1 || func === '1'
                      )
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </Grid>
                <Grid
                  className={classes.checkboxLayout}
                  item
                  alignItems="center"
                  md={2}
                >
                  <CheckboxCustom
                    disabled={
                      values.functionalities.includes(4) ||
                      values.functionalities.includes('4')
                    }
                    name="functionalities"
                    label="Doméstico"
                    value="2"
                    checked={
                      values.functionalities.find(
                        (func) => func === 2 || func === '2'
                      )
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </Grid>
                <Grid
                  className={classes.checkboxLayout}
                  item
                  alignItems="center"
                  md={2}
                >
                  <CheckboxCustom
                    disabled={
                      values.functionalities.includes(4) ||
                      values.functionalities.includes('4')
                    }
                    name="functionalities"
                    label="Tarea"
                    value="3"
                    checked={
                      values.functionalities.find(
                        (func) => func === 3 || func === '3'
                      )
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </Grid>
                <Grid
                  className={classes.checkboxLayout}
                  item
                  alignItems="center"
                  md={2}
                  onChange={(e) => {}}
                >
                  <CheckboxCustom
                    value="4"
                    name="functionalities"
                    label="Sin referencias"
                    checked={
                      values.functionalities.find(
                        (func) => func === 4 || func === '4'
                      )
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      if (
                        !values.functionalities.includes(4) &&
                        !values.functionalities.includes('4')
                      ) {
                        setFieldValue('functionalities', [4]);
                        setFieldValue('otherFunctionality', '');
                      } else {
                        setFieldValue('functionalities', []);
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={classes.gridLayout}
              >
                <Grid
                  className={classes.checkboxLayout}
                  item
                  alignItems="center"
                  md={12}
                  onChange={(e) => {}}
                >
                  <CheckboxCustom
                    disabled={
                      values.functionalities.includes(4) ||
                      values.functionalities.includes('4')
                    }
                    value="5"
                    name="functionalities"
                    label="Otra funcionalidad:"
                    checked={
                      values.functionalities.find(
                        (func) => func === 5 || func === '5'
                      )
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </Grid>
                <Grid item md={10}>
                  <InputText
                    variant="outlined"
                    multiline
                    rows={2}
                    required
                    fullWidth
                    name="otherFunctionality"
                    value={values.otherFunctionality}
                    disabled={
                      !values.functionalities.includes(5) &&
                      !values.functionalities.includes('5')
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={classes.gridLayout}
              >
                <Grid
                  item
                  container
                  alignItems="center"
                  className={classes.gridLayout}
                  md={5}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                    md={12}
                  >
                    <Typography fontWeight="500" fontSize="18px">
                      Localización estratigráfica:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    className={classes.locationLayout}
                    md={12}
                  >
                    <Grid item md={2}>
                      <Typography className={classes.subFormTitle}>
                        Unidad:
                      </Typography>
                    </Grid>
                    <Grid item container spacing={3} md={10}>
                      <Grid item md={6}>
                        <InputText
                          variant="outlined"
                          required
                          fullWidth
                          placeholder={
                            values.localizationUnitNoRef
                              ? 'Sin referencias'
                              : ''
                          }
                          name="localizationUnit"
                          autoComplete="localizationUnit"
                          value={values.localizationUnit}
                          disabled={values.localizationUnitNoRef}
                        />
                      </Grid>
                      <Grid
                        className={classes.checkboxLayout}
                        item
                        alignItems="center"
                        md={6}
                      >
                        <CheckboxCustom
                          checked={values.localizationUnitNoRef === true}
                          name="localizationUnitNoRef"
                          value={values.localizationUnitNoRef}
                          label="Sin referencias"
                          onChange={(e) => {
                            setFieldValue('localizationUnit', '');
                            handleChange(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    className={classes.locationLayout}
                    md={12}
                  >
                    <Grid item md={2}>
                      <Typography className={classes.subFormTitle}>
                        Capa:
                      </Typography>
                    </Grid>
                    <Grid item container spacing={3} md={10}>
                      <Grid item md={6}>
                        <InputText
                          variant="outlined"
                          required
                          fullWidth
                          name="localizationLayer"
                          autoComplete="localizationLayer"
                          value={values.localizationLayer}
                          placeholder={
                            values.localizationLayerNoRef
                              ? 'Sin referencias'
                              : ''
                          }
                          disabled={values.localizationLayerNoRef}
                        />
                      </Grid>
                      <Grid
                        className={classes.checkboxLayout}
                        item
                        alignItems="center"
                        md={6}
                      >
                        <CheckboxCustom
                          checked={values.localizationLayerNoRef === true}
                          name="localizationLayerNoRef"
                          value={values.localizationLayerNoRef}
                          label="Sin referencias"
                          onChange={(e) => {
                            setFieldValue('localizationLayer', '');
                            handleChange(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    className={classes.locationLayout}
                    md={12}
                  >
                    <Grid item md={2}>
                      <Typography className={classes.subFormTitle}>
                        Nivel:
                      </Typography>
                    </Grid>
                    <Grid item container spacing={3} md={10}>
                      <Grid item md={6}>
                        <InputText
                          variant="outlined"
                          required
                          fullWidth
                          name="localizationLevel"
                          autoComplete="localizationLevel"
                          value={values.localizationLevel}
                          placeholder={
                            values.localizationLevelNoRef
                              ? 'Sin referencias'
                              : ''
                          }
                          disabled={values.localizationLevelNoRef}
                        />
                      </Grid>
                      <Grid
                        className={classes.checkboxLayout}
                        item
                        alignItems="center"
                        md={6}
                      >
                        <CheckboxCustom
                          checked={values.localizationLevelNoRef === true}
                          name="localizationLevelNoRef"
                          value={values.localizationLevelNoRef}
                          label="Sin referencias"
                          onChange={(e) => {
                            setFieldValue('localizationLevel', '');
                            handleChange(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem={true}
                  color="#EB8242"
                ></Divider>
                <Grid item md={6}>
                  <Box pl="32px">
                    <Grid item md={12}>
                      <Typography className={classes.subTitle}>
                        Observaciones
                      </Typography>
                    </Grid>
                    <Grid item md={12}>
                      <InputText
                        variant="outlined"
                        multiline
                        rows={2}
                        required
                        fullWidth
                        name="localizationObservations"
                        value={values.localizationObservations}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </SubSection>
          <SubSection title="Contexto funerario">
            <Box>
              <Grid
                container
                alignItems="center"
                className={classes.gridLayout}
              >
                <Grid item alignItems="center" md={1}>
                  <RadioButton
                    checked={values.hasFuneralContext === 'true'}
                    name="hasFuneralContext"
                    type="radio"
                    value="true"
                    label="Aplica"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </Grid>
                <Grid item alignItems="center" md="auto">
                  <RadioButton
                    checked={values.hasFuneralContext === 'false'}
                    name="hasFuneralContext"
                    type="radio"
                    value="false"
                    label="No aplica"
                    onChange={(e) => {
                      setFieldValue('isGuy', '');
                      setFieldValue('grave', '');
                      setFieldValue('graveNoRef', false);
                      setFieldValue('numberGuy', '');
                      setFieldValue('numberGuyNoRef', false);
                      setFieldValue('responsibleFuneralName', '');
                      setFieldValue('obtainingDateYear', '');
                      setFieldValue('obtainingDateNoRef', false);
                      setFieldValue('obtainingDateMonth', '');
                      setFieldValue('obtainingDateDay', '');
                      handleChange(e);
                    }}
                  />
                </Grid>
              </Grid>
              {values.hasFuneralContext === 'true' && (
                <>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                  >
                    <Grid item md={3}>
                      <Typography className={classes.formTitle}>
                        Tipo de colección
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <RadioButton
                        checked={values.isGuy === 'true'}
                        name="isGuy"
                        type="radio"
                        value="true"
                        label="Individuo"
                        onChange={(e) => {
                          if (
                            values.inventaryNumber &&
                            values.inventaryNumber.length > 0
                          ) {
                            getIndividualAsociated(values.inventaryNumber);
                          }
                          setFieldValue('numberGuy', '');
                          handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <RadioButton
                        checked={values.isGuy === 'false'}
                        name="isGuy"
                        type="radio"
                        value="false"
                        label="Objeto"
                        onChange={(e) => {
                          setAsociatedArticles([]);
                          handleChange(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                  >
                    <Grid item md={3}>
                      <Typography className={classes.formTitle}>
                        Tumba/enterratorio/conjunto funerario:
                      </Typography>
                    </Grid>
                    <Grid item container spacing={4} md={9}>
                      <Grid item md={3}>
                        <InputText
                          variant="outlined"
                          required
                          fullWidth
                          name="grave"
                          autoComplete="grave"
                          value={values.grave}
                          placeholder={
                            values.graveNoRef ? 'Sin referencias' : ''
                          }
                          disabled={values.graveNoRef}
                        />
                      </Grid>
                      <Grid
                        className={classes.checkboxLayout}
                        item
                        alignItems="center"
                        xs={6}
                        sm={3}
                        md={3}
                      >
                        <CheckboxCustom
                          checked={values.graveNoRef}
                          name="graveNoRef"
                          label="Sin referencias"
                          onChange={(e) => {
                            setFieldValue('grave', '');
                            handleChange(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {values.isGuy === 'false' && (
                    <Grid
                      container
                      alignItems="center"
                      className={classes.gridLayout}
                    >
                      <Grid item md={3}>
                        <Typography className={classes.formTitle}>
                          Nº individuo asociado:
                        </Typography>
                      </Grid>
                      <Grid item container spacing={4} md={9}>
                        <Grid item md={3}>
                          <InputText
                            variant="outlined"
                            required
                            fullWidth
                            name="numberGuy"
                            autoComplete="numberGuy"
                            value={values.numberGuy}
                            placeholder={
                              values.numberGuyNoRef ? 'Sin referencias' : ''
                            }
                            onBlur={() => {
                              if (
                                values.numberGuy &&
                                values.numberGuy.length > 0
                              ) {
                                getIndividualAsociated(values.numberGuy);
                              }
                            }}
                            disabled={values.numberGuyNoRef}
                          />
                        </Grid>
                        <Grid
                          className={classes.checkboxLayout}
                          item
                          alignItems="center"
                          xs={6}
                          sm={3}
                          md={3}
                        >
                          <CheckboxCustom
                            checked={values.numberGuyNoRef}
                            name="numberGuyNoRef"
                            label="Sin referencias"
                            onChange={(e) => {
                              setFieldValue('numberGuy', '');
                              handleChange(e);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                  >
                    <Grid item md={3}>
                      <Typography className={classes.formTitle}>
                        Nombre responsable:
                      </Typography>
                    </Grid>
                    <Grid item container md={6}>
                      <Grid item md={6}>
                        <InputText
                          variant="outlined"
                          required
                          fullWidth
                          name="responsibleFuneralName"
                          autoComplete="responsibleFuneralName"
                          value={values.responsibleFuneralName}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                  >
                    <Grid item md={3}>
                      <Typography className={classes.formTitle}>
                        Fecha obtención
                      </Typography>
                    </Grid>
                    <Grid item container spacing={4} md={9}>
                      <Grid item md={3}>
                        <InputText
                          placeholder={
                            values.obtainingDateNoRef
                              ? 'Sin referencias'
                              : 'Año'
                          }
                          variant="outlined"
                          type="text"
                          required
                          fullWidth
                          name="obtainingDateYear"
                          autoComplete="obtainingDateYear"
                          value={values.obtainingDateYear}
                          disabled={values.obtainingDateNoRef}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <SelectText
                            placeholder="Mes"
                            disabled={values.obtainingDateNoRef}
                            required
                            fullWidth
                            name="obtainingDateMonth"
                            list={originMonth}
                            onChange={(event) => {
                              handleChange(event);
                            }}
                          />
                          <ErrorMessage name="obtainingDateMonth" />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <InputText
                          disabled={values.obtainingDateNoRef}
                          placeholder={
                            values.obtainingDateNoRef
                              ? 'Sin referencias'
                              : 'Día'
                          }
                          variant="outlined"
                          type="text"
                          required
                          fullWidth
                          name="obtainingDateDay"
                          autoComplete="obtainingDateDay"
                          value={values.obtainingDateDay}
                        />
                      </Grid>
                      <Grid
                        className={classes.checkboxLayout}
                        item
                        alignItems="center"
                        md={3}
                      >
                        <CheckboxCustom
                          checked={values.obtainingDateNoRef}
                          name="obtainingDateNoRef"
                          label="Sin referencias"
                          onChange={(e) => {
                            setFieldValue('obtainingDateYear', '');
                            setFieldValue('obtainingDateMonth', '');
                            setFieldValue('obtainingDateDay', '');
                            handleChange(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </SubSection>
          {values.hasFuneralContext === 'true' && (
            <SubSection
              title="Ajuar / Ofrenda asociados"
              style={{ marginBottom: '2rem' }}
            >
              <Box
                sx={{
                  maxHeight: '340px',
                  overflow: 'auto',
                  overflowY: 'scroll',
                  padding: '16px',
                  backgroundColor: '#F0F0F0',
                  borderRadius: '8px',
                }}
              >
                {asociatedArticles.length > 0 && !skeletonAsociatedArticles && (
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {asociatedArticles.map(
                      (
                        { collection, inventaryNumber, material, photography },
                        index
                      ) => (
                        <Grid item xs={2} sm={3} md={3} key={index}>
                          <Card>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '1rem',
                                paddingRight: '8px',
                              }}
                            >
                              <Tooltip
                                title={
                                  collection !== null
                                    ? collectionStyle[collection].name
                                    : collectionStyle[5].name
                                }
                                placement="top"
                              >
                                <Avatar
                                  sx={{
                                    bgcolor:
                                      collection !== null
                                        ? collectionStyle[collection].color
                                        : collectionStyle[5].color,
                                    marginRight: '12px',
                                  }}
                                >
                                  {collection !== null
                                    ? collectionStyle[collection].svg
                                    : collectionStyle[5].svg}
                                </Avatar>
                              </Tooltip>
                              <Box
                                sx={{
                                  flex: '1 1 auto',
                                }}
                              >
                                <span
                                  style={{
                                    margin: 0,
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    lineHeight: 1.43,
                                    letterSpacing: '0.01071em',
                                    display: 'block',
                                  }}
                                >
                                  {inventaryNumber}
                                </span>
                                <span
                                  style={{
                                    margin: '0px',
                                    fontWeight: 400,
                                    fontSize: '0.875rem',
                                    lineHeight: 1.43,
                                    letterSpacing: '0.01071em',
                                    display: 'block',
                                  }}
                                >
                                  {!collection
                                    ? 'Sin colección'
                                    : collection === '3'
                                    ? 'Invividuo'
                                    : 'Objeto'}
                                </span>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                display: 'flex',
                                height: '180px',
                                justifyContent: 'center',
                                backgroundColor:
                                  collection !== null
                                    ? collectionStyle[collection].color
                                    : collectionStyle[5].color,
                                padding: '1rem',
                              }}
                            >
                              <img
                                style={{
                                  height: '100%',
                                  borderRadius: '4px',
                                }}
                                src={
                                  photography
                                    ? `${process.env.REACT_APP_URL_STORAGE}${photography}`
                                    : emptyState
                                }
                                alt="Imagen de objeto"
                              />
                            </Box>
                          </Card>
                        </Grid>
                      )
                    )}
                  </Grid>
                )}
                {asociatedArticles.length === 0 && !skeletonAsociatedArticles && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      fontSize: '16px',
                      color: '#8d8d8d',
                      fontWeight: '500',
                      height: '5rem',
                    }}
                  >
                    No se han encontrado ajuares asociados con el número de
                    individuo.
                  </Box>
                )}
                {skeletonAsociatedArticles && (
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {Array.from(Array(4)).map((_, index) => (
                      <Grid item xs={6} sm={3} md={3} key={index}>
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height={200}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Box>
            </SubSection>
          )}
          <SubSection title="Temporalidad">
            <Box>
              <Grid
                container
                alignItems="center"
                className={classes.gridLayout}
                spacing={2}
              >
                <Grid item md={3}>
                  <Typography className={classes.formTitle}>
                    Período:
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <InputText
                    placeholder={values.periodNoRef ? 'Sin referencias' : ''}
                    variant="outlined"
                    required
                    fullWidth
                    name="period"
                    autoComplete="period"
                    value={values.period}
                    disabled={values.periodNoRef}
                  />
                </Grid>
                <Grid
                  className={classes.checkboxLayout}
                  item
                  alignItems="center"
                  md={3}
                >
                  <CheckboxCustom
                    checked={values.periodNoRef}
                    name="periodNoRef"
                    label="Sin referencias"
                    onChange={(e) => {
                      setFieldValue('period', '');
                      handleChange(e);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={classes.gridLayout}
                spacing={2}
              >
                <Grid item md={3}>
                  <Typography className={classes.formTitle}>
                    Adscripción cultural:
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <InputText
                    placeholder={
                      values.culturalAscriptionNoRef ? 'Sin referencias' : ''
                    }
                    variant="outlined"
                    required
                    fullWidth
                    name="culturalAscription"
                    autoComplete="culturalAscription"
                    value={values.culturalAscription}
                    disabled={values.culturalAscriptionNoRef}
                  />
                </Grid>
                <Grid
                  className={classes.checkboxLayout}
                  item
                  alignItems="center"
                  md={3}
                >
                  <CheckboxCustom
                    checked={values.culturalAscriptionNoRef}
                    name="culturalAscriptionNoRef"
                    label="Sin referencias"
                    onChange={(e) => {
                      setFieldValue('culturalAscription', '');
                      handleChange(e);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={classes.gridLayout}
              >
                <Grid item md={3}>
                  <Typography className={classes.formTitle}>
                    Cronología absoluta:
                  </Typography>
                </Grid>
                <Grid item md={1}>
                  <RadioButton
                    checked={values.absoluteChronology === 'true'}
                    name="absoluteChronology"
                    type="radio"
                    value="true"
                    label="Si"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </Grid>
                <Grid item md={1}>
                  <RadioButton
                    checked={values.absoluteChronology === 'false'}
                    name="absoluteChronology"
                    type="radio"
                    value="false"
                    label="No"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </Grid>
              </Grid>
              {values.absoluteChronology === 'true' && (
                <>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                  >
                    <Grid item md={3}>
                      <Typography className={classes.formTitle}>
                        Fecha
                      </Typography>
                    </Grid>
                    <Grid item md={1}>
                      <RadioButton
                        checked={values.temporalityType === 'AC'}
                        name="temporalityType"
                        type="radio"
                        value="AC"
                        label="AC"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <RadioButton
                        checked={values.temporalityType === 'DC'}
                        name="temporalityType"
                        type="radio"
                        value="DC"
                        label="DC"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <RadioButton
                        checked={values.temporalityType === 'AP'}
                        name="temporalityType"
                        type="radio"
                        value="AP"
                        label="AP"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                  {(values.temporalityType === 'AC' ||
                    values.temporalityType === 'DC') && (
                    <Grid
                      container
                      alignItems="center"
                      className={classes.gridLayout}
                      spacing={2}
                    >
                      <Grid item md={3}></Grid>
                      <Grid item md={2}>
                        <InputText
                          variant="outlined"
                          required
                          fullWidth
                          type="number"
                          name="temporalityYearsBase"
                          autoComplete="temporalityYearsBase"
                          value={values.temporalityYearsBase}
                        />
                      </Grid>
                      <Grid item md="auto">
                        <Typography className={classes.formTitle}>
                          Sigma +/-
                        </Typography>
                      </Grid>
                      <Grid item md={2}>
                        <InputText
                          variant="outlined"
                          required
                          fullWidth
                          type="number"
                          name="temporalityYearsSigma"
                          autoComplete="temporalityYearsSigma"
                          value={values.temporalityYearsSigma}
                        />
                      </Grid>
                      <Grid
                        className={classes.checkboxLayout}
                        item
                        alignItems="center"
                        md={2}
                      >
                        <CheckboxCustom
                          checked={values.temporalityCalibrated}
                          name="temporalityCalibrated"
                          label="Calibrada"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {values.temporalityType === 'AP' && (
                    <Grid
                      container
                      alignItems="center"
                      className={classes.gridLayout}
                      spacing={2}
                    >
                      <Grid item md={3}></Grid>
                      <Grid item md={2}>
                        <InputText
                          variant="outlined"
                          required
                          fullWidth
                          type="number"
                          name="temporalityYearsBase"
                          autoComplete="temporalityYearsBase"
                          value={values.temporalityYearsBase}
                        />
                      </Grid>
                      <Grid item md="auto">
                        <Typography className={classes.formTitle}>-</Typography>
                      </Grid>
                      <Grid item md={2}>
                        <InputText
                          variant="outlined"
                          required
                          fullWidth
                          type="number"
                          name="temporalityYearsSigma"
                          autoComplete="temporalityYearsSigma"
                          value={values.temporalityYearsSigma}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                    spacing={2}
                  >
                    <Grid item md={3}>
                      <Typography className={classes.formTitle}>
                        Código lab:
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <InputText
                        placeholder={
                          values.temporalityCodeLabNoRef
                            ? 'Sin referencias'
                            : ''
                        }
                        variant="outlined"
                        required
                        fullWidth
                        name="temporalityCodeLab"
                        autoComplete="temporalityCodeLab"
                        value={values.temporalityCodeLab}
                        disabled={values.temporalityCodeLabNoRef}
                      />
                    </Grid>
                    <Grid
                      className={classes.checkboxLayout}
                      item
                      alignItems="center"
                      md={3}
                    >
                      <CheckboxCustom
                        checked={values.temporalityCodeLabNoRef}
                        name="temporalityCodeLabNoRef"
                        label="Sin referencias"
                        onChange={(e) => {
                          setFieldValue('temporalityCodeLab', '');
                          handleChange(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                    spacing={2}
                  >
                    <Grid item md={3}>
                      <Typography className={classes.formTitle}>
                        Material fechado:
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <InputText
                        placeholder={
                          values.temporalityMaterialDatedNoRef
                            ? 'Sin referencias'
                            : ''
                        }
                        variant="outlined"
                        required
                        fullWidth
                        name="temporalityMaterialDated"
                        autoComplete="temporalityMaterialDated"
                        value={values.temporalityMaterialDated}
                        disabled={values.temporalityMaterialDatedNoRef}
                      />
                    </Grid>
                    <Grid
                      className={classes.checkboxLayout}
                      item
                      alignItems="center"
                      md={3}
                    >
                      <CheckboxCustom
                        checked={values.temporalityMaterialDatedNoRef}
                        name="temporalityMaterialDatedNoRef"
                        label="Sin referencias"
                        onChange={(e) => {
                          setFieldValue('temporalityMaterialDated', '');
                          handleChange(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                    spacing={2}
                  >
                    <Grid item md={3}>
                      <Typography className={classes.formTitle}>
                        Referencias:
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <InputText
                        placeholder={
                          values.temporalityReferencesNoRef
                            ? 'Sin referencias'
                            : ''
                        }
                        variant="outlined"
                        required
                        fullWidth
                        name="temporalityReferences"
                        autoComplete="temporalityReferences"
                        value={values.temporalityReferences}
                        disabled={values.temporalityReferencesNoRef}
                      />
                    </Grid>
                    <Grid
                      className={classes.checkboxLayout}
                      item
                      alignItems="center"
                      md={3}
                    >
                      <CheckboxCustom
                        checked={values.temporalityReferencesNoRef}
                        name="temporalityReferencesNoRef"
                        label="Sin referencias"
                        onChange={(e) => {
                          setFieldValue('temporalityReferences', '');
                          handleChange(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </SubSection>
          <SubSection title="Observaciones ">
            <Box>
              <Grid container alignItems="start" className={classes.gridLayout}>
                <Grid item md={3}>
                  <Typography className={classes.formTitle}>
                    Observaciones contexto original:
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <InputText
                    variant="outlined"
                    multiline
                    rows={5}
                    required
                    fullWidth
                    name="observationsContext"
                    value={values.observationsContext}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </SubSection>
        </Box>
      </Section>
    </Box>
  );
};

export default OriginalContext;
