export const origin = [
  {
    value: 1,
    label: 'Donación',
  },
  {
    value: 2,
    label: 'Entrega voluntaria',
  },
  {
    value: 3,
    label: 'Salvataje/rescate',
  },
  {
    value: 4,
    label: 'Incautación',
  },
  {
    value: 5,
    label: 'Evidencia',
  },
  {
    value: 6,
    label: 'Compra',
  },
  {
    value: 7,
    label: 'Excavación y/o recolección en investigación',
  },
  {
    value: 8,
    label: 'Excavación y/o recolección de impacto ambiental',
  },
  {
    value: 9,
    label: 'Otro',
  },
];

export const originYear = () => {
  const max = new Date().getFullYear();
  const min = 2014;
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push({
      value: i,
      label: i,
    });
  }

  return years;
};

export const originMonth = [
  {
    value: 1,
    label: 'Enero',
  },
  {
    value: 2,
    label: 'Febrero',
  },
  {
    value: 3,
    label: 'Marzo',
  },
  {
    value: 4,
    label: 'Abril',
  },
  {
    value: 5,
    label: 'Mayo',
  },
  {
    value: 6,
    label: 'Junio',
  },
  {
    value: 7,
    label: 'Julio',
  },
  {
    value: 8,
    label: 'Agosto',
  },
  {
    value: 9,
    label: 'Septiembre',
  },
  {
    value: 10,
    label: 'Octubre',
  },
  {
    value: 11,
    label: 'Noviembre',
  },
  {
    value: 12,
    label: 'Diciembre',
  },
];

export const actualLocation = [
  {
    value: 1,
    label: 'En deposito',
  },
  {
    value: 2,
    label: 'En exhibición',
  },
  {
    value: 3,
    label: 'En deposito transitorio',
  },
  {
    value: 4,
    label: 'En préstamo',
  },
];

export const getActualLocation = (actualLocationId) => {
  return actualLocation.find((location) => location.value === actualLocationId)
    .label;
};

export const actualLocationItems = {
  1: [
    {
      value: 1,
      label: 'Estante',
    },
    {
      value: 2,
      label: 'Caja',
    },
  ],
  2: [
    {
      value: 3,
      label: 'Sala',
    },
    {
      value: 4,
      label: 'Vitrina',
    },
  ],
  3: [
    {
      value: 5,
      label: 'Estante',
    },
    {
      value: 6,
      label: 'Caja',
    },
  ],
};

export const getActualLocationItem = (
  actualLocationId,
  actualLocationItemId
) => {
  return actualLocationItems[actualLocationId].find(
    (location) => location.value === actualLocationItemId
  ).label;
};

export const collections = [
  {
    value: 1,
    label: 'Arqueológica',
  },
  {
    value: 2,
    label: 'Histórica',
  },
  {
    value: 3,
    label: 'Bioantropológica',
  },
  {
    value: 4,
    label: 'Etnográfica',
  },
];

export const cultures = [
  {
    value: 1,
    label: 'Unidad',
  },
  {
    value: 2,
    label: 'Conjunto',
  },
  {
    value: 3,
    label: 'Bolsa',
  },
  {
    value: 4,
    label: 'Caja',
  },
];

export const materials = [
  {
    value: 1,
    label: 'Cerámica',
  },
  {
    value: 2,
    label: 'Lítico',
  },
  {
    value: 3,
    label: 'Óseo animal',
  },
  {
    value: 4,
    label: 'Madera',
  },
  {
    value: 5,
    label: 'Textil',
  },
  {
    value: 6,
    label: 'Metal',
  },
  {
    value: 7,
    label: 'Vidrio',
  },
  {
    value: 8,
    label: 'Concha',
  },
  {
    value: 9,
    label: 'Vegetal',
  },
  {
    value: 10,
    label: 'Bioantropológico',
  },
  {
    value: 11,
    label: 'Loza',
  },
  {
    value: 12,
    label: 'Plástico',
  },
  {
    value: 13,
    label: 'Yeso',
  },
  {
    value: 14,
    label: 'Cemento',
  },
  {
    value: 15,
    label: 'Otro',
  },
];

export const materialNames = {
  1: 'Cerámica',
  2: 'Lítico',
  3: 'Óseo Animal',
  4: 'Madera',
  5: 'Textil',
  6: 'Metal',
  7: 'Vidrio',
  8: 'Malacológico',
  9: 'Vegetal',
  10: 'Loza',
  11: 'Plástico',
  12: 'Yeso',
  13: 'Cemento',
  14: 'Sedimento',
  15: 'Quincha',
  16: 'Otro',
};

export const materialItems = {
  1: [
    {
      value: 1,
      label: 'Tejas',
    },
    {
      value: 2,
      label: 'Adobe',
    },
    {
      value: 3,
      label: 'Figurilla',
    },
    {
      value: 4,
      label: 'Plato',
    },
    {
      value: 5,
      label: 'Vaso',
    },
    {
      value: 6,
      label: 'Jarro',
    },
    {
      value: 7,
      label: 'Botella',
    },
    {
      value: 8,
      label: 'Olla',
    },
    {
      value: 9,
      label: 'Tembetá',
    },
    {
      value: 10,
      label: 'Pipa',
    },
    {
      value: 11,
      label: 'Tortera',
    },
    {
      value: 12,
      label: 'Aríbalo',
    },
    {
      value: 13,
      label: 'Botella Aribaloide',
    },
    {
      value: 14,
      label: 'Escudilla',
    },
    {
      value: 15,
      label: 'Puco',
    },
    {
      value: 16,
      label: 'Urna',
    },
    {
      value: 17,
      label: 'Vaso Doble',
    },
    {
      value: 18,
      label: 'Taza',
    },
    {
      value: 19,
      label: 'Fragmentos Sin Identificar',
    },
    {
      value: 20,
      label: 'Otro (Abierto)',
    },
  ],
  2: [
    {
      value: 21,
      label: 'Adoquín',
    },
    {
      value: 22,
      label: 'Punta De Proyectil',
    },
    {
      value: 23,
      label: 'Boleadora',
    },
    {
      value: 24,
      label: 'Mano De Moler',
    },
    {
      value: 25,
      label: 'Mortero',
    },
    {
      value: 26,
      label: 'Conana',
    },
    {
      value: 27,
      label: 'Piedra Horadada',
    },
    {
      value: 28,
      label: 'Tortera',
    },
    {
      value: 29,
      label: 'Pulidor',
    },
    {
      value: 30,
      label: 'Hacha',
    },
    {
      value: 31,
      label: 'Tembetá',
    },
    {
      value: 32,
      label: 'Cuenta',
    },
    {
      value: 33,
      label: 'Lasca',
    },
    {
      value: 34,
      label: 'Instrumento Indeterminado',
    },
    {
      value: 35,
      label: 'Raspador/Raedera',
    },
    {
      value: 36,
      label: 'Cuchillo',
    },
    {
      value: 37,
      label: 'Percutor',
    },
    {
      value: 38,
      label: 'Sobador',
    },
    {
      value: 39,
      label: 'Otro (Abierto)',
    },
  ],
  3: [
    {
      value: 40,
      label: 'Anzuelo',
    },
    {
      value: 41,
      label: 'Punta De Proyectil',
    },
    {
      value: 42,
      label: 'Instrumento Aguzado',
    },
    {
      value: 43,
      label: 'Desecho',
    },
    {
      value: 44,
      label: 'Otro (Abierto)',
    },
  ],
  4: [
    {
      value: 45,
      label: 'Tabique',
    },
    {
      value: 46,
      label: 'Cerrojo',
    },
    {
      value: 47,
      label: 'Bisagra',
    },
    {
      value: 48,
      label: 'Lanza',
    },
    {
      value: 49,
      label: 'Máscara',
    },
    {
      value: 50,
      label: 'Kultrún',
    },
    {
      value: 51,
      label: 'Piano',
    },
    {
      value: 52,
      label: 'Vitrola',
    },
    {
      value: 53,
      label: 'Cencerro',
    },
    {
      value: 54,
      label: 'Palín',
    },
    {
      value: 55,
      label: 'Otro (Abierto)',
    },
  ],
  5: [
    {
      value: 56,
      label: 'bolsa',
    },
    {
      value: 57,
      label: 'hilado',
    },
    {
      value: 58,
      label: 'vestimenta indeterminada',
    },
    {
      value: 59,
      label: 'otro (abierto)',
    },
  ],
  6: [
    {
      value: 60,
      label: 'Bisagra',
    },
    {
      value: 61,
      label: 'Lámpara',
    },
    {
      value: 62,
      label: 'Chapa',
    },
    {
      value: 63,
      label: 'Llave',
    },
    {
      value: 64,
      label: 'Clavo',
    },
    {
      value: 65,
      label: 'Figurilla',
    },
    {
      value: 66,
      label: 'Contenedor Indeterminado',
    },
    {
      value: 67,
      label: 'Taparrabo',
    },
    {
      value: 68,
      label: 'Tupu',
    },
    {
      value: 69,
      label: 'Espaldar',
    },
    {
      value: 70,
      label: 'Letrero',
    },
    {
      value: 71,
      label: 'Proyectora',
    },
    {
      value: 72,
      label: 'Moneda',
    },
    {
      value: 73,
      label: 'Cuchillo',
    },
    {
      value: 74,
      label: 'Otro (Abierto)',
    },
  ],
  7: [
    {
      value: 75,
      label: 'Contenedor Indeterminado',
    },
    {
      value: 76,
      label: 'Desecho',
    },
    {
      value: 77,
      label: 'Casco',
    },
    {
      value: 78,
      label: 'Otro (Abierto)',
    },
  ],
  8: [
    {
      value: 79,
      label: 'Cuenta',
    },
    {
      value: 80,
      label: 'Desecho',
    },
    {
      value: 81,
      label: 'Otro (Abierto)',
    },
  ],
  9: [
    {
      value: 82,
      label: 'Cesto',
    },
    {
      value: 83,
      label: 'Estera',
    },
    {
      value: 84,
      label: 'Peineta',
    },
    {
      value: 85,
      label: 'Otro (Abierto)',
    },
  ],
  10: [
    {
      value: 86,
      label: 'Boquete',
    },
    {
      value: 87,
      label: 'Plato',
    },
    {
      value: 88,
      label: 'Taza',
    },
    {
      value: 89,
      label: 'Jarro',
    },
    {
      value: 90,
      label: 'Botella',
    },
    {
      value: 91,
      label: 'Desecho',
    },
    {
      value: 92,
      label: 'Otro (Abierto)',
    },
  ],
  11: [
    {
      value: 93,
      label: 'Ficha',
    },
    {
      value: 94,
      label: 'Disco Vinilo',
    },
    {
      value: 95,
      label: 'Otro (Abierto)',
    },
  ],
  12: [
    {
      value: 96,
      label: 'Figurilla',
    },
    {
      value: 97,
      label: 'Cuadro',
    },
    {
      value: 98,
      label: 'Otro (Abierto)',
    },
  ],
  13: [
    {
      value: 99,
      label: 'Letrero',
    },
    {
      value: 100,
      label: 'Otro (Abierto)',
    },
  ],
  14: [
    {
      value: 101,
      label: 'Sedimento',
    },
  ],
  15: [
    {
      value: 102,
      label: 'Quincha',
    },
  ],
};

export const funcionallity = [
  {
    value: 1,
    label: 'Funerario',
  },
  {
    value: 2,
    label: 'Doméstico',
  },
  {
    value: 3,
    label: 'Tarea',
  },
  {
    value: 4,
    label: 'Sin referencia',
  },
];

export const obtained = [
  {
    value: 1,
    label: 'Excavación sistemática',
  },
  {
    value: 2,
    label: 'Excavación no sistemática',
  },
  {
    value: 3,
    label: 'Donación',
  },
  {
    value: 4,
    label: 'Recolección sistemática',
  },
  {
    value: 5,
    label: 'Recolección asistemática',
  },
  {
    value: 6,
    label: 'Sin referencia',
  },
];

export const genderList = [
  {
    value: 1,
    label: 'Masculino',
  },
  {
    value: 2,
    label: 'Femenino',
  },
  {
    value: 3,
    label: 'Indeterminado',
  },
  {
    value: 4,
    label: 'Probablemente femenino',
  },
  {
    value: 5,
    label: 'Probablemente masculino',
  },
  {
    value: 6,
    label: 'No observable',
  },
  {
    value: 7,
    label: 'Sin información',
  },
];

export const ageList = [
  {
    value: 1,
    label: 'Indeterminado',
  },
  {
    value: 2,
    label: 'Feto (En útero)',
  },
  {
    value: 3,
    label: 'Infante (0-3 años)',
  },
  {
    value: 4,
    label: 'Niño (3-12 años)',
  },
  {
    value: 5,
    label: 'Adolescente (12-20 años)',
  },
  {
    value: 6,
    label: 'Adulto Joven (20-35 años)',
  },
  {
    value: 7,
    label: 'Adulto Medio (35-50 años)',
  },
  {
    value: 8,
    label: 'Adulto Maduro (50-100 años)',
  },
  {
    value: 9,
    label: 'Otro',
  },
  {
    value: 10,
    label: 'No observable',
  },
];
