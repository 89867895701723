/* eslint-disable react/no-array-index-key */

import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { collectionStyle } from './Index';

const styles = StyleSheet.create({
  name: {
    fontFamily: 'Lato Bold',
    fontSize: 14,
    marginBottom: 10,
    textTransform: 'uppercase',
  },
  container: {
    flex: 1,
    paddingTop: 30,
    paddingLeft: 15,
  },
  leftColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  title: {
    fontSize: 11,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  description: {
    fontSize: 11,
    color: '#65748B',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '4px',
  },
});

const Information = ({ article }) => (
  <View style={styles.container}>
    <Text style={styles.name}>{article && article.name}</Text>
    <View style={styles.leftColumn}>
      <View style={styles.row}>
        <Text style={styles.title}>Nº de inventario - </Text>
        <Text style={styles.description}>
          {article && article.inventaryNumber}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Colección - </Text>
        <Text style={styles.description}>
          {article && article.collection
            ? collectionStyle[article.collection].name
            : 'Sin datos'}
        </Text>
      </View>
    </View>
  </View>
);

export default Information;
