import { Avatar, Button, Grid, Tooltip } from '@mui/material';
import { styled } from '@mui/styles';
import { Box } from '@mui/system';
import { getData } from 'api/queries';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import ImageGallery from 'react-image-gallery';
import './image-gallery.scss';
import { useUserDataContext } from 'contexts/UserDataContext';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Index from './Pdfs/private/Index';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Card } from './../Articles/ArticlesHome';
import emptyState from 'assets/images/empty_state.png';
import TableComponent from 'components/Table/Table';
import { format, parseISO } from 'date-fns';
import { useLocation } from 'react-router-dom';

const CardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '2rem',
  boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
  borderRadius: '8px',
});

const Title = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontSize: '18px',
  fontWeight: 500,
});

const Table = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
});

const Tr = styled('tr')({
  borderBottom: '3px solid #F3F4F6',

  '&:first-child': {
    borderTop: '3px solid #F3F4F6',
  },

  '&:last-child': {
    borderBottom: '0',
  },
});

const Td = styled('td')({
  textAlign: 'left',
  padding: '1.5rem',
  verticalAlign: 'middle',
});

export const collectionStyle = {
  1: {
    name: 'Colección Arqueológica',
    color: '#FEB460',
  },
  2: {
    name: 'Colección Histórica',
    color: '#bc0b0b',
  },
  3: {
    name: 'Colección Bioantropológica',
    color: '#A9C3B6',
  },
  4: {
    name: 'Colección Etnográfica',
    color: '#8FB8CA',
  },
  5: {
    name: 'Otra colección',
    color: '#6D8B74',
  },
  6: {
    name: 'Sin colección',
    color: '#CCCCCC',
  },
};

export const materialsIndividual = {
  1: 'Esqueleto',
  2: 'Momia',
};

export const completenessIdentification = {
  1: 'Completo',
  2: 'Semicompleto',
  3: 'Incompleto',
};

export const completeness = {
  1: 'Completo',
  2: 'Semicompleto',
  3: 'Incompleto',
  4: 'Altamente incompleto',
  5: 'No aplica',
};

export const genderList = {
  1: 'Masculino',
  2: 'Femenino',
  3: 'Indeterminado',
  4: 'Probablemente femenino',
  5: 'Probablemente masculino',
  6: 'No observable',
  7: 'Sin información',
};

export const ageList = {
  1: 'Indeterminado',
  2: 'Feto (En útero)',
  3: 'Infante (0-3 años)',
  4: 'Niño (3-12 años)',
  5: 'Adolescente (12-20 años)',
  6: 'Adulto Joven (20-35 años)',
  7: 'Adulto Medio (35-50 años)',
  8: 'Adulto Maduro (50-100 años)',
  9: 'Otro',
  10: 'No observable',
};

export const origins = {
  1: 'Donación',
  2: 'Entrega voluntaria',
  3: 'Salvataje/rescate',
  4: 'Incautación',
  5: 'Evidencia',
  6: 'Compra',
  7: 'Excavación y/o recolección en investigación',
  8: 'Excavación y/o recolección de impacto ambiental',
  9: 'Otro',
};

export const months = {
  1: 'Enero',
  2: 'Febrero',
  3: 'Marzo',
  4: 'Abril',
  5: 'Mayo',
  6: 'Junio',
  7: 'Julio',
  8: 'Agosto',
  9: 'Septiembre',
  10: 'Octubre',
  11: 'Noviembre',
  12: 'Diciembre',
};

export const coordinates = {
  1: 'UTM',
  2: 'Geográfica',
  3: 'Sin referencia',
};

export const functionalities = {
  1: 'Funerario',
  2: 'Doméstico',
  3: 'Tarea',
  4: 'Sin referencias',
  5: 'Otra',
};

export const numbers = {
  1: '01',
  2: '02',
  3: '03',
  4: '04',
  5: '05',
  6: '06',
  7: '07',
  8: '08',
  9: '09',
};

export const records = {
  1: 'Artefacto',
  2: 'Conjunto',
  3: 'Bolsa',
};

export const findings = {
  1: 'Entierro primario',
  2: 'Entierro secundario',
  3: 'Hallazgo aislado',
  4: 'Otro',
  5: 'Sin referencias',
};

export const conservations = {
  1: 'Muy bueno',
  2: 'Bueno',
  3: 'Regular',
  4: 'Malo',
  5: 'Muy malo',
};

export const setNumber = (number) => {
  if (String(number).length === 1) {
    return numbers[number];
  } else {
    return number;
  }
};

const ArticlePrivateCatalog = () => {
  const history = useHistory();
  const location = useLocation();
  const { articleId } = useParams();
  const [article, setArticle] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { isStaff } = useUserDataContext();
  const [imagesPhotography, setImagesPhotography] = useState([]);
  const [imagesLabel, setImagesLabel] = useState([]);
  const [skeletonAsociatedArticles, setSkeletonAsociatedArticles] = useState();
  const [asociatedArticles, setAsociatedArticles] = useState([]);
  const [currentUrlParams, setCurrentUrlParams] = useState({});

  const getIndividualAsociated = async (asociatedNumber) => {
    setSkeletonAsociatedArticles(true);
    await getData(`/articles/associated_guy/${asociatedNumber}`).then(
      (response) => {
        setAsociatedArticles(response);
        setSkeletonAsociatedArticles(false);
      }
    );
  };

  const getImagesPhotography = (images) => {
    let array = [];
    if (images && images.length > 0) {
      images.forEach((image) => {
        let mainImage = '';
        let format = '';
        mainImage = image.url.split('.')[0];
        format = image.url.split('.')[1];
        if (
          format === 'jpg' ||
          format === 'JPG' ||
          format === 'png' ||
          format === 'PNG' ||
          format === 'jpeg' ||
          format === 'JPEG'
        ) {
          array.push({
            original: `${process.env.REACT_APP_URL_STORAGE}${mainImage}_large.${format}`,
            thumbnail: `${process.env.REACT_APP_URL_STORAGE}${mainImage}_large.${format}`,
          });
        }
      });
    } else {
      array.push({
        original: `${process.env.REACT_APP_URL_PUBLIC_STORAGE}/empty_state.png`,
        thumbnail: `${process.env.REACT_APP_URL_PUBLIC_STORAGE}/empty_state.png`,
      });
    }
    setImagesPhotography(array);
  };

  const getImagesLabel = (images) => {
    let array = [];
    if (images && images.length > 0) {
      images.forEach((image) => {
        array.push({
          original: `${process.env.REACT_APP_URL_STORAGE}${image.url}`,
          thumbnail: `${process.env.REACT_APP_URL_STORAGE}${image.url}`,
        });
      });
    }
    setImagesLabel(array);
  };

  const getArticleData = async () => {
    setIsLoading(true);
    await getData(
      `/articles/${articleId}?view=${isStaff ? 'full' : 'single'}`
    ).then((data) => {
      setArticle(data);
      getImagesPhotography(data.uploads.materialPhotography);
      getImagesLabel(data.uploads.labelPhotography);
      if (
        data.isGuy &&
        data.isGuy === false &&
        data.numberGuy &&
        data.numberGuy.length > 0
      ) {
        getIndividualAsociated(data.numberGuy);
      } else if (
        data.isGuy &&
        data.isGuy === true &&
        data.inventaryNumber &&
        data.inventaryNumber.length > 0
      ) {
        getIndividualAsociated(data.inventaryNumber);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (history.location?.state?.currentUrlParams) {
      setCurrentUrlParams(history.location?.state?.currentUrlParams);
    }
    window.scrollTo(0, 0);
    if (articleId) {
      getArticleData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        padding: '2rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            if (location.state?.fromHome) {
              history.push('/museo/articulos');
            } else {
              let filtersQuery = '';
              if (currentUrlParams) {
                const params = currentUrlParams;

                if (params.page) {
                  filtersQuery = `page=${params.page}`;
                }

                if (params.query) {
                  filtersQuery = `${filtersQuery}&query=${params.query}`;
                }

                if (params.functionality) {
                  filtersQuery = `${filtersQuery}&functionality=${params.functionality}`;
                }

                if (params.material) {
                  filtersQuery = `${filtersQuery}&material=${params.material}`;
                }

                if (params.collection) {
                  filtersQuery = `${filtersQuery}&collection=${params.collection}`;
                }
              }
              history.push(`/museo/catalogo?${filtersQuery}`);
            }
          }}
          sx={{ marginBottom: '2rem' }}
        >
          Volver
        </Button>
        {isLoading ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={36}
            width={140}
            sx={{ borderRadius: '8px' }}
          />
        ) : (
          <PDFDownloadLink
            document={<Index article={article} imagesLabel={imagesLabel} />}
            fileName={`Museo_quillota_articulo_${articleId}`}
            style={{ textDecoration: 'none' }}
          >
            {({ loading }) =>
              loading ? (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginBottom: '2rem' }}
                >
                  Cargando...
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginBottom: '2rem' }}
                  endIcon={<PictureAsPdfIcon />}
                >
                  Descargar
                </Button>
              )
            }
          </PDFDownloadLink>
        )}
      </Box>
      <Box sx={{}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span
            style={{
              fontSize: '20px',
              color: '#444444',
            }}
          >
            {isLoading ? (
              <Skeleton variant="text" animation="wave" width="16rem" />
            ) : (
              `Nº: ${article.inventaryNumber} - ${article.name}`
            )}
          </span>
          <span
            style={{
              color: '#9a9a9a',
              fontSize: '16px',
            }}
          >
            {isLoading ? (
              <Skeleton variant="text" animation="wave" width="16rem" />
            ) : (
              `${
                article.collection
                  ? collectionStyle[article.collection].name
                  : collectionStyle[6].name
              }`
            )}
          </span>
        </Box>
        <Box
          sx={{
            border: `2px solid ${
              article.collection
                ? collectionStyle[article.collection].color
                : collectionStyle[6].color
            }`,
            marginTop: '8px',
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CardContainer style={{ width: '40rem', padding: '1rem' }}>
          {isLoading ? (
            <Skeleton animation="wave" variant="rectangular" height={400} />
          ) : (
            <ImageGallery items={imagesPhotography} />
          )}
        </CardContainer>
      </Box>
      <CardContainer>
        <Box
          sx={{
            padding: '1.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title>Información administrativa</Title>
        </Box>
        <Table>
          <Tr>
            <Td style={{ width: '20%' }}>Nombre responsable</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.responsibleNameNoRef ? (
                'Sin responsable'
              ) : (
                article.responsibleFirstname + ' ' + article.responsibleLastname
              )}
            </Td>
            <Td style={{ width: '20%' }}>Procedencia</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : (
                origins[article.origin]
              )}
            </Td>
          </Tr>
          <Tr>
            <Td style={{ width: '20%' }}>Año/mes de procedencia</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.originDateNoRef ? (
                'Sin referencia de fecha'
              ) : (
                article.originYear + '/' + months[article.originMonth]
              )}
            </Td>
            <Td style={{ width: '20%' }}>
              Asociación a informes de sitio /contexto
            </Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.associationContext ? (
                'Sí'
              ) : (
                'No'
              )}
            </Td>
          </Tr>
          <Tr>
            <Td style={{ width: '20%' }}>Año de ingreso MHAQ</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.yearEntry ? (
                article.yearEntry
              ) : (
                'Sin referencia'
              )}
            </Td>
            <Td style={{ width: '20%' }}>Nº de caja</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : (
                article.inventaryBox
              )}
            </Td>
          </Tr>
          <Tr>
            <Td style={{ width: '20%' }}>Ubicación actual MHAQ</Td>
            <Td colSpan="2" style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : (
                article.logUbications[0].observations
              )}
            </Td>
          </Tr>
        </Table>
      </CardContainer>
      <CardContainer>
        <Box
          sx={{
            padding: '1.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title>Contexto original</Title>
        </Box>
        <Table>
          <Tr>
            <Td style={{ width: '20%' }}>Nombre del sitio</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.siteName ? (
                article.siteName
              ) : (
                'Sin datos'
              )}
            </Td>
            <Td style={{ width: '20%' }}>Código del sitio</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.siteCode ? (
                article.siteCode
              ) : (
                'Sin datos'
              )}
            </Td>
          </Tr>
          <Tr>
            <Td style={{ width: '20%' }}>Comuna</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.comune ? (
                article.comune
              ) : (
                'Sin datos'
              )}
            </Td>
            <Td style={{ width: '20%' }}>Localidad</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.locality ? (
                article.locality
              ) : (
                'Sin datos'
              )}
            </Td>
          </Tr>
          {article.hasCoordinates && (
            <>
              {article.coordinateType === 1 && (
                <>
                  <Tr>
                    <Td style={{ width: '20%' }}>UTM Este</Td>
                    <Td style={{ color: '#65748B' }}>
                      {isLoading ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          width="16rem"
                        />
                      ) : article.coordinateInfo.utmEast ? (
                        article.coordinateInfo.utmEast
                      ) : (
                        'Sin datos'
                      )}
                    </Td>
                    <Td style={{ width: '20%' }}>UTM Norte</Td>
                    <Td style={{ color: '#65748B' }}>
                      {isLoading ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          width="16rem"
                        />
                      ) : article.coordinateInfo.utmNorth ? (
                        article.coordinateInfo.utmNorth
                      ) : (
                        'Sin datos'
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td style={{ width: '20%' }}>Datum</Td>
                    <Td style={{ color: '#65748B' }}>
                      {isLoading ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          width="16rem"
                        />
                      ) : article.coordinateInfo.datum ? (
                        article.coordinateInfo.datum
                      ) : article.coordinateInfo.datumNoRef ? (
                        'Sin referencias'
                      ) : (
                        'Sin datos'
                      )}
                    </Td>
                    <Td style={{ width: '20%' }}>Altitud</Td>
                    <Td style={{ color: '#65748B' }}>
                      {isLoading ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          width="16rem"
                        />
                      ) : article.coordinateInfo.altitude ? (
                        article.coordinateInfo.altitude
                      ) : article.coordinateInfo.altitudeNoRef ? (
                        'Sin referencias'
                      ) : (
                        'Sin datos'
                      )}
                    </Td>
                  </Tr>
                </>
              )}
              {article.coordinateType === 2 && (
                <Tr>
                  <Td style={{ width: '20%' }}>Latitud</Td>
                  <Td style={{ color: '#65748B' }}>
                    {isLoading ? (
                      <Skeleton variant="text" animation="wave" width="16rem" />
                    ) : article.coordinateInfo.latitude ? (
                      article.coordinateInfo.latitude
                    ) : (
                      'Sin datos'
                    )}
                  </Td>
                  <Td style={{ width: '20%' }}>Longitud</Td>
                  <Td style={{ color: '#65748B' }}>
                    {isLoading ? (
                      <Skeleton variant="text" animation="wave" width="16rem" />
                    ) : article.coordinateInfo.longitude ? (
                      article.coordinateInfo.longitude
                    ) : (
                      'Sin datos'
                    )}
                  </Td>
                </Tr>
              )}
              {article.coordinateType === 3 && (
                <Tr>
                  <Td style={{ width: '20%' }}>Coordenadas</Td>
                  <Td style={{ color: '#65748B' }}>
                    {isLoading ? (
                      <Skeleton variant="text" animation="wave" width="16rem" />
                    ) : (
                      'Sin coodernadas'
                    )}
                  </Td>
                </Tr>
              )}
            </>
          )}
          <Tr>
            <Td style={{ width: '20%' }}>Funcionalidad del sitio</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : (
                article.functionalities &&
                article.functionalities.map((func, index) => {
                  const rowLen = article.functionalities.length;
                  return (
                    <>
                      {rowLen === index + 1
                        ? `${functionalities[func]}`
                        : `${functionalities[func]}, `}
                    </>
                  );
                })
              )}
            </Td>
            {article.otherFunctionality && (
              <>
                <Td style={{ width: '20%' }}>Otra funcionalidad</Td>
                <Td style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    article.otherFunctionality
                  )}
                </Td>
              </>
            )}
          </Tr>
          <Tr>
            <Td
              colSpan="4"
              style={{ fontWeight: 500, backgroundColor: '#F3F4F6' }}
            >
              Localización estratégica:
            </Td>
          </Tr>
          <Tr>
            <Td style={{ width: '20%' }}>Unidad</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.localizationUnit &&
                article.localizationUnit.length > 0 ? (
                article.localizationUnit
              ) : article.localizationUnitNoRef ? (
                'Sin referencias'
              ) : (
                'Sin datos'
              )}
            </Td>
            <Td style={{ width: '20%' }}>Capa</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.localizationLayer &&
                article.localizationLayer.length > 0 ? (
                article.localizationLayer
              ) : article.localizationLayerNoRef ? (
                'Sin referencias'
              ) : (
                'Sin datos'
              )}
            </Td>
          </Tr>
          <Tr>
            <Td style={{ width: '20%' }}>Nivel</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.localizationLevel &&
                article.localizationLevel.length > 0 ? (
                article.localizationLevel
              ) : article.localizationLevelNoRef ? (
                'Sin referencias'
              ) : (
                'Sin datos'
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>Observaciones</Td>
            <Td colSpan="2" style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.localizationObservations ? (
                article.localizationObservations
              ) : (
                'Sin datos'
              )}
            </Td>
          </Tr>
          {article.hasFuneralContext && (
            <>
              <Tr>
                <Td
                  colSpan="4"
                  style={{ fontWeight: 500, backgroundColor: '#F3F4F6' }}
                >
                  Contexto funerario:
                </Td>
              </Tr>
              <Tr>
                <Td style={{ width: '20%' }}>
                  Tumba/enterratorio/conjunto funerario
                </Td>
                <Td style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.grave !== null ? (
                    article.grave
                  ) : (
                    'Sin datos'
                  )}
                </Td>
                {article.numberGuy && (
                  <>
                    <Td style={{ width: '20%' }}>Nº individuo asociado</Td>
                    <Td style={{ color: '#65748B' }}>
                      {isLoading ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          width="16rem"
                        />
                      ) : article.numberGuy.length > 0 ? (
                        article.numberGuy
                      ) : (
                        'Sin datos'
                      )}
                    </Td>
                  </>
                )}
              </Tr>
              <Tr>
                <Td style={{ width: '20%' }}>Nombre responsable</Td>
                <Td style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.responsibleFuneralName ? (
                    article.responsibleFuneralName
                  ) : (
                    'Sin datos'
                  )}
                </Td>
                <Td style={{ width: '20%' }}>Fecha obtención</Td>
                <Td style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    <>
                      {!article.obtainingDateDay &&
                      !article.obtainingDateDay &&
                      !article.obtainingDateDay
                        ? 'Sin datos'
                        : `${
                            article.obtainingDateDay
                              ? setNumber(article.obtainingDateDay)
                              : 'Sin día'
                          } - ${
                            article.obtainingDateMonth
                              ? setNumber(article.obtainingDateMonth)
                              : 'Sin mes'
                          } - ${
                            article.obtainingDateYear
                              ? article.obtainingDateYear
                              : 'Sin año'
                          }`}
                    </>
                  )}
                </Td>
              </Tr>
            </>
          )}
          <Tr>
            <Td
              colSpan="4"
              style={{ fontWeight: 500, backgroundColor: '#F3F4F6' }}
            >
              Temporalidad:
            </Td>
          </Tr>
          <Tr>
            <Td style={{ width: '30%' }}>Periodo</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.period && article.period.length > 0 ? (
                article.period
              ) : article.periodNoRef ? (
                'Sin periodo asociado'
              ) : (
                'Sin datos'
              )}
            </Td>
            <Td style={{ width: '30%' }}>Adscripción cultural</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.culturalAscription &&
                article.culturalAscription.length > 0 ? (
                article.culturalAscription
              ) : article.culturalAscriptionNoRef ? (
                'Sin adscripción cultural asociada'
              ) : (
                'Sin datos'
              )}
            </Td>
          </Tr>
          <Tr>
            <Td style={{ width: '30%' }}>Cronología absoluta</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.absoluteChronology ? (
                `${
                  article.temporalityYearsBase
                    ? article.temporalityYearsBase
                    : '-'
                } ${
                  article.temporalityType ? article.temporalityType : '-'
                } ± ${
                  article.temporalityYearsSigma
                    ? article.temporalityYearsSigma
                    : '-'
                }`
              ) : (
                'Sin cronología'
              )}
            </Td>
            <Td style={{ width: '30%' }}>Código lab.</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.temporalityCodeLab ? (
                article.temporalityCodeLab
              ) : article.temporalityCodeLabNoRef ? (
                'Sin código asociado'
              ) : (
                'Sin datos'
              )}
            </Td>
          </Tr>
          <Tr>
            <Td style={{ width: '30%' }}>Material fechado</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.temporalityMaterialDated ? (
                article.temporalityMaterialDated
              ) : article.temporalityMaterialDatedNoRef ? (
                'Sin material asociado'
              ) : (
                'Sin datos'
              )}
            </Td>
            <Td style={{ width: '30%' }}>Referencias</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.temporalityReferences ? (
                article.temporalityReferences
              ) : article.temporalityReferencesNoRef ? (
                'Sin referencias asociadas'
              ) : (
                'Sin datos'
              )}
            </Td>
          </Tr>
          <Tr>
            <Td style={{ width: '30%' }}>Observaciones contexto original</Td>
            <Td colSpan="2" style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.observationsContext ? (
                article.observationsContext
              ) : (
                'Sin datos'
              )}
            </Td>
          </Tr>
        </Table>
      </CardContainer>
      {article.collection && article.collection !== 3 && (
        <CardContainer>
          <Box
            sx={{
              padding: '1.5rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Title>Identificación del material</Title>
          </Box>
          <Table>
            <Tr>
              <Td style={{ width: '20%' }}>Condición de registro</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.recordCondition ? (
                  records[article.recordCondition]
                ) : (
                  'Sin datos'
                )}
              </Td>
            </Tr>
            <Tr>
              <Td style={{ width: '20%' }}>Material</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : (
                  <>
                    {article.collection &&
                      article.collection !== 3 &&
                      article.material.map((m, index) => {
                        const rowLen = article.material.length;
                        return (
                          <>
                            {rowLen === index + 1 ? `${m.name}` : `${m.name}, `}
                          </>
                        );
                      })}
                    {article.collection &&
                      article.collection !== 3 &&
                      article.material.length === 0 &&
                      'Material no registrado'}
                    {!article.collection && 'Sin colección'}
                  </>
                )}
              </Td>
            </Tr>
            <Tr>
              <Td style={{ width: '20%' }}>Tipo de manufactura</Td>
              <Td style={{ color: '#65748B' }} colSpan="2">
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.typeManufacture ? (
                  article.typeManufacture
                ) : (
                  'Sin datos'
                )}
              </Td>
            </Tr>
            <Tr>
              <Td
                colSpan="4"
                style={{ fontWeight: 500, backgroundColor: '#F3F4F6' }}
              >
                Dimensiones
              </Td>
            </Tr>
            <Tr>
              <Td style={{ width: '30%' }}>Alto</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.dimensions.height ? (
                  article.dimensions.height
                ) : (
                  'Sin datos'
                )}
              </Td>
              <Td style={{ width: '30%' }}>Ancho</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.dimensions.width ? (
                  article.dimensions.width
                ) : (
                  'Sin datos'
                )}
              </Td>
            </Tr>
            <Tr>
              <Td style={{ width: '30%' }}>Largo</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.dimensions.lenght ? (
                  article.dimensions.lenght
                ) : (
                  'Sin datos'
                )}
              </Td>
              <Td style={{ width: '30%' }}>Espesor</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.dimensions.thickness ? (
                  article.dimensions.thickness
                ) : (
                  'Sin datos'
                )}
              </Td>
            </Tr>
            <Tr>
              <Td style={{ width: '30%' }}>Diámetro</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.dimensions.diameter ? (
                  article.dimensions.diameter
                ) : (
                  'Sin datos'
                )}
              </Td>
            </Tr>
          </Table>
        </CardContainer>
      )}
      {article.collection && article.collection === 3 && (
        <CardContainer>
          <Box
            sx={{
              padding: '1.5rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Title>Identificación</Title>
          </Box>
          <Table>
            <Tr>
              <Td style={{ width: '30%' }}>Contexto de hallazgo</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.typeFinding ? (
                  findings[article.typeFinding]
                ) : (
                  'Sin datos'
                )}
              </Td>
              <Td style={{ width: '30%' }}>Observaciones</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.observationsFinding ? (
                  article.observationsFinding
                ) : (
                  'Sin datos'
                )}
              </Td>
            </Tr>
            <Tr>
              <Td style={{ width: '30%' }}>Individuo</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.individual ? (
                  article.individual
                ) : (
                  'Sin datos'
                )}
              </Td>
              <Td style={{ width: '20%' }}>Material</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : (
                  <>
                    {article.materialIndividual !== '3'
                      ? materialsIndividual[article.materialIndividual]
                      : article.materialIndividual === '3'
                      ? article.otherMaterialIndividual
                      : 'Sin datos'}
                    {article.materialIndividual === null && 'Sin datos'}
                  </>
                )}
              </Td>
            </Tr>
            <Tr>
              <Td style={{ width: '20%' }}>Completitud</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.completeness ? (
                  completeness[article.completeness]
                ) : (
                  'Sin datos'
                )}
              </Td>
              <Td style={{ width: '20%' }}>Número de individuos</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : (
                  <>
                    {article.numberIndividual
                      ? article.numberIndividual
                      : 'Sin datos'}
                  </>
                )}
              </Td>
            </Tr>
            <Tr>
              <Td style={{ width: '20%' }}>Sexo</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.sexIndividual ? (
                  genderList[article.sexIndividual]
                ) : (
                  'Sin datos'
                )}
              </Td>
              <Td style={{ width: '20%' }}>Edad</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.ageIndividual ? (
                  ageList[article.ageIndividual]
                ) : (
                  'Sin datos'
                )}
              </Td>
            </Tr>
          </Table>
        </CardContainer>
      )}
      <CardContainer style={{ marginBottom: '1rem', padding: '1rem' }}>
        <Box
          sx={{
            padding: '0.5rem 0.5rem 1.5rem 0.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title>Ajuar / Ofrenda asociados</Title>
        </Box>
        <Box
          sx={{
            maxHeight: '340px',
            overflow: 'auto',
            overflowY: 'scroll',
            padding: '16px',
            backgroundColor: '#F0F0F0',
            borderRadius: '8px',
          }}
        >
          {asociatedArticles.length > 0 && !skeletonAsociatedArticles && (
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {asociatedArticles.map(
                (
                  { id, collection, inventaryNumber, material, photography },
                  index
                ) => (
                  <Grid
                    onClick={() => {
                      window.location.assign(`/museo/catalogo/articulo/${id}`);
                    }}
                    item
                    xs={2}
                    sm={3}
                    md={3}
                    key={index}
                  >
                    <Card>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          padding: '1rem',
                          paddingRight: '8px',
                        }}
                      >
                        <Tooltip
                          title={
                            collection !== null
                              ? collectionStyle[collection].name
                              : collectionStyle[5].name
                          }
                          placement="top"
                        >
                          <Avatar
                            sx={{
                              bgcolor:
                                collection !== null
                                  ? collectionStyle[collection].color
                                  : collectionStyle[5].color,
                              marginRight: '12px',
                            }}
                          >
                            {collection !== null
                              ? collectionStyle[collection].svg
                              : collectionStyle[5].svg}
                          </Avatar>
                        </Tooltip>
                        <Box
                          sx={{
                            flex: '1 1 auto',
                          }}
                        >
                          <span
                            style={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: '14px',
                              lineHeight: 1.43,
                              letterSpacing: '0.01071em',
                              display: 'block',
                            }}
                          >
                            {inventaryNumber}
                          </span>
                          <span
                            style={{
                              margin: '0px',
                              fontWeight: 400,
                              fontSize: '0.875rem',
                              lineHeight: 1.43,
                              letterSpacing: '0.01071em',
                              display: 'block',
                            }}
                          >
                            {!collection
                              ? 'Sin colección'
                              : collection === '3'
                              ? 'Invividuo'
                              : 'Objeto'}
                          </span>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          display: 'flex',
                          height: '180px',
                          justifyContent: 'center',
                          backgroundColor:
                            collection !== null
                              ? collectionStyle[collection].color
                              : collectionStyle[5].color,
                          padding: '1rem',
                          borderBottomLeftRadius: '4px',
                          borderBottomRightRadius: '4px',
                        }}
                      >
                        <img
                          style={{
                            height: '100%',
                            borderRadius: '4px',
                          }}
                          src={
                            photography
                              ? `${process.env.REACT_APP_URL_STORAGE}${photography}`
                              : emptyState
                          }
                          alt="Imagen de objeto"
                        />
                      </Box>
                    </Card>
                  </Grid>
                )
              )}
            </Grid>
          )}
          {asociatedArticles.length === 0 && !skeletonAsociatedArticles && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                fontSize: '16px',
                color: '#8d8d8d',
                fontWeight: '500',
                height: '5rem',
              }}
            >
              No se han encontrado ajuares asociados con el número de individuo.
            </Box>
          )}
          {skeletonAsociatedArticles && (
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {Array.from(Array(4)).map((_, index) => (
                <Grid item xs={6} sm={3} md={3} key={index}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={200}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </CardContainer>
      {article.collection &&
        article.collection === 3 &&
        article.uploads.bioantropologic && (
          <CardContainer style={{ padding: '1rem' }}>
            <Box
              sx={{
                padding: '0.5rem 0.5rem 1.5rem 0.5rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Title>PDF asociados al registro biantropológico</Title>
            </Box>
            <Box>
              <TableComponent
                rows={article.uploads.bioantropologic}
              ></TableComponent>
            </Box>
          </CardContainer>
        )}
      <CardContainer>
        <Box
          sx={{
            padding: '1.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title>Identificación del objeto</Title>
        </Box>
        <Table>
          <Tr>
            <Td style={{ width: '20%' }}>Estado de conservación</Td>
            <Td style={{ color: '#65748B' }}>
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : article.conservationState ? (
                conservations[article.conservationState]
              ) : (
                'Sin datos'
              )}
            </Td>
            {article.collection && article.collection !== 3 && (
              <>
                <Td style={{ width: '20%' }}>Completitud</Td>
                <Td style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    <>
                      {article.completenessIdentification !== '4' && (
                        <>
                          {
                            completenessIdentification[
                              article.completenessIdentification
                            ]
                          }
                        </>
                      )}
                      {article.completenessIdentification === '4' &&
                        (article.otherCompleteness
                          ? article.otherCompleteness
                          : 'Sin datos')}
                      {article.completenessIdentification === null &&
                        'Sin datos'}
                    </>
                  )}
                </Td>
              </>
            )}
          </Tr>
          {article.sampling && (
            <>
              <Tr>
                <Td
                  colSpan="4"
                  style={{ fontWeight: 500, backgroundColor: '#F3F4F6' }}
                >
                  Toma de muestras:
                </Td>
              </Tr>
              <Tr>
                <Td style={{ width: '20%' }}>Proyecto asociado</Td>
                <Td style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    article.samplingAssociateProject &&
                    article.samplingAssociateProject
                  )}
                </Td>
                <Td style={{ width: '20%' }}>Investigador responsable</Td>
                <Td style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    article.samplingResponsibleInvestigator &&
                    article.samplingResponsibleInvestigator
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td style={{ width: '20%' }}>Fecha</Td>
                <Td style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    article.samplingDate &&
                    format(parseISO(article.samplingDate), 'dd/MM/yyyy')
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td
                  colSpan="4"
                  style={{ fontWeight: 500, backgroundColor: '#F3F4F6' }}
                >
                  Bitacora de toma de muestras
                </Td>
              </Tr>
              {article.uploads.sampling && (
                <Tr>
                  <Td colSpan="4">
                    <Box>
                      <TableComponent
                        rows={article.uploads.sampling}
                      ></TableComponent>
                    </Box>
                  </Td>
                </Tr>
              )}
              <Tr>
                <Td style={{ width: '20%' }}>Observaciones generales</Td>
                <Td style={{ color: '#65748B' }} colSpan="3">
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.observationsIdentification ? (
                    article.observationsIdentification
                  ) : (
                    'Sin datos'
                  )}
                </Td>
              </Tr>
            </>
          )}
        </Table>
      </CardContainer>
      {imagesLabel.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <CardContainer style={{ width: '40rem', padding: '1rem' }}>
            <Box
              sx={{
                padding: '1.5rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Title>Fotografías de etiqueta</Title>
            </Box>
            {isLoading ? (
              <Skeleton animation="wave" variant="rectangular" height={400} />
            ) : (
              <ImageGallery items={imagesLabel} />
            )}
          </CardContainer>
        </Box>
      )}
    </Box>
  );
};

export default ArticlePrivateCatalog;
