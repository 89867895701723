import React, { useState, useEffect } from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
  useLocation,
  useHistory,
  Redirect,
} from 'react-router-dom';
import { Form, Formik } from 'formik';
import Button from '@mui/material/Button';
import { useUserDataContext } from './../../contexts/UserDataContext';
import CustomizedStepper from '../../components/Stepper/Stepper';
import styled from 'styled-components';
import css from '@styled-system/css';
import AdministrativeInformation from './AdministrativeInformation';
import OriginalContext from './OriginalContext';
import MaterialIdentification from './MaterialIdentification';
import { Box } from '@mui/system';
import { useArticleDataContext } from 'contexts/ArticleDataContext';
import {
  useArticleCreateMutation,
  useArticleUpdateMutation,
} from 'api/mutations';
import ObjectIdentification from './ObjectIdentification';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { postPdf, postData, setPdfBucket, postAssignPdf } from 'api/queries';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

const BottomNav = styled.div(
  css({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '72px',
    borderTop: '2px solid #f9711b',
    backgroundColor: 'white',
    zIndex: 999,
  })
);

const BottomNavWrapper = styled.div(
  css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    height: '34px',
  })
);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ArticlesConfiguration = ({
  isValid,
  values,
  stepNumber,
  handleSubmit,
  handleChange,
  mapStep,
  setFieldValue,
  articlesUrl,
  handleBlur,
  setFieldTouched,
  isSubmitting,
  touched,
  errors,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { setUserAttribute } = useUserDataContext();
  const { setArticleData, setArticleAttribute, id } = useArticleDataContext();
  const [articleCreateMutation] = useArticleCreateMutation();
  const [articleUpdateMutation] = useArticleUpdateMutation(id);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState({
    message: '',
    type: '',
  });

  const onCloseAlert = () => setOpenAlert(false);

  const saveAdministrativeInformation = async (nextPage) => {
    setOpenBackdrop(true);
    await articleCreate(values)
      .then((response) => {
        if (response.statusCode) {
          setOpenBackdrop(false);
          errors.inventaryNumber = 'El número de inventario ya existe';
          setMessageAlert({
            message: 'El número de inventario ya existe.',
            type: 'warning',
          });
          return false;
        }
        setArticleData((prevState) => ({
          ...prevState,
          ...response,
        }));
        setArticleAttribute('id', response.id);
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'La colección se ha guardado correctamente.',
          type: 'success',
        });
        setOpenAlert(true);
        if (nextPage) {
          handleSubmit();
        }
        setTimeout(() => {
          setOpenAlert(false);
        }, 5000);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'Ha ocurrido un error al guardar la colección.',
          type: 'error',
        });
      });
  };

  const updateAdministrativeInformation = async (nextPage) => {
    setOpenBackdrop(true);
    await articleUpdateAdministrative(values)
      .then((response) => {
        if (response.statusCode) {
          setOpenBackdrop(false);
          errors.inventaryNumber = 'El número de inventario ya existe';
          setMessageAlert({
            message: 'El número de inventario ya existe.',
            type: 'warning',
          });
          return false;
        }
        setArticleData((prevState) => ({
          ...prevState,
          ...response,
        }));
        setArticleAttribute('id', response.id);
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'La colección se ha actualizado correctamente.',
          type: 'success',
        });
        setOpenAlert(true);
        if (nextPage) {
          handleSubmit();
        }
        setTimeout(() => {
          setOpenAlert(false);
        }, 5000);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'Ha ocurrido un error al guardar la colección.',
          type: 'error',
        });
      });
  };

  const updateOriginalContext = async (nextPage) => {
    setOpenBackdrop(true);
    await articleUpdateOriginalContext(values)
      .then((response) => {
        if (response.statusCode) {
          setOpenBackdrop(false);
          return false;
        }
        setArticleData((prevState) => ({
          ...prevState,
          ...response,
        }));
        setArticleAttribute('id', response.id);
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'La colección se ha actualizado correctamente.',
          type: 'success',
        });
        setOpenAlert(true);
        if (nextPage) {
          handleSubmit();
        }
        setTimeout(() => {
          setOpenAlert(false);
        }, 5000);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'Ha ocurrido un error al guardar la colección.',
          type: 'error',
        });
      });
  };

  const updateCollection = async (nextPage) => {
    setOpenBackdrop(true);
    await articleUpdateCollection(values)
      .then((response) => {
        if (response.statusCode) {
          setOpenBackdrop(false);
          return false;
        }
        setArticleData((prevState) => ({
          ...prevState,
          ...response,
        }));
        setArticleAttribute('id', response.id);
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'La colección se ha actualizado correctamente.',
          type: 'success',
        });
        setOpenAlert(true);
        if (nextPage) {
          handleSubmit();
        }
        setTimeout(() => {
          setOpenAlert(false);
        }, 5000);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'Ha ocurrido un error al guardar la colección.',
          type: 'error',
        });
      });
  };

  const updateMaterial = async (nextPage) => {
    setOpenBackdrop(true);
    await articleUpdateMaterial(values)
      .then((response) => {
        if (response.statusCode) {
          setOpenBackdrop(false);
          return false;
        }
        setArticleData((prevState) => ({
          ...prevState,
          ...response,
        }));
        setArticleAttribute('id', response.id);
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'La colección se ha actualizado correctamente.',
          type: 'success',
        });
        setOpenAlert(true);
        if (nextPage) {
          handleSubmit();
        }
        setTimeout(() => {
          setOpenAlert(false);
        }, 5000);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'Ha ocurrido un error al guardar la colección.',
          type: 'error',
        });
      });
  };

  const updateBioanthropology = async (nextPage) => {
    setOpenBackdrop(true);
    await articleUpdateBioanthropology(values)
      .then((response) => {
        if (response.statusCode) {
          setOpenBackdrop(false);
          return false;
        }
        setArticleData((prevState) => ({
          ...prevState,
          ...response,
        }));
        setArticleAttribute('id', response.id);
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'La colección se ha actualizado correctamente.',
          type: 'success',
        });
        setOpenAlert(true);
        if (nextPage) {
          handleSubmit();
        }
        setTimeout(() => {
          setOpenAlert(false);
        }, 5000);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'Ha ocurrido un error al guardar la colección.',
          type: 'error',
        });
      });
  };

  const uploadPdfBio = async (
    selectedFile,
    setSelectedFiles,
    getFilesByArticle
  ) => {
    if (selectedFile === '' || selectedFile === undefined) {
      setMessageAlert({
        message: 'Debes agregar un archivo primero.',
        type: 'info',
      });
      setOpenAlert(true);
      return false;
    }
    setOpenBackdrop(true);
    await postData('/uploads/get-upload-signed-url/', {
      filename: selectedFile[0].name,
      name: values.pdfNameBio !== '' ? values.pdfNameBio : selectedFile[0].name,
      type: 'bioantropologic',
    }).then(async (response) => {
      await setPdfBucket(response.signedUrl, {
        file: selectedFile,
      }).then(async (data) => {
        if (data.ok) {
          await postAssignPdf('/uploads/', {
            url: response.urlFile,
            name:
              values.pdfNameBio !== ''
                ? values.pdfNameBio
                : selectedFile[0].name,
            articleId: id,
            type: 'bioantropologic',
          })
            .then(() => {
              if (response.statusCode) {
                setOpenBackdrop(false);
                return false;
              }
              setOpenBackdrop(false);
              setMessageAlert({
                message: 'El archivo se ha subido correctamente.',
                type: 'success',
              });
              setOpenAlert(true);
              setSelectedFiles(undefined);
              setFieldValue('pdfNameBio', '');
              getFilesByArticle();
              document.getElementById('uploadPhotoBio').value = null;
            })
            .catch((e) => {
              setOpenBackdrop(false);
              setMessageAlert({
                message: 'Ha ocurrido un error al guardar la colección.',
                type: 'error',
              });
            });
        }
      });
    });
  };

  const uploadPdfSampling = async (
    selectedFile,
    setSelectedFiles,
    getFilesByArticle
  ) => {
    if (selectedFile === '' || selectedFile === undefined) {
      setMessageAlert({
        message: 'Debes agregar un archivo primero.',
        type: 'info',
      });
      setOpenAlert(true);
      return false;
    }
    setOpenBackdrop(true);
    await postData('/uploads/get-upload-signed-url/', {
      filename: selectedFile[0].name,
      name:
        values.pdfNameSampling !== ''
          ? values.pdfNameSampling
          : selectedFile[0].name,
      type: 'sampling',
    }).then(async (response) => {
      await setPdfBucket(response.signedUrl, {
        file: selectedFile,
      }).then(async (data) => {
        if (data.ok) {
          await postAssignPdf('/uploads/', {
            url: response.urlFile,
            name:
              values.pdfNameSampling !== ''
                ? values.pdfNameSampling
                : selectedFile[0].name,
            articleId: id,
            type: 'sampling',
          })
            .then(() => {
              if (response.statusCode) {
                setOpenBackdrop(false);
                return false;
              }
              setOpenBackdrop(false);
              setMessageAlert({
                message: 'El archivo se ha subido correctamente.',
                type: 'success',
              });
              setOpenAlert(true);
              setSelectedFiles(undefined);
              setFieldValue('pdfNameSampling', '');
              document.getElementById('uploadPhotoSampling').value = null;
              getFilesByArticle();
            })
            .catch((e) => {
              setOpenBackdrop(false);
              setMessageAlert({
                message: 'Ha ocurrido un error al guardar la colección.',
                type: 'error',
              });
            });
        }
      });
    });
  };

  const uploadLabelPhoto = async (selectedFile, getLabelPhotosByArticle) => {
    if (selectedFile === '' || selectedFile === undefined) {
      setMessageAlert({
        message: 'Debes agregar una imagen primero.',
        type: 'info',
      });
      setOpenAlert(true);
      return false;
    }
    setOpenBackdrop(true);
    await postPdf('/uploads/', {
      file: selectedFile,
      articleId: id,
      type: 'label_photography',
    })
      .then((response) => {
        if (response.statusCode) {
          setOpenBackdrop(false);
          return false;
        }
        setOpenBackdrop(false);
        setMessageAlert({
          message: `${
            selectedFile.length > 1 ? 'Las imagenes se han' : 'La imagen se ha'
          } subido correctamente.`,
          type: 'success',
        });
        setOpenAlert(true);
        setFieldValue('uploadPhotoLabel', '');
        getLabelPhotosByArticle();
      })
      .catch((e) => {
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'Ha ocurrido un error al guardar la colección.',
          type: 'error',
        });
      });
  };

  const uploadMaterialPhoto = async (
    selectedFile,
    getMaterialPhotosByArticle
  ) => {
    if (selectedFile === '' || selectedFile === undefined) {
      setMessageAlert({
        message: 'Debes agregar una imagen primero.',
        type: 'info',
      });
      setOpenAlert(true);
      return false;
    }
    setOpenBackdrop(true);
    await postPdf('/uploads/', {
      file: selectedFile,
      articleId: id,
      type: 'material_photography',
    })
      .then((response) => {
        if (response.statusCode) {
          setOpenBackdrop(false);
          return false;
        }
        setOpenBackdrop(false);
        setMessageAlert({
          message: `${
            selectedFile.length > 1 ? 'Las imagenes se han' : 'La imagen se ha'
          } subido correctamente.`,
          type: 'success',
        });
        setOpenAlert(true);
        getMaterialPhotosByArticle();
      })
      .catch((e) => {
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'Ha ocurrido un error al guardar la colección.',
          type: 'error',
        });
      });
  };

  const updateObjectIdentification = async (nextPage) => {
    setOpenBackdrop(true);
    await articleUpdateObjectIdentification(values)
      .then((response) => {
        if (response.statusCode) {
          setOpenBackdrop(false);
          return false;
        }
        setArticleData((prevState) => ({
          ...prevState,
          ...response,
        }));
        setArticleAttribute('id', response.id);
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'La colección se ha actualizado correctamente.',
          type: 'success',
        });
        setOpenAlert(true);
        if (nextPage) {
          handleSubmit();
        }
        setTimeout(() => {
          setOpenAlert(false);
        }, 5000);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'Ha ocurrido un error al guardar la colección.',
          type: 'error',
        });
      });
  };

  const articleCreate = (values) => {
    const {
      name,
      responsibleFirstname,
      responsibleLastname,
      responsibleNameNoRef,
      origin,
      originYear,
      originMonth,
      originDateNoRef,
      associationContext,
      inventaryNumber,
      inventaryBox,
      yearEntry,
      yearEntryNoRef,
      logUbications,
    } = values;

    return articleCreateMutation({
      name,
      responsibleFirstname,
      responsibleLastname,
      responsibleNameNoRef,
      origin,
      originYear,
      originMonth,
      originDateNoRef,
      associationContext,
      inventaryNumber,
      inventaryBox,
      yearEntry,
      yearEntryNoRef,
      logUbications,
    });
  };

  const articleUpdateAdministrative = (values) => {
    const {
      name,
      responsibleFirstname,
      responsibleLastname,
      responsibleNameNoRef,
      origin,
      originYear,
      originMonth,
      originDateNoRef,
      associationContext,
      inventaryNumber,
      inventaryBox,
      yearEntry,
      yearEntryNoRef,
      logUbications,
    } = values;

    return articleUpdateMutation({
      name,
      responsibleFirstname,
      responsibleLastname,
      responsibleNameNoRef,
      origin,
      originYear,
      originMonth,
      originDateNoRef,
      associationContext,
      inventaryNumber,
      inventaryBox,
      yearEntry,
      yearEntryNoRef,
      logUbications,
    });
  };

  const articleUpdateOriginalContext = (values) => {
    const {
      siteName,
      siteCode,
      comune,
      locality,
      hasCoordinates,
      coordinateType,
      utmEast,
      utmNorth,
      datum,
      datumNoRef,
      altitude,
      altitudeNoRef,
      latitude,
      longitude,
      functionalities,
      otherFunctionality,
      localizationUnit,
      localizationUnitNoRef,
      localizationLayer,
      localizationLayerNoRef,
      localizationLevel,
      localizationLevelNoRef,
      localizationObservations,
      hasFuneralContext,
      isGuy,
      grave,
      graveNoRef,
      numberGuy,
      numberGuyNoRef,
      responsibleFuneralName,
      obtainingDateYear,
      obtainingDateNoRef,
      obtainingDateMonth,
      obtainingDateDay,
      period,
      periodNoRef,
      culturalAscription,
      culturalAscriptionNoRef,
      temporalityType,
      temporalityYearsBase,
      temporalityYearsSigma,
      temporalityCalibrated,
      temporalityCodeLab,
      temporalityCodeLabNoRef,
      temporalityMaterialDated,
      temporalityMaterialDatedNoRef,
      temporalityReferences,
      temporalityReferencesNoRef,
      absoluteChronology,
      observationsContext,
    } = values;

    const coordinateInfo = {
      1: {
        utmEast,
        utmNorth,
        datum,
        datumNoRef,
        altitude,
        altitudeNoRef,
      },
      2: {
        latitude,
        longitude,
      },
      3: null,
    };

    let requestBody = {
      siteName,
      siteCode,
      comune,
      locality,
      hasCoordinates,
      coordinateType,
      coordinateInfo: coordinateInfo[coordinateType],
      functionalities,
      otherFunctionality,
      localizationUnit,
      localizationUnitNoRef,
      localizationLayer,
      localizationLayerNoRef,
      localizationLevel,
      localizationLevelNoRef,
      localizationObservations,
      hasFuneralContext: hasFuneralContext === 'true' ? true : false,
      period,
      periodNoRef,
      culturalAscription,
      culturalAscriptionNoRef,
      absoluteChronology: absoluteChronology === 'true' ? true : false,
      observationsContext,
    };

    if (hasFuneralContext === 'true') {
      requestBody.isGuy = isGuy;
      requestBody.grave = grave;
      requestBody.graveNoRef = graveNoRef;
      requestBody.numberGuy = numberGuy;
      requestBody.numberGuyNoRef = numberGuyNoRef;
      requestBody.responsibleFuneralName = responsibleFuneralName;
      requestBody.obtainingDateYear =
        obtainingDateYear !== '' ? obtainingDateYear : null;
      requestBody.obtainingDateMonth =
        obtainingDateMonth !== '' ? obtainingDateMonth : null;
      requestBody.obtainingDateDay =
        obtainingDateDay !== '' ? obtainingDateDay : null;
      requestBody.obtainingDateNoRef = obtainingDateNoRef;
    }

    if (absoluteChronology === 'true') {
      requestBody.temporalityType = temporalityType;
      requestBody.temporalityYearsBase =
        temporalityYearsBase === '' ? null : temporalityYearsBase;
      requestBody.temporalityYearsSigma =
        temporalityYearsSigma === '' ? null : temporalityYearsSigma;
      requestBody.temporalityCalibrated = temporalityCalibrated;
      requestBody.temporalityCodeLab = temporalityCodeLab;
      requestBody.temporalityCodeLabNoRef = temporalityCodeLabNoRef;
      requestBody.temporalityMaterialDated = temporalityMaterialDated;
      requestBody.temporalityMaterialDatedNoRef = temporalityMaterialDatedNoRef;
      requestBody.temporalityReferences = temporalityReferences;
      requestBody.temporalityReferencesNoRef = temporalityReferencesNoRef;
    }

    return articleUpdateMutation(requestBody);
  };

  const articleUpdateCollection = (values) => {
    const {
      material,
      collection,
      otherCollection,
      otherCollectionDescription,
      recordCondition,
      materialDetails,
      otherMaterialDetails,
      typeManufacture,
      height,
      lenght,
      width,
      diameter,
      thickness,
    } = values;

    const dimensions = {
      height: height ? height : null,
      lenght: lenght ? lenght : null,
      width: width ? width : null,
      diameter: diameter ? diameter : null,
      thickness: thickness ? thickness : null,
    };

    return articleUpdateMutation({
      collection,
      otherCollection,
      otherCollectionDescription,
      recordCondition,
      materialDetails: material.length > 0 ? materialDetails : [],
      otherMaterialDetails,
      typeManufacture,
      dimensions,
    });
  };

  const articleUpdateMaterial = (values) => {
    const {
      material,
      recordCondition,
      materialDetails,
      otherMaterialDetails,
      typeManufacture,
      height,
      lenght,
      width,
      diameter,
      thickness,
    } = values;

    const dimensions = {
      height: height ? height : null,
      lenght: lenght ? lenght : null,
      width: width ? width : null,
      diameter: diameter ? diameter : null,
      thickness: thickness ? thickness : null,
    };

    return articleUpdateMutation({
      recordCondition,
      materialDetails: material.length > 0 ? materialDetails : [],
      otherMaterialDetails,
      typeManufacture,
      dimensions,
    });
  };

  const articleUpdateBioanthropology = (values) => {
    const {
      typeFinding,
      observationsFinding,
      individual,
      materialIndividual,
      otherMaterialIndividual,
      completeness,
      otherCompleteness,
      numberIndividual,
      numberIndividualNoApply,
      sexIndividual,
      ageIndividual,
      ageIndividualNoApply,
    } = values;

    return articleUpdateMutation({
      typeFinding,
      observationsFinding,
      individual,
      materialIndividual,
      otherMaterialIndividual,
      completeness,
      otherCompleteness,
      numberIndividual: numberIndividual ? numberIndividual : null,
      numberIndividualNoApply,
      sexIndividual,
      ageIndividual,
      ageIndividualNoApply,
    });
  };

  const articleUpdateObjectIdentification = (values) => {
    const {
      conservationState,
      completenessIdentification,
      sampling,
      samplingAssociateProject,
      samplingResponsibleInvestigator,
      samplingDate,
      hasPhotographyMaterial,
      hasPhotographyLabel,
      observationsIdentification,
    } = values;

    return articleUpdateMutation({
      conservationState,
      completenessIdentification,
      sampling,
      samplingAssociateProject,
      samplingResponsibleInvestigator,
      samplingDate: samplingDate
        ? format(parseISO(samplingDate), 'yyyy-MM-dd')
        : null,
      hasPhotographyMaterial,
      hasPhotographyLabel,
      observationsIdentification,
    });
  };

  return (
    <Form>
      <Switch location={location} key={location.pathname}>
        <>
          <CustomizedStepper stepNumber={stepNumber - 1} />
          {stepNumber === 1 && (
            <AdministrativeInformation
              values={values}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              errors={errors}
              touched={touched}
            />
          )}
          {stepNumber === 2 && (
            <OriginalContext
              values={values}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
            />
          )}
          {(stepNumber === 3 || stepNumber === 4) && (
            <MaterialIdentification
              values={values}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              stepNumber={stepNumber}
              uploadPdfBio={uploadPdfBio}
            />
          )}
          {stepNumber === 5 && (
            <ObjectIdentification
              values={values}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              uploadPdfSampling={uploadPdfSampling}
              uploadLabelPhoto={uploadLabelPhoto}
              uploadMaterialPhoto={uploadMaterialPhoto}
            />
          )}
          <BottomNav>
            <BottomNavWrapper>
              <Button
                variant="outlined"
                color="primary"
                onClick={(e) => {
                  history.push(
                    stepNumber === 1
                      ? articlesUrl
                      : `${mapStep[stepNumber - 1]}`
                  );
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                  setUserAttribute('direction', -1);
                }}
              >
                Volver
              </Button>
              <Box>
                <Button
                  disabled={isSubmitting || !isValid}
                  variant="contained"
                  color="third"
                  onClick={(e) => {
                    if (stepNumber === 1) {
                      if (!id) {
                        saveAdministrativeInformation();
                      } else {
                        updateAdministrativeInformation();
                      }
                    } else if (stepNumber === 2) {
                      updateOriginalContext();
                    } else if (stepNumber === 3) {
                      if (
                        values.collection === null &&
                        values.otherCollectionButton === 'false'
                      ) {
                        setMessageAlert({
                          message: 'Debes seleccionar una colección.',
                          type: 'info',
                        });
                        setOpenAlert(true);
                      } else {
                        updateCollection();
                      }
                    } else if (stepNumber === 4 && values.collection !== 3) {
                      updateMaterial();
                    } else if (stepNumber === 4 && values.collection === 3) {
                      updateBioanthropology();
                    } else if (stepNumber === 5) {
                      updateObjectIdentification();
                    }
                  }}
                  sx={{ marginRight: '8px' }}
                >
                  Guardar
                </Button>
                <Button
                  disabled={
                    !isValid || isSubmitting || (stepNumber === 1 && !id)
                  }
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    if (stepNumber === 1) {
                      if (!id) {
                        saveAdministrativeInformation(true);
                      } else {
                        updateAdministrativeInformation(true);
                      }
                    } else if (stepNumber === 2) {
                      updateOriginalContext(values);
                    } else if (stepNumber === 3) {
                      if (
                        values.collection === null &&
                        values.otherCollectionButton === 'false'
                      ) {
                        setMessageAlert({
                          message: 'Debes seleccionar una colección.',
                          type: 'info',
                        });
                        setOpenAlert(true);
                      } else {
                        updateCollection(true);
                      }
                    } else if (stepNumber === 4 && values.collection !== 3) {
                      updateMaterial(true);
                    } else if (stepNumber === 4 && values.collection === 3) {
                      updateBioanthropology(true);
                    } else if (stepNumber === 5) {
                      updateObjectIdentification(true);
                    }
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                  }}
                >
                  Guardar y {stepNumber === 5 ? 'finalizar' : 'continuar'}
                </Button>
              </Box>
            </BottomNavWrapper>
          </BottomNav>
        </>
      </Switch>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={onCloseAlert}
      >
        <Alert
          severity={messageAlert.type}
          sx={{ width: '100%' }}
          onClose={onCloseAlert}
        >
          {messageAlert.message}
        </Alert>
      </Snackbar>
    </Form>
  );
};

const ArticlesConfigurationWrapper = ({ articlesUrl }) => {
  const [isEditing, setIsEditing] = useState(false);
  const match = useRouteMatch();
  const location = useLocation();
  const {
    id,
    name,
    responsibleFirstname,
    responsibleLastname,
    responsibleNameNoRef,
    origin,
    originYear,
    originMonth,
    originDateNoRef,
    associationContext,
    inventaryNumber,
    inventaryBox,
    actualLocation,
    actualLocationItem,
    yearEntry,
    yearEntryNoRef,
    logDate,
    log,
    logDateEdit,
    logEdit,
    logUbications,

    siteName,
    siteCode,
    comune,
    locality,
    hasCoordinates,
    coordinateType,
    coordinateInfo,
    functionalities,
    otherFunctionality,
    localizationUnit,
    localizationUnitNoRef,
    localizationLayer,
    localizationLayerNoRef,
    localizationLevel,
    localizationLevelNoRef,
    localizationObservations,
    hasFuneralContext,
    isGuy,
    grave,
    graveNoRef,
    numberGuy,
    numberGuyNoRef,
    responsibleFuneralName,
    obtainingDateYear,
    obtainingDateNoRef,
    obtainingDateMonth,
    obtainingDateDay,
    period,
    periodNoRef,
    culturalAscription,
    culturalAscriptionNoRef,
    absoluteChronology,
    temporalityType,
    temporalityYearsBase,
    temporalityYearsSigma,
    temporalityCalibrated,
    temporalityCodeLab,
    temporalityCodeLabNoRef,
    temporalityMaterialDated,
    temporalityMaterialDatedNoRef,
    temporalityReferences,
    temporalityReferencesNoRef,
    observationsContext,

    collection,
    otherCollection,
    otherCollectionDescription,
    recordCondition,
    material,
    materialDetails,
    otherMaterialDetails,
    typeManufacture,
    dimensions,

    typeFinding,
    observationsFinding,
    individual,
    materialIndividual,
    otherMaterialIndividual,
    completeness,
    otherCompleteness,
    numberIndividual,
    numberIndividualNoApply,
    sexIndividual,
    ageIndividual,
    ageIndividualNoApply,

    conservationState,
    completenessIdentification,
    sampling,
    samplingAssociateProject,
    samplingResponsibleInvestigator,
    samplingDate,
    hasPhotographyMaterial,
    hasPhotographyLabel,
    observationsIdentification,
  } = useArticleDataContext();

  useEffect(() => {
    if (id) {
      setIsEditing(true);
    }
  }, [id]);

  const getWithOtherMaterials = () => {
    const temp = material;
    if (!material.find((m) => m === 16)) {
      temp.push(16);
    }
    return material;
  };

  return (
    <Route path={`${match.url}/:step`}>
      {({ match, history }) => {
        if (!match) {
          return <Redirect to={`crear/informacion`} />;
        }

        const stepName = {
          informacion: 1,
          contexto: 2,
          coleccion: 3,
          identificacion: 4,
          objeto: 5,
        };

        const mapStep = {
          1: 'informacion',
          2: 'contexto',
          3: 'coleccion',
          4: 'identificacion',
          5: 'objeto',
        };

        const stepNumber = stepName[match?.params.step];

        const setKey = (pathname) => {
          let key = '';
          return key;
        };

        return (
          <>
            <h2 style={{ marginBottom: '1rem' }}>
              {isEditing
                ? `Editando Ficha - ${inventaryNumber}`
                : 'Nueva Ficha'}
            </h2>
            <Formik
              initialValues={{
                name,
                responsibleFirstname,
                responsibleLastname,
                responsibleNameNoRef,
                origin,
                originYear,
                originMonth,
                originDateNoRef,
                associationContext: associationContext ? 'true' : 'false',
                inventaryNumber,
                inventaryBox,
                actualLocation,
                actualLocationItem,
                yearEntry,
                yearEntryNoRef,
                logDate,
                log,
                logDateEdit,
                logEdit,
                logUbications,

                siteName,
                siteCode,
                comune,
                locality,
                hasCoordinates: hasCoordinates ? 'true' : 'false',
                coordinateType,
                utmEast: coordinateInfo?.utmEast,
                utmNorth: coordinateInfo?.utmNorth,
                datum: coordinateInfo?.datum,
                datumNoRef: coordinateInfo?.datumNoRef,
                altitude: coordinateInfo?.altitude,
                altitudeNoRef: coordinateInfo?.altitudeNoRef,
                latitude: coordinateInfo?.latitude,
                longitude: coordinateInfo?.longitude,
                functionalities,
                otherFunctionality,
                localizationUnit,
                localizationUnitNoRef,
                localizationLayer,
                localizationLayerNoRef,
                localizationLevel,
                localizationLevelNoRef,
                localizationObservations,
                hasFuneralContext: hasFuneralContext ? 'true' : 'false',
                isGuy: isGuy ? 'true' : 'false',
                grave,
                graveNoRef,
                numberGuy,
                numberGuyNoRef,
                responsibleFuneralName,
                obtainingDateYear,
                obtainingDateNoRef,
                obtainingDateMonth,
                obtainingDateDay,
                period,
                periodNoRef,
                culturalAscription,
                culturalAscriptionNoRef,
                absoluteChronology: absoluteChronology ? 'true' : 'false',
                temporalityType,
                temporalityYearsBase,
                temporalityYearsSigma,
                temporalityCalibrated,
                temporalityCodeLab,
                temporalityCodeLabNoRef,
                temporalityMaterialDated,
                temporalityMaterialDatedNoRef,
                temporalityReferences,
                temporalityReferencesNoRef,
                observationsContext,

                collection,
                otherCollection,
                otherCollectionButton: otherCollection ? 'true' : 'false',
                otherCollectionDescription,
                recordCondition,
                material: otherMaterialDetails
                  ? getWithOtherMaterials()
                  : material,
                materialDetails,
                otherMaterialDetails,
                typeManufacture,
                height: dimensions?.height,
                lenght: dimensions?.lenght,
                width: dimensions?.width,
                diameter: dimensions?.diameter,
                thickness: dimensions?.thickness,

                typeFinding,
                observationsFinding,
                individual,
                materialIndividual,
                otherMaterialIndividual,
                completeness,
                otherCompleteness,
                numberIndividual,
                numberIndividualNoApply,
                sexIndividual,
                ageIndividual,
                ageIndividualNoApply,
                pdfNameBio: '',

                conservationState,
                completenessIdentification,
                sampling: sampling ? 'true' : 'false',
                samplingAssociateProject,
                samplingResponsibleInvestigator,
                samplingDate,
                pdfNameSampling: '',
                observationsIdentification,
                hasPhotographyMaterial: hasPhotographyMaterial
                  ? 'true'
                  : 'false',
                hasPhotographyLabel: hasPhotographyLabel ? 'true' : 'false',
                uploadPhotoLabel: '',
              }}
              initialErrors={{
                name: !name,
                responsibleFirstname: !responsibleFirstname,
                responsibleLastname: !responsibleLastname,
                responsibleNameNoRef: !responsibleNameNoRef,
                origin: !origin,
                originYear: !originYear,
                originMonth: !originMonth,
                originDateNoRef: !originDateNoRef,
                associationContext: !associationContext,
                inventaryNumber: !inventaryNumber,
                inventaryBox: !inventaryBox,
                actualLocation: !actualLocation,
                actualLocationItem: !actualLocationItem,
                yearEntry: !yearEntry,
                yearEntryNoRef: !yearEntryNoRef,
                logDate: !logDate,
                log: !log,
                logDateEdit: !logDateEdit,
                logEdit: !logEdit,
                logUbications: !logUbications,
              }}
              key={setKey(location.pathname)}
              validate={(values) => {
                const errors = {};

                if (stepNumber === 1) {
                  const todayYear = format(new Date(), 'yyyy', {
                    locale: es,
                  });

                  if (!values.name) {
                    errors.name =
                      'Sin el nombre del proyecto no podemos guardar la colección.';
                  }

                  if (
                    !values.responsibleNameNoRef &&
                    !values.responsibleFirstname
                  ) {
                    errors.responsibleFirstname =
                      'Sin el nombre del responsable no podemos guardar la colección.';
                  }

                  if (
                    !values.responsibleNameNoRef &&
                    !values.responsibleLastname
                  ) {
                    errors.responsibleLastname =
                      'Sin el apellido del responsable no podemos guardar la colección.';
                  }

                  if (!values.origin) {
                    errors.origin =
                      'Sin la procedencia no podemos guardar la colección.';
                  }

                  if (!values.originDateNoRef && !values.originYear) {
                    errors.originYear =
                      'Sin el año de procedencia no podemos guardar la colección.';
                  }

                  if (values.originYear && values.originYear < 1950) {
                    errors.originYear =
                      'El año de procedencia no puede ser más antiguo a 1950.';
                  }

                  if (values.originYear && values.originYear > todayYear) {
                    errors.originYear =
                      'El año de procedencia no puede ser más nuevo que el actual.';
                  }

                  if (!values.originDateNoRef && !values.originMonth) {
                    errors.originMonth =
                      'Sin el mes de procedencia no podemos guardar la colección.';
                  }

                  if (!values.inventaryNumber) {
                    errors.inventaryNumber =
                      'Sin el número de inventario no podemos guardar la colección.';
                  }

                  if (!values.inventaryBox) {
                    errors.inventaryBox =
                      'Sin el número de caja no podemos guardar la colección.';
                  }

                  if (!values.yearEntryNoRef && !values.yearEntry) {
                    errors.yearEntry =
                      'Sin el año de ingreso no podemos guardar la colección.';
                  }

                  if (values.yearEntry && values.yearEntry > todayYear) {
                    errors.yearEntry =
                      'El año de ingreso no puede ser mayor al actual.';
                  }

                  if (
                    values.logUbications &&
                    values.logUbications.length === 0
                  ) {
                    errors.logUbications =
                      'Sin un historial de ubicaciones no podemos guardar la colección.';
                  }
                }

                if (stepNumber === 2) {
                  const todayYear = format(new Date(), 'yyyy', {
                    locale: es,
                  });
                  const todayMonth = format(new Date(), 'M', {
                    locale: es,
                  });
                  const todayDay = format(new Date(), 'd', {
                    locale: es,
                  });

                  if (values.obtainingDateYear > todayYear) {
                    errors.obtainingDateYear =
                      'El año de obtención no puede ser mayor al actual.';
                  }

                  if (
                    values.obtainingDateYear >= todayYear &&
                    values.obtainingDateMonth > todayMonth
                  ) {
                    errors.obtainingDateMonth =
                      'El mes de obtención no puede ser mayor al actual.';
                  }

                  if (
                    values.obtainingDateYear >= todayYear &&
                    values.obtainingDateMonth >= todayMonth &&
                    values.obtainingDateDay > todayDay
                  ) {
                    errors.obtainingDateDay =
                      'El día de obtención no puede ser mayor al actual.';
                  }

                  if (values.obtainingDateDay > 31) {
                    errors.obtainingDateDay = 'El día debe ser un día válido.';
                  }
                }

                return errors;
              }}
              onSubmit={async (values) => {
                if (stepNumber < 5) {
                  history.push(`${mapStep[stepNumber + 1]}`);
                } else {
                  history.push('/museo/articulos');
                }
              }}
            >
              {({ setFieldValue, validateForm, ...formikProps }) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEffect(() => {
                  validateForm();
                  // eslint-disable-next-line react-hooks/exhaustive-deps
                }, [
                  formikProps.values.logUbications,
                  formikProps.values.collection,
                ]);

                return (
                  <ArticlesConfiguration
                    {...formikProps}
                    articlesUrl={articlesUrl}
                    stepNumber={stepNumber}
                    mapStep={mapStep}
                    handleSubmit={async (event) => {
                      if (event) event.preventDefault();
                      formikProps.handleSubmit(event);
                    }}
                    handleChange={(event) => {
                      formikProps.setFieldTouched(event.target.name, false);
                      formikProps.handleChange(event);
                    }}
                    handleBlur={async (event) => {
                      formikProps.handleBlur(event);
                      const trimmedValue = event.target.value.trim();
                      setFieldValue(event.target.name, trimmedValue);
                    }}
                    setFieldValue={(fieldName, value) => {
                      setFieldValue(fieldName, value);
                    }}
                  />
                );
              }}
            </Formik>
          </>
        );
      }}
    </Route>
  );
};

export default ArticlesConfigurationWrapper;
