import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Title from './Title';
import {
  completenessIdentification,
  conservations,
} from 'views/Catalog/ArticlePrivateCatalog';
import Subtitle from './Subtitle';
import { format, parseISO } from 'date-fns';

const styles = StyleSheet.create({
  container: {
    paddingTop: '10px',
  },
  leftColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  title: {
    fontSize: 11,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  description: {
    fontSize: 11,
    color: '#65748B',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '4px',
    flex: '1',
  },
  imagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  image: {
    borderRadius: '8px',
    marginRight: '8px',
    marginBottom: '8px',
    width: 250,
  },
});

const IdentificationObject = ({ article, imagesLabel }) => {
  return (
    <>
      <View style={styles.container}>
        <Title article={article}>Identificación del objeto</Title>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Estado de conservación - </Text>
            <Text style={styles.description}>
              {article.conservationState
                ? conservations[article.conservationState]
                : 'Sin datos'}
            </Text>
          </View>
          {article.collection && article.collection !== 3 && (
            <>
              <View style={styles.row}>
                <Text style={styles.title}>Completitud - </Text>
                <Text style={styles.description}>
                  {article.completenessIdentification !== '4'
                    ? completenessIdentification[
                        article.completenessIdentification
                      ]
                    : article.completenessIdentification === '4'
                    ? article.otherCompleteness
                    : 'Sin datos'}
                  {article.completenessIdentification === null && 'Sin datos'}
                </Text>
              </View>
            </>
          )}
        </View>
        {article.sampling && (
          <>
            <Subtitle>Toma de muestras:</Subtitle>
            <View style={styles.table}>
              <View style={styles.row}>
                <Text style={styles.title}>Proyecto asociado - </Text>
                <Text style={styles.description}>
                  {article.samplingAssociateProject &&
                    article.samplingAssociateProject}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.title}>Investigador responsable - </Text>
                <Text style={styles.description}>
                  {article.samplingResponsibleInvestigator &&
                    article.samplingResponsibleInvestigator}
                </Text>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.row}>
                <Text style={styles.title}>Fecha - </Text>
                <Text style={styles.description}>
                  {article.samplingDate &&
                    format(parseISO(article.samplingDate), 'dd/MM/yyyy')}
                </Text>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.row}>
                <Text style={styles.title}>Observaciones generales - </Text>
                <Text style={styles.description}>
                  {article.observationsIdentification
                    ? article.observationsIdentification
                    : 'Sin datos'}
                </Text>
              </View>
            </View>
          </>
        )}
        {imagesLabel && imagesLabel.length > 0 && (
          <View
            style={{
              paddingTop: '8px',
            }}
            break
          >
            <Title article={article}>Fotografías de etiqueta</Title>
            <View style={styles.imagesContainer}>
              {imagesLabel.map((image) => (
                <Image src={image.original} style={styles.image} />
              ))}
            </View>
          </View>
        )}
      </View>
    </>
  );
};

export default IdentificationObject;
