import React from 'react';
import {
  Page,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import Header from './Header';
import emptyState from 'assets/images/empty_state.png';
import Information from './Information';
import OriginalContext from './OriginalContext';
import IdentificationObject from './IdentificationObject';
import Identification from './Identification';

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  container: {
    flexDirection: 'row',
  },
  leftColumn: {
    width: 250,
    paddingTop: 30,
    paddingRight: 15,
  },
  image: {
    borderRadius: '8px',
  },
});

Font.register({
  family: 'Open Sans',
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: 'Lato',
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: 'Lato Italic',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
  family: 'Lato Bold',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

export const collectionStyle = {
  1: {
    name: 'Arqueológica',
    color: '#FEB460',
  },
  2: {
    name: 'Histórica',
    color: '#bc0b0b',
  },
  3: {
    name: 'Bioantropológica',
    color: '#A9C3B6',
  },
  4: {
    name: 'Etnográfica',
    color: '#8FB8CA',
  },
  5: {
    name: 'Otra colección',
    color: '#6D8B74',
  },
  6: {
    name: 'Sin colección',
    color: '#CCCCCC',
  },
};

const Index = ({ article, imagesLabel }) => {
  return (
    <Document>
      <Page style={styles.body}>
        {article && Object.keys(article).length > 0 && (
          <>
            <Header article={article} />
            <View style={styles.container}>
              <View style={styles.leftColumn}>
                <Image
                  src={
                    article && article.photography
                      ? `${process.env.REACT_APP_URL_STORAGE}${article.photography}`
                      : emptyState
                  }
                  style={styles.image}
                />
              </View>
              <Information article={article} />
            </View>
            <OriginalContext article={article} />
            <Identification article={article} />
            <IdentificationObject article={article} imagesLabel={imagesLabel} />
          </>
        )}
      </Page>
    </Document>
  );
};

export default Index;
