import { FormControlLabel, Radio } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

const RadioButton = ({ label, onChange, checked, ...props }) => {
  const [field] = useField(props);
  return (
    <FormControlLabel
      {...field}
      checked={checked}
      control={<Radio />}
      label={label}
      onChange={onChange}
    ></FormControlLabel>
  );
};

export default RadioButton;
