import { Button } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import WebAssetOffIcon from '@mui/icons-material/WebAssetOff';

const CardContainer = styled('div')({
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
  borderRadius: '8px',
  padding: '2rem',
  maxWidth: '640px',
  backgroundColor: '#fcf6ec',
  color: '#4c5b73',
});

const Container = styled('div')({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
  padding: '2rem',
  height: '100%',
  paddingTop: '50px',
});

const Title = styled('div')({
  fontSize: '50px',
});

const Subtitle = styled('div')({
  fontSize: '20px',
  textAlign: 'center',
  marginBottom: '10px',
});

const Parragraph = styled('div')({
  fontSize: '16px',
  textAlign: 'center',
  marginBottom: '20px',
});

const NotFound = () => {
  const history = useHistory();
  return (
    <Container>
      <CardContainer>
        <WebAssetOffIcon sx={{ fontSize: '4rem' }} />
        <Title>Oops!</Title>
        <Subtitle>La página que estabas buscando no existe.</Subtitle>
        <Parragraph>
          No te asustes, solo clickea en el botón grande para volver al inicio.
        </Parragraph>
        <Button
          variant="contained"
          color="primary"
          sx={{ height: '2.5rem', width: '12rem' }}
          onClick={() => history.push('/museo/catalogo')}
        >
          INICIO
        </Button>
      </CardContainer>
    </Container>
  );
};

export default NotFound;
