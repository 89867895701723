import React, { useCallback, useContext, useState } from 'react';

export const UserDataContext = React.createContext({
  authToken: '',
  username: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  birthDate: '',
  email: '',
  birthDateObject: null,
  rut: '',
  cellphone: '',
  address1: '',
  address2: '',
  region: '',
  commune: '',
  direction: '',
  profile: '',
  isStaff: false,
  setUserAttribute: (attribute, value) => {},
  setUserData: () => {},
  clear: () => {},
  logout: () => {},
});

export const UserDataContextConsumer = UserDataContext.Consumer;

const initialData = {
  authToken: '',
  username: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  birthDate: '',
  email: '',
  rut: '',
  cellphone: '',
  address1: '',
  address2: '',
  region: '',
  commune: '',
  direction: '',
  profile: '',
  isStaff: false,
};

const savedUserData =
  JSON.parse(localStorage.getItem('userData')) || initialData;

const saveUserData = (value) => {
  localStorage.setItem('userData', JSON.stringify(value));
};

const authToken = localStorage.getItem('token');

export const useUserDataContext = () => useContext(UserDataContext);

export const UserDataContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(savedUserData);
  const [token, setToken] = useState(authToken);
  const [loginDone, setLoginDone] = useState(!!authToken);
  const setUserAttribute = useCallback(
    (attribute, value) => {
      setUserData((prevUserData) => {
        const newUserData = {
          ...prevUserData,
          [attribute]: value,
        };
        saveUserData(newUserData);

        return newUserData;
      });
    },
    [setUserData]
  );

  const isLoggedIn = !!token;

  const contextValues = {
    ...userData,
    isLoggedIn: isLoggedIn && loginDone,
  };

  return (
    <UserDataContext.Provider
      value={{
        ...contextValues,
        loginDone,
        authToken: token,
        setLogin: (token) => {
          localStorage.setItem('token', token);
          setToken(token);
        },
        endLogin: () => {
          setLoginDone(true);
        },
        clear: () => {
          setUserData(initialData);
        },
        logout: () => {
          localStorage.clear();
          sessionStorage.clear();
          setUserData(initialData);
          setToken('');
          setLoginDone(false);
        },
        setUserData,
        saveUserData,
        setUserAttribute,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
