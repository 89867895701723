import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import isValidEmail from '../../utils/isValidEmail';
import { Form, Formik } from 'formik';
import InputText from '../../components/Input/InputText';
import { Section, SubSection } from 'components/StylesForm/StylesForm';
import {
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  TextField,
} from '@mui/material';
import circleIcon from 'assets/images/circle_icon.png';
import logo from 'assets/images/logo.png';
import RadioButton from 'components/RadioButton/RadioButton';
import SelectText from 'components/Select/Select';
import { Hidden } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { getData } from 'api/queries';
import { postData } from '../../api/queries';
import { format, differenceInYears, startOfToday } from 'date-fns';
import { parseDateString } from 'utils/date';
import { useUserDataContext } from 'contexts/UserDataContext';
import { es } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  title: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 20,
    paddingLeft: '16px',
  },
  formTitle: {
    paddingRight: '16px',
    textAlign: 'right',
  },
  mobileFormTitle: {
    fontWeight: 500,
    paddingRight: '16px',
    textAlign: 'left',
  },
  textCenter: {
    textAlign: 'center',
  },
  gridLayout: {
    marginBottom: '32px',
  },
  gridEditLog: {
    marginTop: '2rem',
    marginLeft: '4rem',
    marginBottom: '2rem',
  },
  checkboxLayout: {
    display: 'flex',
  },
  divider: {
    backgroundColor: '#EB8242',
  },
  subSectionTitle: {
    fontSize: 18,
    color: 'white',
  },
  subFormTitle: {
    paddingRight: '16px',
  },
  subTitle: {
    fontWeight: '500',
    fontSize: 18,
  },
  child: {
    display: 'flex',
    opacity: 0,
  },
}));

export const educationalsLevels = [
  {
    value: 1,
    label: 'Preescolar',
  },
  {
    value: 2,
    label: 'Educación Básica',
  },
  {
    value: 3,
    label: 'Educación Media',
  },
  {
    value: 4,
    label: 'Educación Superior',
  },
];

const Register = () => {
  const classes = useStyles();
  const history = useHistory();
  const [regions, setRegions] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { setUserAttribute } = useUserDataContext();
  const [emailError, setEmailError] = useState(false);

  const todayYear = format(new Date(), 'yyyy', {
    locale: es,
  });

  const getRegions = async () => {
    await getData('/regions').then((response) => {
      let array = [];
      response.forEach((region) => {
        array.push({
          value: region.id,
          label: region.name,
        });
      });
      setRegions(array);
    });
  };

  useEffect(() => {
    getRegions();
  }, []);

  return (
    <>
      <Hidden smDown={true}>
        <Container
          style={{
            background: '#FCF6EC',
            paddingBottom: '1.5rem',
            maxWidth: 'initial',
          }}
        >
          <Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '1rem',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={circleIcon}
                  alt="icono de museo"
                  style={{ width: '4rem', marginRight: '10px' }}
                />
                <Typography
                  style={{
                    color: '#999388',
                    fontSize: '24px',
                    fontWeight: 500,
                  }}
                >
                  Sistema de registro de colecciones
                </Typography>
              </div>
              <img src={logo} alt="logo de museo" style={{ width: '10rem' }} />
            </Box>
            <Box sx={{ padding: '0rem 2rem 3rem' }}>
              <Section
                title="Registro"
                style={{ marginTop: '1rem', marginBottom: 0 }}
              >
                <Formik
                  initialValues={{
                    firstName: '',
                    lastName: '',
                    birthdate: format(
                      new Date(todayYear - 18, 3, 5),
                      'MM/dd/yyyy'
                    ),
                    gender: '',
                    nationalityChilean: '',
                    belongsNativePeople: '',
                    nativePeople: '',
                    region: '',
                    commune: '',
                    email: '',
                    password: '',
                    passwordConfirmation: '',
                    userType: '1',
                    educationalLevel: null,
                    educationalInstitution: '',
                  }}
                  validate={(values) => {
                    const errors = {};
                    const ck_password = /^[a-zA-Z\u00C0-\u017F\s]+$/;

                    if (!values.firstName) {
                      errors.firstName =
                        'Sin tu nombre no podemos registrarte.';
                    } else if (!ck_password.test(values.firstName)) {
                      errors.firstName =
                        'El nombre solo puede contener caracteres alfabéticos.';
                    }

                    if (!values.lastName) {
                      errors.lastName =
                        'Sin tu apellido no podemos registrarte.';
                    } else if (!ck_password.test(values.lastName)) {
                      errors.lastName =
                        'El apellido solo puede contener caracteres alfabéticos.';
                    }

                    if (!values.birthdate) {
                      errors.birthdate =
                        'Sin tu fecha de nacimiento no podemos registrarte.';
                    }

                    if (
                      differenceInYears(
                        startOfToday(),
                        parseDateString(values.birthdate)
                      ) < 5
                    ) {
                      errors.birthdate =
                        'Debes ser mayor a 5 años para registrarte.';
                    }

                    if (!values.gender) {
                      errors.gender = 'Sin tu género no podemos registrarte.';
                    }

                    if (!values.nationalityChilean) {
                      errors.nationalityChilean =
                        'Sin tu nacionalidad no podemos registrarte.';
                    }

                    if (!values.belongsNativePeople && !values.nativePeople) {
                      errors.nativePeople =
                        'Sin tu respuesta no podemos registrarte.';
                    }

                    if (!values.region) {
                      errors.region = 'Sin tu región no podemos registrarte.';
                    }

                    if (!values.commune) {
                      errors.commune = 'Sin tu comuna no podemos registrarte.';
                    }

                    if (values.userType === '1' && !values.educationalLevel) {
                      errors.educationalLevel =
                        'Debes seleccionar un nivel educacional.';
                    }

                    if (
                      values.userType === '1' &&
                      !values.educationalInstitution
                    ) {
                      errors.educationalInstitution =
                        'Sin tu institución no podemos registrarte.';
                    }

                    if (!values.email) {
                      errors.email = 'Sin tu correo no podemos registrarte.';
                    } else if (!isValidEmail(values.email)) {
                      errors.email = 'No es un correo válido.';
                    }

                    if (!values.password) {
                      errors.password =
                        'Sin tu contraseña no podemos registrarte.';
                    } else if (values.password.length < 8) {
                      errors.password =
                        'La contraseña debe tener al menos 8 caracteres.';
                    }

                    if (!values.passwordConfirmation) {
                      errors.passwordConfirmation =
                        'Debes repetir tu contraseña.';
                    } else if (values.passwordConfirmation.length < 8) {
                      errors.passwordConfirmation =
                        'La contraseña debe tener al menos 8 caracteres.';
                    } else if (
                      values.password !== values.passwordConfirmation
                    ) {
                      errors.passwordConfirmation =
                        'La contraseña no es igual a la ingresada anteriormente.';
                    }

                    return errors;
                  }}
                  onSubmit={async ({
                    email,
                    password,
                    passwordConfirmation,
                    firstName,
                    lastName,
                    birthdate,
                    gender,
                    nationalityChilean,
                    belongsNativePeople,
                    nativePeople,
                    region,
                    commune,
                    userType,
                    educationalLevel,
                    educationalInstitution,
                  }) => {
                    setEmailError(false);
                    const data = {
                      email,
                      username: email,
                      password,
                      passwordConfirmation,
                      firstName,
                      lastName,
                      birthdate: format(
                        parseDateString(birthdate),
                        'yyyy-MM-dd'
                      ),
                      gender: Number(gender),
                      nationalityChilean:
                        nationalityChilean === '1' ? true : false,
                      belongsNativePeople:
                        belongsNativePeople === 'true' ? true : false,
                      nativePeople:
                        belongsNativePeople === 'true' ? nativePeople : null,
                      region,
                      commune,
                      userType: Number(userType),
                      educationalLevel:
                        userType === '1' ? educationalLevel : null,
                      educationalInstitution:
                        educationalInstitution &&
                        educationalInstitution.length > 0
                          ? educationalInstitution
                          : null,
                    };

                    try {
                      setOpenBackdrop(true);
                      postData('/users/signup/', data).then((data) => {
                        if (data.statusCode === 400) {
                          if (
                            data.email?.includes('Este campo debe ser único.')
                          ) {
                            setEmailError(true);
                          }
                          setOpenBackdrop(false);
                          return false;
                        }
                        setUserAttribute('email', data.email);
                        history.push('/activar-cuenta');
                        setOpenBackdrop(false);
                      });
                    } catch (e) {}
                  }}
                >
                  {({
                    values,
                    isSubmitting,
                    setFieldValue,
                    errors,
                    touched,
                    isValid,
                    ...formikProps
                  }) => {
                    const handleChange = (event) => {
                      formikProps.setFieldTouched(event.target.name, false);
                      formikProps.handleChange(event);
                      setFieldValue(event.target.name, event.target.value);

                      if (event.target.name === 'region') {
                        getCommune(event.target.value);
                      }
                    };

                    const getCommune = async (regionId) => {
                      await getData(`/communes?region=${regionId}`).then(
                        (response) => {
                          let array = [];
                          response.forEach((commune) => {
                            array.push({
                              value: commune.id,
                              label: commune.name,
                            });
                          });
                          setCommunes(array);
                        }
                      );
                    };

                    return (
                      <Form className={classes.form} noValidate>
                        <SubSection
                          title="Datos personales"
                          style={{ padding: 0 }}
                        >
                          <Grid
                            container
                            alignItems="center"
                            className={classes.gridLayout}
                          >
                            <Grid item md={2}>
                              <Typography className={classes.formTitle}>
                                Nombres:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={9}>
                              <InputText
                                variant="outlined"
                                required
                                fullWidth
                                name="firstName"
                                autoComplete="firstName"
                                value={values.firstName}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            className={classes.gridLayout}
                          >
                            <Grid item md={2}>
                              <Typography className={classes.formTitle}>
                                Apellidos:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={9}>
                              <InputText
                                variant="outlined"
                                required
                                fullWidth
                                name="lastName"
                                autoComplete="lastName"
                                value={values.lastName}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            className={classes.gridLayout}
                          >
                            <Grid item md={2}>
                              <Typography className={classes.formTitle}>
                                Fecha de nacimiento:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={9}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={esLocale}
                              >
                                <DatePicker
                                  inputFormat="dd/MM/yyyy"
                                  placeholder="dd/MM/aaaa"
                                  name="birthdate"
                                  value={values.birthdate}
                                  onChange={(newValue) => {
                                    formikProps.setFieldTouched(
                                      'birthdate',
                                      false
                                    );
                                    setFieldValue('birthdate', newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      helperText={errors.birthdate}
                                      error={!!errors.birthdate}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            className={classes.gridLayout}
                          >
                            <Grid item md={2}>
                              <Typography className={classes.formTitle}>
                                Género:
                              </Typography>
                            </Grid>
                            <Grid md={10} container alignItems="center">
                              <Grid item md="auto">
                                <RadioButton
                                  checked={values.gender === '1'}
                                  name="gender"
                                  type="radio"
                                  value="1"
                                  label="Femenino"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                />
                              </Grid>
                              <Grid item md="auto">
                                <RadioButton
                                  checked={values.gender === '2'}
                                  name="gender"
                                  type="radio"
                                  value="2"
                                  label="Masculino"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                />
                              </Grid>
                              <Grid item md={6}>
                                <RadioButton
                                  checked={values.gender === '3'}
                                  name="gender"
                                  type="radio"
                                  value="3"
                                  label="Otro"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                />
                              </Grid>
                              <Grid item md={12}>
                                <ErrorMessage name="gender" />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            className={classes.gridLayout}
                          >
                            <Grid item md={2}>
                              <Typography className={classes.formTitle}>
                                Nacionalidad:
                              </Typography>
                            </Grid>
                            <Grid md={10} container alignItems="center">
                              <Grid item md="auto">
                                <RadioButton
                                  checked={values.nationalityChilean === '1'}
                                  name="nationalityChilean"
                                  type="radio"
                                  value="1"
                                  label="Chilena"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                />
                              </Grid>
                              <Grid item md="auto">
                                <RadioButton
                                  checked={values.nationalityChilean === '2'}
                                  name="nationalityChilean"
                                  type="radio"
                                  value="2"
                                  label="Otro"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                />
                              </Grid>
                              <Grid item md={12}>
                                <ErrorMessage name="nationalityChilean" />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            className={classes.gridLayout}
                          >
                            <Grid item md={2}>
                              <Typography className={classes.formTitle}>
                                Pertenece a un pueblo originario:
                              </Typography>
                            </Grid>
                            <Grid md={10} container alignItems="center">
                              <Grid item md={1}>
                                <RadioButton
                                  checked={
                                    values.belongsNativePeople === 'true'
                                  }
                                  name="belongsNativePeople"
                                  type="radio"
                                  value="true"
                                  label="Si"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                />
                              </Grid>
                              <Grid item md={1}>
                                <RadioButton
                                  checked={
                                    values.belongsNativePeople === 'false'
                                  }
                                  name="belongsNativePeople"
                                  type="radio"
                                  value="false"
                                  label="No"
                                  onChange={(e) => {
                                    setFieldValue('nativePeople', '');
                                    handleChange(e);
                                  }}
                                />
                              </Grid>
                              <Grid item md={12}>
                                <ErrorMessage name="nativePeople" />
                              </Grid>
                            </Grid>
                          </Grid>
                          {values.belongsNativePeople === 'true' && (
                            <Grid
                              container
                              alignItems="center"
                              className={classes.gridLayout}
                            >
                              <Grid item md={2}>
                                <Typography className={classes.formTitle}>
                                  Indique cual:
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4}>
                                <InputText
                                  variant="outlined"
                                  required
                                  fullWidth
                                  name="nativePeople"
                                  autoComplete="nativePeople"
                                  value={values.nativePeople}
                                />
                              </Grid>
                            </Grid>
                          )}
                          <Grid
                            container
                            alignItems="center"
                            className={classes.gridLayout}
                          >
                            <Grid item md={2}>
                              <Typography className={classes.formTitle}>
                                Región:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <SelectText
                                  required
                                  fullWidth
                                  name="region"
                                  list={regions}
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                />
                                <ErrorMessage name="region" />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            className={classes.gridLayout}
                          >
                            <Grid item md={2}>
                              <Typography className={classes.formTitle}>
                                Comuna:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <SelectText
                                  disabled={values.region.length === 0}
                                  required
                                  fullWidth
                                  name="commune"
                                  list={communes}
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                />
                                <ErrorMessage name="commune" />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            className={classes.gridLayout}
                          >
                            <Grid item md={2}>
                              <Typography className={classes.formTitle}>
                                Correo electrónico:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={9}>
                              <InputText
                                variant="outlined"
                                required
                                fullWidth
                                name="email"
                                autoComplete="email"
                                value={values.email}
                              />
                            </Grid>
                            {emailError && (
                              <>
                                <Grid item md={2}></Grid>
                                <Grid item md={9} sx={{ marginTop: '8px' }}>
                                  <div style={{ color: '#D14343' }}>
                                    El correo electrónico ingresado ya está en
                                    uso.
                                  </div>
                                </Grid>
                              </>
                            )}
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            className={classes.gridLayout}
                          >
                            <Grid item md={2}>
                              <Typography className={classes.formTitle}>
                                Contraseña:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <InputText
                                variant="outlined"
                                placeholder="Contraseña"
                                name="password"
                                value={values.password}
                                type="password"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                                inputProps={{
                                  minLength: 6,
                                  maxLength: 20,
                                }}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            className={classes.gridLayout}
                          >
                            <Grid item md={2}>
                              <Typography className={classes.formTitle}>
                                Repetir Contraseña:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <InputText
                                placeholder="Repite tu contraseña"
                                name="passwordConfirmation"
                                value={values.passwordConfirmation}
                                type="password"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  minLength: 6,
                                  maxLength: 20,
                                }}
                                required
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            className={classes.gridLayout}
                          >
                            <Grid item md={2}>
                              <Typography className={classes.formTitle}>
                                Actualmente eres:
                              </Typography>
                            </Grid>
                            <Grid item md="auto">
                              <RadioButton
                                checked={values.userType === '1'}
                                name="userType"
                                type="radio"
                                value="1"
                                label="Estudiante"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </Grid>
                            <Grid item md="auto">
                              <RadioButton
                                checked={values.userType === '2'}
                                name="userType"
                                type="radio"
                                value="2"
                                label="Investigador"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </Grid>
                            <Grid item md="auto">
                              <RadioButton
                                checked={values.userType === '3'}
                                name="userType"
                                type="radio"
                                value="3"
                                label="Persona natural"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </SubSection>
                        {values.userType === '1' && (
                          <SubSection title="Información académica">
                            <Grid
                              container
                              alignItems="center"
                              className={classes.gridLayout}
                            >
                              <Grid item md={2}>
                                <Typography className={classes.formTitle}>
                                  Estudiante:
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FormControl
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <SelectText
                                    required
                                    fullWidth
                                    name="educationalLevel"
                                    list={educationalsLevels}
                                    onChange={(event) => {
                                      handleChange(event);
                                    }}
                                  />
                                  <ErrorMessage name="educationalLevel" />
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              alignItems="center"
                              className={classes.gridLayout}
                            >
                              <Grid item md={2}>
                                <Typography className={classes.formTitle}>
                                  Institución:
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={9}>
                                <InputText
                                  variant="outlined"
                                  required
                                  fullWidth
                                  name="educationalInstitution"
                                  value={values.educationalInstitution}
                                />
                              </Grid>
                            </Grid>
                          </SubSection>
                        )}
                        {values.userType === '2' && (
                          <SubSection title="Información académica">
                            <Grid
                              container
                              alignItems="center"
                              className={classes.gridLayout}
                            >
                              <Grid item md={2}>
                                <Typography className={classes.formTitle}>
                                  Institución:
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={9}>
                                <InputText
                                  variant="outlined"
                                  required
                                  fullWidth
                                  name="educationalInstitution"
                                  value={values.educationalInstitution}
                                />
                              </Grid>
                            </Grid>
                          </SubSection>
                        )}
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ width: '20rem', marginBottom: '1rem' }}>
                            <Button
                              disabled={!isValid || isSubmitting}
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="primary"
                              className={classes.submit}
                            >
                              Registrarme
                            </Button>
                          </div>
                          <div>
                            <Link
                              href=""
                              onClick={() => history.push('/ingresar')}
                              variant="body2"
                            >
                              Ya tienes una cuenta? Inicia sesión
                            </Link>
                          </div>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </Section>
            </Box>
          </Box>
        </Container>
      </Hidden>

      <Hidden smUp={true}>
        <Container
          style={{
            background: '#FFFFFF',
          }}
        >
          <Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '1rem',
                marginBottom: '1rem',
                marginTop: '1rem',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={circleIcon}
                  alt="icono de museo"
                  style={{ width: '3rem', marginRight: '2rem' }}
                />
                <Typography
                  style={{
                    color: '#999388',
                    fontSize: '18px',
                    fontWeight: 500,
                  }}
                >
                  Sistema de registro de colecciones
                </Typography>
              </div>
            </Box>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                birthdate: format(new Date(todayYear - 18, 3, 5), 'MM/dd/yyyy'),
                gender: '',
                nationalityChilean: '',
                belongsNativePeople: false,
                nativePeople: '',
                region: '',
                commune: '',
                email: '',
                password: '',
                passwordConfirmation: '',
                userType: '1',
                educationalLevel: '',
                educationalInstitution: '',
              }}
              validate={(values) => {
                const errors = {};
                const ck_password = /^[a-zA-Z\u00C0-\u017F\s]+$/;

                if (!values.firstName) {
                  errors.firstName = 'Sin tu nombre no podemos registrarte.';
                } else if (!ck_password.test(values.firstName)) {
                  errors.firstName =
                    'El nombre solo puede contener caracteres alfabéticos.';
                }

                if (!values.lastName) {
                  errors.lastName = 'Sin tu apellido no podemos registrarte.';
                } else if (!ck_password.test(values.lastName)) {
                  errors.lastName =
                    'El apellido solo puede contener caracteres alfabéticos.';
                }

                if (!values.birthdate) {
                  errors.birthdate =
                    'Sin tu fecha de nacimiento no podemos registrarte.';
                }

                if (
                  differenceInYears(
                    startOfToday(),
                    parseDateString(values.birthdate)
                  ) < 5
                ) {
                  errors.birthdate =
                    'Debes ser mayor a 5 años para registrarte.';
                }

                if (!values.gender) {
                  errors.gender = 'Sin tu género no podemos registrarte.';
                }

                if (!values.nationalityChilean) {
                  errors.nationalityChilean =
                    'Sin tu nacionalidad no podemos registrarte.';
                }

                if (!values.belongsNativePeople && !values.nativePeople) {
                  errors.nativePeople =
                    'Sin tu respuesta no podemos registrarte.';
                }

                if (!values.region) {
                  errors.region = 'Sin tu región no podemos registrarte.';
                }

                if (!values.commune) {
                  errors.commune = 'Sin tu comuna no podemos registrarte.';
                }

                if (values.userType === '1' && !values.educationalLevel) {
                  errors.educationalLevel =
                    'Debes seleccionar un nivel educacional.';
                }

                if (values.userType === '1' && !values.educationalInstitution) {
                  errors.educationalInstitution =
                    'Sin tu institución no podemos registrarte.';
                }

                if (!values.email) {
                  errors.email = 'Sin tu correo no podemos registrarte.';
                } else if (!isValidEmail(values.email)) {
                  errors.email = 'No es un correo válido.';
                }

                if (!values.password) {
                  errors.password = 'Sin tu contraseña no podemos registrarte.';
                } else if (values.password.length < 8) {
                  errors.password =
                    'La contraseña debe tener al menos 8 caracteres.';
                }

                if (!values.passwordConfirmation) {
                  errors.passwordConfirmation = 'Debes repetir tu contraseña.';
                } else if (values.passwordConfirmation.length < 8) {
                  errors.passwordConfirmation =
                    'La contraseña debe tener al menos 8 caracteres.';
                } else if (values.password !== values.passwordConfirmation) {
                  errors.passwordConfirmation =
                    'La contraseña no es igual a la ingresada anteriormente.';
                }

                return errors;
              }}
              onSubmit={async ({
                email,
                password,
                passwordConfirmation,
                firstName,
                lastName,
                birthdate,
                gender,
                nationalityChilean,
                belongsNativePeople,
                nativePeople,
                region,
                commune,
                userType,
                educationalLevel,
                educationalInstitution,
              }) => {
                setEmailError(false);
                const data = {
                  email,
                  username: email,
                  password,
                  passwordConfirmation,
                  firstName,
                  lastName,
                  birthdate: format(parseDateString(birthdate), 'yyyy-MM-dd'),
                  gender: Number(gender),
                  nationalityChilean: nationalityChilean === '1' ? true : false,
                  belongsNativePeople:
                    belongsNativePeople === 'true' ? true : false,
                  nativePeople:
                    belongsNativePeople === 'true' ? nativePeople : null,
                  region,
                  commune,
                  userType: Number(userType),
                  educationalLevel: userType === '1' ? educationalLevel : null,
                  educationalInstitution:
                    educationalInstitution && educationalInstitution.length > 0
                      ? educationalInstitution
                      : null,
                };

                try {
                  setOpenBackdrop(true);
                  postData('/users/signup/', data).then((data) => {
                    if (data.statusCode === 400) {
                      if (data.email?.includes('Este campo debe ser único.')) {
                        setEmailError(true);
                      }
                      setOpenBackdrop(false);
                      return false;
                    }
                    setUserAttribute('email', data.email);
                    history.push('/activar-cuenta');
                    setOpenBackdrop(false);
                  });
                } catch (e) {}
              }}
            >
              {({
                values,
                isSubmitting,
                setFieldValue,
                errors,
                ...formikProps
              }) => {
                const handleChange = (event) => {
                  formikProps.setFieldTouched(event.target.name, false);
                  formikProps.handleChange(event);
                  setFieldValue(event.target.name, event.target.value);

                  if (event.target.name === 'region') {
                    getCommune(event.target.value);
                  }
                };

                const getCommune = async (regionId) => {
                  await getData(`/communes?region=${regionId}`).then(
                    (response) => {
                      let array = [];
                      response.forEach((commune) => {
                        array.push({
                          value: commune.id,
                          label: commune.name,
                        });
                      });
                      setCommunes(array);
                    }
                  );
                };

                return (
                  <Form className={classes.form} noValidate>
                    <SubSection
                      title="Datos personales"
                      style={{ padding: 0 }}
                      boxStyle={{ paddingBottom: 0 }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        className={classes.gridLayout}
                      >
                        <Grid item xs={12} sm={6} md={9}>
                          <InputText
                            label="Nombres"
                            variant="outlined"
                            required
                            fullWidth
                            name="firstName"
                            autoComplete="firstName"
                            value={values.firstName}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.gridLayout}
                      >
                        <Grid item xs={12} sm={6} md={9}>
                          <InputText
                            label="Apellidos"
                            variant="outlined"
                            required
                            fullWidth
                            name="lastName"
                            autoComplete="lastName"
                            value={values.lastName}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.gridLayout}
                      >
                        <Grid item xs={12} sm={6} md={9}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={esLocale}
                          >
                            <DatePicker
                              label="Fecha de nacimiento"
                              inputFormat="dd/MM/yyyy"
                              placeholder="dd/MM/aaaa"
                              name="birthdate"
                              value={values.birthdate}
                              onChange={(newValue) => {
                                formikProps.setFieldTouched('birthdate', false);
                                setFieldValue('birthdate', newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={errors.birthdate}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.gridLayout}
                      >
                        <Grid item xs={12}>
                          <Typography className={classes.mobileFormTitle}>
                            Género:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <RadioButton
                            checked={values.gender === '1'}
                            name="gender"
                            type="radio"
                            value="1"
                            label="Femenino"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <RadioButton
                            checked={values.gender === '2'}
                            name="gender"
                            type="radio"
                            value="2"
                            label="Masculino"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <RadioButton
                            checked={values.gender === '3'}
                            name="gender"
                            type="radio"
                            value="3"
                            label="Otro"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ErrorMessage name="gender" />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.gridLayout}
                      >
                        <Grid item xs={12}>
                          <Typography className={classes.mobileFormTitle}>
                            Nacionalidad:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <RadioButton
                            checked={values.nationalityChilean === '1'}
                            name="nationalityChilean"
                            type="radio"
                            value="1"
                            label="Chilena"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <RadioButton
                            checked={values.nationalityChilean === '2'}
                            name="nationalityChilean"
                            type="radio"
                            value="2"
                            label="Otro"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ErrorMessage name="nationalityChilean" />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.gridLayout}
                      >
                        <Grid item xs={12}>
                          <Typography className={classes.mobileFormTitle}>
                            Pertenece a un pueblo originario:
                          </Typography>
                        </Grid>
                        <Grid item md={1}>
                          <RadioButton
                            checked={values.belongsNativePeople === 'true'}
                            name="belongsNativePeople"
                            type="radio"
                            value="true"
                            label="Si"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </Grid>
                        <Grid item md={1}>
                          <RadioButton
                            checked={values.belongsNativePeople === 'false'}
                            name="belongsNativePeople"
                            type="radio"
                            value="false"
                            label="No"
                            onChange={(e) => {
                              setFieldValue('nativePeople', '');
                              handleChange(e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ErrorMessage name="belongsNativePeople" />
                        </Grid>
                        {values.belongsNativePeople === 'true' && (
                          <>
                            <Grid item md={2} style={{ marginBottom: 0 }}>
                              <Typography className={classes.formTitle}>
                                Indique cual:
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <InputText
                                variant="outlined"
                                required
                                fullWidth
                                name="nativePeople"
                                autoComplete="nativePeople"
                                value={values.nativePeople}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>

                      <Grid
                        container
                        alignItems="center"
                        className={classes.gridLayout}
                      >
                        <Grid item md={2}>
                          <Typography className={classes.formTitle}>
                            Región:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <SelectText
                              required
                              fullWidth
                              name="region"
                              list={regions}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                            />
                            <ErrorMessage name="region" />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.gridLayout}
                      >
                        <Grid item md={2}>
                          <Typography className={classes.formTitle}>
                            Comuna:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <SelectText
                              required
                              fullWidth
                              name="commune"
                              list={communes}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                            />
                            <ErrorMessage name="commune" />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.gridLayout}
                      >
                        <Grid item xs={12} sm={6} md={9}>
                          <InputText
                            variant="outlined"
                            required
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Correo electrónico"
                            name="email"
                            autoComplete="email"
                            value={values.email}
                          />
                        </Grid>
                        {emailError && (
                          <Grid item xs={12} sx={{ marginTop: '8px' }}>
                            <div style={{ color: '#D14343' }}>
                              El correo electrónico ingresado ya está en uso.
                            </div>
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.gridLayout}
                      >
                        <Grid item xs={12} sm={6} md={9}>
                          <InputText
                            variant="outlined"
                            placeholder="Contraseña"
                            name="password"
                            label="Contraseña"
                            value={values.password}
                            type="password"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            required
                            inputProps={{
                              minLength: 6,
                              maxLength: 20,
                            }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.gridLayout}
                      >
                        <Grid item xs={12} sm={6} md={9}>
                          <InputText
                            placeholder="Repite tu contraseña"
                            label="Repite tu contraseña"
                            name="passwordConfirmation"
                            value={values.passwordConfirmation}
                            type="password"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              minLength: 6,
                              maxLength: 20,
                            }}
                            required
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.gridLayout}
                      >
                        <Grid item xs={12}>
                          <Typography className={classes.mobileFormTitle}>
                            Actualmente estudias:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <RadioButton
                            checked={values.userType === '1'}
                            name="userType"
                            type="radio"
                            value="1"
                            label="Si"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <RadioButton
                            checked={values.userType === '2'}
                            name="userType"
                            type="radio"
                            value="2"
                            label="No"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </SubSection>
                    {values.userType === '1' && (
                      <SubSection
                        title="Información académica"
                        style={{ padding: 0 }}
                        boxStyle={{ paddingBottom: 0 }}
                      >
                        <Grid
                          container
                          alignItems="center"
                          className={classes.gridLayout}
                        >
                          <Grid item md={2}>
                            <Typography className={classes.formTitle}>
                              Estudiante:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <SelectText
                                required
                                fullWidth
                                name="educationalLevel"
                                list={educationalsLevels}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                              <ErrorMessage name="educationalLevel" />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          className={classes.gridLayout}
                        >
                          <Grid item md={2}>
                            <Typography className={classes.formTitle}>
                              Institución:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={9}>
                            <InputText
                              variant="outlined"
                              required
                              fullWidth
                              name="educationalInstitution"
                              value={values.educationalInstitution}
                            />
                          </Grid>
                        </Grid>
                      </SubSection>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingBottom: '2rem',
                      }}
                    >
                      <div style={{ width: '20rem', marginBottom: '1rem' }}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          Registrarme
                        </Button>
                      </div>
                      <div>
                        <Link
                          href=""
                          onClick={() => history.push('/ingresar')}
                          variant="body2"
                        >
                          Ya tienes una cuenta? Inicia sesión
                        </Link>
                      </div>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Container>
      </Hidden>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Register;
