const baseUrl = `${process.env.REACT_APP_GATEWAY_URL}`;

export function postData(path = '', data = {}) {
  const token = localStorage.getItem('token');
  let headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(`${baseUrl}${path}`, {
    method: 'post',
    headers,
    body: JSON.stringify(data),
  }).then(async (response) => {
    const data = await response.json();

    if (data.statusCode === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.assign('/');
    }

    return data;
  });
}

export async function deleteData(path = '', data = {}) {
  const token = localStorage.getItem('token');
  let headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(`${baseUrl}${path}`, {
    method: 'delete',
    headers,
    body: JSON.stringify(data),
  }).then(async (response) => {
    const data = await response.json();

    if (data.statusCode === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.assign('/');
    }

    return data;
  });
}

export async function getData(path = '', data = {}) {
  const token = localStorage.getItem('token');
  let headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(`${baseUrl}${path}`, {
    method: 'GET',
    headers,
  }).then(async (response) => {
    const data = await response.json();

    if (data.statusCode === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.assign('/');
    }

    return data;
  });
}

export async function patchData(path = '', data = {}) {
  const token = localStorage.getItem('token');
  let headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(`${baseUrl}${path}`, {
    method: 'patch',
    headers,
    body: JSON.stringify(data),
  }).then(async (response) => {
    const data = await response.json();

    if (data.statusCode === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.assign('/');
    }

    return data;
  });
}

export async function postPdf(path = '', data = {}) {
  const token = localStorage.getItem('token');
  let headers = {};
  let files = [];

  if (data.file.length > 1) {
    files = data.file[0];
  } else {
    files = data.file[0];
  }

  let formData = new FormData();
  formData.append('files', files);

  if (data.name) {
    formData.append('name', data.name);
  }

  formData.append('article', data.articleId);
  formData.append('type', data.type);

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(`${baseUrl}${path}`, {
    method: 'post',
    headers,
    body: formData,
  }).then(async (response) => {
    const data = await response.json();

    if (data.statusCode === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.assign('/');
    }

    return data;
  });
}

export async function setPdfBucket(path = '', data = {}) {
  let headers = {
    'Content-Type': 'application/octet-stream',
  };

  let formData = new FormData();
  formData.append('files', data.file[0]);

  return fetch(`${path}`, {
    method: 'PUT',
    headers,
    body: formData,
  }).then(async (response) => {
    const data = await response;

    if (data.statusCode === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.assign('/');
    }

    return data;
  });
}

export async function postAssignPdf(path = '', data = {}) {
  const token = localStorage.getItem('token');
  let headers = {};

  let formData = new FormData();
  formData.append('url', data.url);

  if (data.name) {
    formData.append('name', data.name);
  }

  formData.append('article', data.articleId);
  formData.append('type', data.type);

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(`${baseUrl}${path}`, {
    method: 'post',
    headers,
    body: formData,
  }).then(async (response) => {
    const data = await response.json();

    if (data.statusCode === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.assign('/');
    }

    return data;
  });
}
