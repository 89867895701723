import { parseISO, parse, isValid } from 'date-fns';

export const todayDate = new Date().toISOString().slice(0, 10);

export const parseDateString = (dateInput) => {
  const isoDate = parseISO(dateInput);
  const date = parse(dateInput, 'dd/MM/yyyy', new Date());
  const dateHyphen = parse(dateInput, 'dd-MM-yyyy', new Date());
  const isIsoDateValid = isValid(isoDate);
  const isDateValid = isValid(date);
  const isDateHyphenValid = isValid(dateHyphen);

  let finalDate = dateInput;
  if (isIsoDateValid) finalDate = isoDate;
  if (isDateValid) finalDate = date;
  if (isDateHyphenValid) finalDate = dateHyphen;

  return finalDate;
};
