import React from 'react';
import { Text } from '@react-pdf/renderer';
import { collectionStyle } from './Header';

const Title = ({ article, children }) => (
  <Text
    style={{
      fontFamily: 'Lato Bold',
      fontSize: 14,
      marginBottom: 10,
      textTransform: 'uppercase',
      borderBottomWidth: 2,
      borderBottomColor:
        article && article.collection
          ? collectionStyle[article.collection]
          : '#112131',
      borderBottomStyle: 'solid',
      paddingBottom: '4px',
    }}
  >
    {children}
  </Text>
);

export default Title;
