import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useUserDataContext } from './../contexts/UserDataContext';
import Articles from './../views/Articles/Articles';
import CatalogList from './Catalog/CatalogList';
import { variant } from 'styled-system';
import ArticleCatalog from './Catalog/ArticleCatalog';
import CatalogListMobile from './Catalog/CatalogListMobile';
import CatalogFiltersMobile from './Catalog/CatalogFiltersMobile';
import { getData } from 'api/queries';
import ArticleMobileCatalog from './Catalog/ArticleMobileCatalog';
import ArticlePrivateCatalog from './Catalog/ArticlePrivateCatalog';
import ArticlePrivateMobileCatalog from './Catalog/ArticlePrivateMobileCatalog';
import NotFound from './NotFound/NotFound';

const Container = styled.div(
  css({
    minHeight: 'calc(100% - 75px)',
  }),
  variant({
    prop: 'isArticles',
    variants: {
      true: {
        backgroundColor: '#fcf6ec',
      },
    },
  }),
  variant({
    prop: 'isCatalog',
    variants: {
      true: {},
    },
  }),
  variant({
    prop: 'isNotFound',
    variants: {
      true: {
        height: 'calc(100% - 75px)',
      },
    },
  })
);

const Museum = ({ isMobile }) => {
  const match = useRouteMatch();
  const location = useLocation();
  const { isLoggedIn, isStaff } = useUserDataContext();
  const [isFiltered, setIsFiltered] = useState(true);
  const [isLoadingActive, setIsLoadingActive] = useState(false);
  const history = useHistory();
  let currentUrlParams = new URLSearchParams(window.location.search);

  const [pagination, setPagination] = useState({
    data: '',
    perPage: 12,
    currentPage: 0,
  });

  const [filters, setFilters] = useState({
    query: currentUrlParams.get('query') ? currentUrlParams.get('query') : '',
    functionality: currentUrlParams.get('functionality')
      ? currentUrlParams.get('functionality')
      : '',
    material: currentUrlParams.get('material')
      ? currentUrlParams.get('material')
      : '',
    collection: currentUrlParams.get('collection')
      ? currentUrlParams.get('collection')
      : '',
  });

  const getArticlesActive = async (selectedPage) => {
    if (isLoadingActive) {
      return false;
    }

    let isBackButton = false;

    if (history.location?.state?.fromButtonBack) {
      isBackButton = true;
    }

    let filtersQuery = '';

    if (currentUrlParams.get('query')) {
      filtersQuery = `&query=${currentUrlParams.get('query')}`;
      setFilters((prevState) => ({
        ...prevState,
        query: currentUrlParams.get('query'),
      }));
    }

    if (filters.query.length > 0 && !isBackButton) {
      currentUrlParams.set('query', filters.query);
      filtersQuery = `&query=${filters.query}`;
    }

    if (currentUrlParams.get('functionality')) {
      filtersQuery = `${filtersQuery}&functionality=${currentUrlParams.get(
        'functionality'
      )}`;
      setFilters((prevState) => ({
        ...prevState,
        functionality: currentUrlParams.get('functionality'),
      }));
    }

    if (filters.functionality.length !== 0 && !isBackButton) {
      currentUrlParams.set('functionality', filters.functionality);
      filtersQuery = `${filtersQuery}&functionality=${filters.functionality}`;
    }

    if (currentUrlParams.get('material')) {
      filtersQuery = `${filtersQuery}&material=${currentUrlParams.get(
        'material'
      )}`;
      setFilters((prevState) => ({
        ...prevState,
        material: currentUrlParams.get('material'),
      }));
    }

    if (filters.material.length !== 0 && !isBackButton) {
      currentUrlParams.set('material', filters.material);
      filtersQuery = `${filtersQuery}&material=${filters.material}`;
    }

    if (currentUrlParams.get('collection')) {
      filtersQuery = `${filtersQuery}&collection=${currentUrlParams.get(
        'collection'
      )}`;
      setFilters((prevState) => ({
        ...prevState,
        collection: currentUrlParams.get('collection'),
      }));
    }

    if (filters.collection.length !== 0 && !isBackButton) {
      currentUrlParams.set('collection', filters.collection);
      filtersQuery = `${filtersQuery}&collection=${filters.collection}`;
    }

    currentUrlParams.set('page', selectedPage);
    history.push(window.location.pathname + '?' + currentUrlParams.toString());

    setIsLoadingActive(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    await getData(
      `/articles?is_active=1&page=${selectedPage}&view=catalog${filtersQuery}`
    ).then((data) => {
      setPagination({
        ...pagination,
        currentPage: selectedPage - 1,
        forcePage: selectedPage - 1,
        pageCount: Math.ceil(data.total / pagination.perPage),
        data: data.results,
      });
      setIsLoadingActive(false);
    });
  };

  useEffect(() => {
    if (location.pathname === '/museo/catalogo') {
      if (currentUrlParams.get('page')) {
        getArticlesActive(currentUrlParams.get('page'));
      } else {
        getArticlesActive(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Container
      isArticles={location.pathname.includes('/museo/articulos')}
      isCatalog={location.pathname.includes('/museo/catalogo')}
    >
      <Switch>
        {!isLoggedIn && <Redirect to="/" />}

        <Route path={`${match.url}/articulos`}>
          <Articles />
        </Route>

        <Route exact path={`${match.url}/catalogo`}>
          {!isMobile ? (
            <CatalogList
              pagination={pagination}
              setPagination={setPagination}
              isLoadingActive={isLoadingActive}
              setIsLoadingActive={setIsLoadingActive}
              isFiltered={isFiltered}
              setIsFiltered={setIsFiltered}
              filters={filters}
              setFilters={setFilters}
            />
          ) : (
            <CatalogListMobile
              pagination={pagination}
              setPagination={setPagination}
              getArticlesActive={getArticlesActive}
              isLoadingActive={isLoadingActive}
              isFiltered={isFiltered}
              filters={filters}
            />
          )}
        </Route>

        {isMobile && (
          <Route exact path={`${match.url}/catalogo/filtros`}>
            <CatalogFiltersMobile
              filters={filters}
              setFilters={setFilters}
              setIsFiltered={setIsFiltered}
              pagination={pagination}
              setPagination={setPagination}
              getArticlesActive={getArticlesActive}
            />
          </Route>
        )}

        <Route path={`${match.url}/catalogo/articulo/:articleId`}>
          {!isMobile && !isStaff && <ArticleCatalog />}
          {isMobile && !isStaff && <ArticleMobileCatalog />}
          {!isMobile && isStaff && <ArticlePrivateCatalog />}
          {isMobile && isStaff && <ArticlePrivateMobileCatalog />}
        </Route>

        <Route path="*" component={NotFound} />
      </Switch>
    </Container>
  );
};

export default Museum;
