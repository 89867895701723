import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Title from './Title';
import {
  ageList,
  completeness,
  genderList,
  materialsIndividual,
} from 'views/Catalog/ArticleCatalog';
import { findings, records } from 'views/Catalog/ArticlePrivateCatalog';
import Subtitle from './Subtitle';

const styles = StyleSheet.create({
  container: {
    paddingTop: '32px',
  },
  leftColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  title: {
    fontSize: 11,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  description: {
    fontSize: 11,
    color: '#65748B',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '4px',
    flex: '1',
  },
});

const Identification = ({ article }) => (
  <>
    {article.collection && article.collection !== 3 && (
      <View style={styles.container}>
        <Title article={article}>Identificación del material</Title>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Condición de registro - </Text>
            <Text style={styles.description}>
              {article.recordCondition
                ? records[article.recordCondition]
                : 'Sin datos'}
            </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Material - </Text>
            <Text style={styles.description}>
              <>
                {article.collection &&
                  article.collection !== 3 &&
                  article.material.map((m, index) => {
                    const rowLen = article.material.length;
                    return (
                      <>{rowLen === index + 1 ? `${m.name}` : `${m.name}, `}</>
                    );
                  })}
                {article.collection &&
                  article.collection !== 3 &&
                  article.material.length === 0 &&
                  'Material no registrado'}
                {!article.collection && 'Sin colección'}
              </>
            </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Tipo de manufactura - </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.description}>{article.typeManufacture}</Text>
          </View>
        </View>
        <Subtitle>Dimensiones:</Subtitle>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Alto - </Text>
            <Text style={styles.description}>
              {article.dimensions.height
                ? article.dimensions.height
                : 'Sin datos'}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>Ancho - </Text>
            <Text style={styles.description}>
              {article.dimensions.width
                ? article.dimensions.width
                : 'Sin datos'}
            </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Largo - </Text>
            <Text style={styles.description}>
              {article.dimensions.lenght
                ? article.dimensions.lenght
                : 'Sin datos'}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>Ancho - </Text>
            <Text style={styles.description}>
              {article.dimensions.thickness
                ? article.dimensions.thickness
                : 'Sin datos'}
            </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Diámetro - </Text>
            <Text style={styles.description}>
              {article.dimensions.diameter
                ? article.dimensions.diameter
                : 'Sin datos'}
            </Text>
          </View>
        </View>
      </View>
    )}
    {article.collection && article.collection === 3 && (
      <View style={styles.container}>
        <Title article={article}>Identificación</Title>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Contexto de hallazgo - </Text>
            <Text style={styles.description}>
              {article.typeFinding
                ? findings[article.typeFinding]
                : 'Sin datos'}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>Observaciones - </Text>
            <Text style={styles.description}>
              {article.observationsFinding
                ? article.observationsFinding
                : 'Sin datos'}
            </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Individuo - </Text>
            <Text style={styles.description}>
              {article.individual ? article.individual : 'Sin datos'}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>Material - </Text>
            <Text style={styles.description}>
              <>
                {article.materialIndividual !== '3'
                  ? materialsIndividual[article.materialIndividual]
                  : article.materialIndividual === '3'
                  ? article.otherMaterialIndividual
                  : 'Sin datos'}
                {article.materialIndividual === null && 'Sin datos'}
              </>
            </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Completitud - </Text>
            <Text style={styles.description}>
              {article.completeness
                ? completeness[article.completeness]
                : 'Sin datos'}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>Número de individuos - </Text>
            <Text style={styles.description}>
              {article.numberIndividual
                ? article.numberIndividual
                : 'Sin datos'}
            </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Sexo - </Text>
            <Text style={styles.description}>
              {article.sexIndividual
                ? genderList[article.sexIndividual]
                : 'Sin datos'}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>Edad - </Text>
            <Text style={styles.description}>
              {article.ageIndividual
                ? ageList[article.ageIndividual]
                : 'Sin datos'}
            </Text>
          </View>
        </View>
      </View>
    )}
  </>
);

export default Identification;
