import React, { useEffect, useState } from 'react';
import { Box, Button, Fab, FormControl, InputLabel } from '@mui/material';
import {
  ContainerBox,
  Section,
  SubSection,
} from 'components/StylesForm/StylesForm';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import InputText from 'components/Input/InputText';
import RadioButton from 'components/RadioButton/RadioButton';
import styled from 'styled-components';
import css from '@styled-system/css';
import { variant } from 'styled-system';
import SelectMultiple from 'components/SelectMultiple/SelectMultiple';
import {
  ageList,
  genderList,
  materialItems,
  materialNames,
  materials,
} from './articlesArray';
import { useArticleDataContext } from 'contexts/ArticleDataContext';
import Divider from '@mui/material/Divider';
import SelectText from 'components/Select/Select';
import { FieldArray } from 'formik';
import CheckboxCustom from 'components/Checkbox/Checkbox';
import TableComponent from 'components/Table/Table';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import { getData } from 'api/queries';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  title: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 20,
    paddingLeft: '16px',
  },
  formTitle: {
    paddingRight: '16px',
    textAlign: 'right',
  },
  gridLayout: {
    marginBottom: '32px',
  },
  checkboxLayout: {
    display: 'flex',
  },
  divider: {
    backgroundColor: '#EB8242',
  },
  subSectionTitle: {
    fontSize: 18,
    color: 'white',
  },
  subFormTitle: {
    paddingRight: '16px',
  },
  subTitle: {
    fontWeight: '500',
    fontSize: 18,
  },
}));

const ColectionButton = styled('div')(
  css({
    backgroundColor: '#ddd4c5',
    color: '#fff',
    padding: '10px 16px',
    fontSize: '18px',
    minWidth: '200px',
    cursor: 'pointer',
    borderRadius: '10px',
    textAlign: 'center',
    textTransform: 'uppercase',

    '&:hover:not(.active)': {
      backgroundColor: '#ebe3d5',
    },

    '&:focus': {
      backgroundColor: '#EF4B4B',
    },

    '&:active': {
      backgroundColor: '#EF4B4B',
    },
  }),
  variant({
    prop: 'active',
    variants: {
      true: {
        backgroundColor: '#EF4B4B',
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
      },
    },
  })
);

export const RadioPercentageContainer = styled('div')(
  css({
    display: 'flex',
    flexDirection: 'column',
  })
);

export const RadioLabel = styled('div')(
  css({
    backgroundColor: '#999388',
    color: 'white',
    padding: '2px 4px',
    borderRadius: '4px',
    textAlign: 'center',
    width: '50%',
  }),
  variant({
    prop: 'active',
    variants: {
      true: {
        backgroundColor: '#EF4B4B',
      },
    },
  })
);

export const PdfContainer = styled('div')(
  css({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: '#EB8242',
    padding: '16px',
    marginTop: '1.5rem',
    width: '40%',
  })
);

const MaterialIdentification = ({
  values,
  setFieldValue,
  handleChange,
  stepNumber,
  uploadPdfBio,
}) => {
  const [selectedFile, setSelectedFiles] = useState();
  const [files, setFiles] = useState([]);
  const classes = useStyles();
  const { id, collection, otherCollection } = useArticleDataContext();
  const collections = {
    1: 'ARQUEOLÓGICA',
    2: 'HISTÓRICA',
    3: 'BIOANTROPOLÓGICA',
    4: 'ETNOGRÁFICA',
  };

  const getFilesByArticle = async () => {
    await getData(`/uploads?article=${id}&type=bioantropologic`).then(
      (response) => {
        setFiles(response);
      }
    );
  };

  useEffect(() => {
    getFilesByArticle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openSelect, setOpenSelect] = React.useState(false);

  const handleCloseSelect = () => {
    setOpenSelect(false);
  };

  const handleOpenSelect = () => {
    setOpenSelect(true);
  };

  const checkMaterialDetails = (material, materialDetails) => {
    materialDetails.forEach((detail) => {
      let isSet = false;
      material.forEach((m) => {
        materialItems[m].forEach((item) => {
          if (item.value === detail) {
            isSet = true;
          }
        });
      });
      if (!isSet) {
        setFieldValue(
          'materialDetails',
          materialDetails.filter((m) => m !== detail)
        );
      }
    });
  };

  return (
    <Box style={{ marginBottom: '1rem' }}>
      <Section title="Identificación del material">
        {stepNumber === 3 && (
          <SubSection title="Colección">
            <Grid
              container
              alignItems="center"
              className={classes.gridLayout}
              spacing={3}
            >
              <Grid item md="auto">
                <ColectionButton
                  className={values.collection === 1 ? 'active' : ''}
                  onClick={() => {
                    setFieldValue('collection', 1);
                    setFieldValue('otherCollection', '');
                    setFieldValue('otherCollectionDescription', '');
                    setFieldValue('otherCollectionButton', 'false');
                  }}
                  active={values.collection === 1}
                >
                  ARQUEOLÓGICA
                </ColectionButton>
              </Grid>
              <Grid item md="auto">
                <ColectionButton
                  className={values.collection === 2 ? 'active' : ''}
                  onClick={() => {
                    setFieldValue('collection', 2);
                    setFieldValue('otherCollection', '');
                    setFieldValue('otherCollectionDescription', '');
                    setFieldValue('otherCollectionButton', 'false');
                  }}
                  active={values.collection === 2}
                >
                  HISTÓRICA
                </ColectionButton>
              </Grid>
              <Grid item md="auto">
                <ColectionButton
                  className={values.collection === 3 ? 'active' : ''}
                  onClick={() => {
                    setFieldValue('collection', 3);
                    setFieldValue('otherCollection', '');
                    setFieldValue('otherCollectionDescription', '');
                    setFieldValue('otherCollectionButton', 'false');
                    setFieldValue('material', []);
                    setFieldValue('materialDetails', []);
                    setFieldValue('recordCondition', null);
                    setFieldValue('typeManufacture', null);
                    setFieldValue('dimensions', []);
                    setFieldValue('diameter', null);
                    setFieldValue('height', null);
                    setFieldValue('lenght', null);
                    setFieldValue('thickness', null);
                    setFieldValue('width', null);
                  }}
                  active={values.collection === 3}
                >
                  BIOANTROPOLÓGICA
                </ColectionButton>
              </Grid>
              <Grid item md="auto">
                <ColectionButton
                  className={values.collection === 4 ? 'active' : ''}
                  onClick={() => {
                    setFieldValue('collection', 4);
                    setFieldValue('otherCollection', '');
                    setFieldValue('otherCollectionDescription', '');
                    setFieldValue('otherCollectionButton', 'false');
                  }}
                  active={values.collection === 4}
                >
                  ETNOGRÁFICA
                </ColectionButton>
              </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.gridLayout}>
              <Grid
                container
                alignItems="center"
                className={classes.gridLayout}
                item
                md={6}
              >
                <Grid
                  className={classes.checkboxLayout}
                  item
                  alignItems="center"
                  md={12}
                >
                  <RadioButton
                    checked={
                      values.otherCollectionButton === 'true' ||
                      values.collection === 5
                    }
                    name="otherCollectionButton"
                    type="radio"
                    value="true"
                    label="Otra:"
                    onChange={(e) => {
                      setFieldValue('collection', 5);
                      handleChange(e);
                    }}
                  />
                </Grid>
                <Grid item md={11}>
                  <InputText
                    variant="outlined"
                    placeholder="Paleontológico"
                    required
                    fullWidth
                    name="otherCollection"
                    autoComplete="otherCollection"
                    value={values.otherCollection}
                    disabled={
                      values.otherCollectionButton === 'true' ||
                      values.collection === 5
                        ? false
                        : true
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={classes.gridLayout}
                item
                md={6}
              >
                <Grid item md={12}>
                  <Typography className={classes.subTitle}>
                    Descripción
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <InputText
                    variant="outlined"
                    multiline
                    rows={4}
                    required
                    fullWidth
                    name="otherCollectionDescription"
                    value={values.otherCollectionDescription}
                    disabled={
                      values.otherCollectionButton === 'true' ? false : true
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </SubSection>
        )}
        {stepNumber === 4 && (
          <>
            {collection !== 3 ? (
              <>
                <SubSection title="Colección">
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                    spacing={3}
                  >
                    <Grid item md="auto">
                      <ColectionButton active={true}>
                        {collection !== 5
                          ? collections[collection]
                          : otherCollection}
                      </ColectionButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                    style={{ marginTop: '2rem' }}
                  >
                    <Grid item md={2}>
                      <Typography className={classes.subFormTitle}>
                        Condición del registro:
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <RadioButton
                        checked={Number(values.recordCondition) === 1}
                        name="recordCondition"
                        type="radio"
                        value="1"
                        label="Artefacto"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <RadioButton
                        checked={Number(values.recordCondition) === 2}
                        name="recordCondition"
                        type="radio"
                        value="2"
                        label="Conjunto"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <RadioButton
                        checked={Number(values.recordCondition) === 3}
                        name="recordCondition"
                        type="radio"
                        value="3"
                        label="Bolsa"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                  >
                    <Grid item md={3}>
                      <Typography className={classes.subFormTitle}>
                        Materiales
                      </Typography>
                    </Grid>
                    <Grid item md={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <SelectMultiple
                          open={openSelect}
                          onClose={handleCloseSelect}
                          onOpen={handleOpenSelect}
                          multiple
                          required
                          fullWidth
                          label="Material"
                          name="material"
                          autoComplete="material"
                          value={values.material}
                          list={materials}
                          onChange={(event) => {
                            if (!event.target.value.includes(16)) {
                              setFieldValue('otherMaterialDetails', '');
                            }
                            checkMaterialDetails(
                              event.target.value,
                              values.materialDetails
                            );
                            handleChange(event);
                            handleCloseSelect();
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {values.material && values.material.length > 0 && (
                    <Grid
                      container
                      alignItems="center"
                      className={classes.gridLayout}
                    >
                      <ContainerBox
                        title="Materiales"
                        style={{
                          paddingLeft: '2rem',
                          paddingRight: '2rem',
                          paddingTop: '3rem',
                        }}
                        styleBox={{
                          textAlign: 'center',
                        }}
                      >
                        <Grid container spacing={3}>
                          <FieldArray name="materialDetails">
                            <>
                              {values.material.map((item, index) => (
                                <React.Fragment key={index}>
                                  {item !== 16 && (
                                    <Grid item xs={12} sm={6} md={3}>
                                      <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                      >
                                        <InputLabel>
                                          {materialNames[item]}
                                        </InputLabel>
                                        <SelectText
                                          required
                                          fullWidth
                                          label={materialNames[item]}
                                          name={`materialDetails.${index}`}
                                          list={materialItems[item]}
                                          onChange={(event) => {
                                            handleChange(event);
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                  )}
                                </React.Fragment>
                              ))}
                              {values.material.find((item) => item === 16) && (
                                <Grid item xs={12} sm={6} md={3}>
                                  <InputText
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    required
                                    fullWidth
                                    label="Otro"
                                    name={`otherMaterialDetails`}
                                    value={values.otherMaterialDetails}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(event) => {
                                      handleChange(event);
                                    }}
                                  />
                                </Grid>
                              )}
                            </>
                          </FieldArray>
                        </Grid>
                      </ContainerBox>
                    </Grid>
                  )}
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                  >
                    <Grid item md={12}>
                      <Typography fontWeight="500">
                        Tipo de manufactura:
                      </Typography>
                    </Grid>
                    <Grid item md={12}>
                      <Typography color="#979797" fontStyle="italic">
                        Se permiten hasta 500 palabras.
                      </Typography>
                    </Grid>
                    <Grid item md={12}>
                      <InputText
                        variant="outlined"
                        multiline
                        rows={4}
                        required
                        fullWidth
                        name="typeManufacture"
                        value={values.typeManufacture}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                    </Grid>
                  </Grid>
                </SubSection>
                <SubSection title="Dimensiones">
                  <Typography color="#979797" fontStyle="italic" pb="2rem">
                    Ingrese numeros en centímetros y decimales con punto,
                    ejemplo 27.8.
                  </Typography>
                  <Grid alignItems="center" container spacing={3}>
                    <Grid item md="auto">
                      <Typography className={classes.subFormTitle}>
                        Alto (cm):
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <InputText
                        type="number"
                        variant="outlined"
                        required
                        fullWidth
                        name="height"
                        autoComplete="height"
                        value={values.height}
                      />
                    </Grid>
                    <Grid item md="auto">
                      <Typography className={classes.subFormTitle}>
                        Ancho (cm):
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <InputText
                        type="number"
                        variant="outlined"
                        required
                        fullWidth
                        name="width"
                        autoComplete="width"
                        value={values.width}
                      />
                    </Grid>
                    <Grid item md="auto">
                      <Typography className={classes.subFormTitle}>
                        Largo (cm):
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <InputText
                        type="number"
                        variant="outlined"
                        required
                        fullWidth
                        name="lenght"
                        autoComplete="lenght"
                        value={values.lenght}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    alignItems="center"
                    container
                    spacing={3}
                    sx={{ marginTop: '1rem' }}
                  >
                    <Grid item md="auto">
                      <Typography className={classes.subFormTitle}>
                        Diametro (cm):
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <InputText
                        type="number"
                        variant="outlined"
                        required
                        fullWidth
                        name="diameter"
                        autoComplete="diameter"
                        value={values.diameter}
                      />
                    </Grid>
                    <Grid item md="auto">
                      <Typography className={classes.subFormTitle}>
                        Espesor (cm):
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <InputText
                        type="number"
                        variant="outlined"
                        required
                        fullWidth
                        name="thickness"
                        autoComplete="thickness"
                        value={values.thickness}
                      />
                    </Grid>
                  </Grid>
                </SubSection>
              </>
            ) : (
              <>
                <SubSection title="Colección">
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                    spacing={3}
                  >
                    <Grid item md="auto">
                      <ColectionButton active={true}>
                        {collections[collection]}
                      </ColectionButton>
                    </Grid>
                  </Grid>
                </SubSection>
                <SubSection title="Contexto de hallazgo">
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                    style={{ marginTop: '2rem' }}
                  >
                    <Grid item md={2}>
                      <RadioButton
                        checked={values.typeFinding === '1'}
                        name="typeFinding"
                        type="radio"
                        value="1"
                        label="Entierro primario"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <RadioButton
                        checked={values.typeFinding === '2'}
                        name="typeFinding"
                        type="radio"
                        value="2"
                        label="Entierro secundario"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <RadioButton
                        checked={values.typeFinding === '3'}
                        name="typeFinding"
                        type="radio"
                        value="3"
                        label="Hallazgo aislado"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <RadioButton
                        checked={values.typeFinding === '4'}
                        name="typeFinding"
                        type="radio"
                        value="4"
                        label="Otro"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <RadioButton
                        checked={values.typeFinding === '5'}
                        name="typeFinding"
                        type="radio"
                        value="5"
                        label="Sin referencias"
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                    </Grid>
                    <Grid item md={12} style={{ marginTop: '2rem' }}>
                      <Typography className={classes.subTitle}>
                        Observaciones:
                      </Typography>
                    </Grid>
                    <Grid item md={12}>
                      <InputText
                        variant="outlined"
                        multiline
                        rows={2}
                        required
                        fullWidth
                        name="observationsFinding"
                        value={values.observationsFinding}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                    </Grid>
                  </Grid>
                </SubSection>
                <SubSection title="Identificación individuo">
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                  >
                    <Grid item md={2}>
                      <Typography className={classes.subFormTitle}>
                        Individuo:
                      </Typography>
                    </Grid>
                    <Grid item container spacing={3} md={9}>
                      <Grid item md={4}>
                        <InputText
                          variant="outlined"
                          required
                          fullWidth
                          name="individual"
                          value={values.individual}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                    style={{ marginTop: '2rem' }}
                  >
                    <Grid item md={2}>
                      <Typography className={classes.subFormTitle}>
                        Material:
                      </Typography>
                    </Grid>
                    <Grid item container spacing={3} md={9} alignItems="center">
                      <Grid item md={2}>
                        <RadioButton
                          checked={values.materialIndividual === '1'}
                          name="materialIndividual"
                          type="radio"
                          value="1"
                          label="Esqueleto"
                          onChange={(event) => {
                            handleChange(event);
                            setFieldValue('otherMaterialIndividual', '');
                          }}
                        />
                      </Grid>
                      <Grid item md={2}>
                        <RadioButton
                          checked={values.materialIndividual === '2'}
                          name="materialIndividual"
                          type="radio"
                          value="2"
                          label="Momia"
                          onChange={(event) => {
                            handleChange(event);
                            setFieldValue('otherMaterialIndividual', '');
                          }}
                        />
                      </Grid>
                      <Grid item md="auto">
                        <RadioButton
                          checked={values.materialIndividual === '3'}
                          name="materialIndividual"
                          type="radio"
                          value="3"
                          label="Otro"
                          onChange={(event) => {
                            handleChange(event);
                          }}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <InputText
                          variant="outlined"
                          required
                          fullWidth
                          name="otherMaterialIndividual"
                          autoComplete="otherMaterialIndividual"
                          value={values.otherMaterialIndividual}
                          disabled={values.materialIndividual !== '3'}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                    style={{ marginTop: '2rem' }}
                  >
                    <Grid item md={2}>
                      <Typography className={classes.subFormTitle}>
                        Completitud:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      spacing={3}
                      md={10}
                      alignItems="center"
                    >
                      <Grid item md={2}>
                        <RadioPercentageContainer>
                          <RadioButton
                            checked={values.completeness === '1'}
                            name="completeness"
                            type="radio"
                            value="1"
                            label="Completo"
                            onChange={(event) => {
                              handleChange(event);
                              setFieldValue('otherCompleteness', '');
                            }}
                          />
                          <RadioLabel
                            active={values.completeness === '1' ? true : false}
                          >
                            {'>'} 75%
                          </RadioLabel>
                        </RadioPercentageContainer>
                      </Grid>
                      <Grid item md={2}>
                        <RadioPercentageContainer>
                          <RadioButton
                            checked={values.completeness === '2'}
                            name="completeness"
                            type="radio"
                            value="2"
                            label="Semicompleto"
                            onChange={(event) => {
                              handleChange(event);
                              setFieldValue('otherCompleteness', '');
                            }}
                          />
                          <RadioLabel
                            active={values.completeness === '2' ? true : false}
                          >
                            75% - 25%
                          </RadioLabel>
                        </RadioPercentageContainer>
                      </Grid>
                      <Grid item md="auto">
                        <RadioPercentageContainer>
                          <RadioButton
                            checked={values.completeness === '3'}
                            name="completeness"
                            type="radio"
                            value="3"
                            label="Incompleto"
                            onChange={(event) => {
                              handleChange(event);
                              setFieldValue('otherCompleteness', '');
                            }}
                          />
                          <RadioLabel
                            active={values.completeness === '3' ? true : false}
                          >
                            {'<'} 25%
                          </RadioLabel>
                        </RadioPercentageContainer>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      className={classes.gridLayout}
                      style={{ marginTop: '1rem' }}
                      spacing={3}
                    >
                      <Grid item md={2}></Grid>
                      <Grid item md="auto">
                        <RadioButton
                          checked={values.completeness === '4'}
                          name="completeness"
                          type="radio"
                          value="4"
                          label="Otro"
                          onChange={(event) => {
                            handleChange(event);
                            setFieldValue('otherCompleteness', '');
                          }}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <InputText
                          variant="outlined"
                          required
                          fullWidth
                          name="otherCompleteness"
                          autoComplete="otherCompleteness"
                          value={values.otherCompleteness}
                          disabled={
                            values.completeness !== '4' ||
                            values.completeness === '5'
                          }
                        />
                      </Grid>
                      <Grid item md="auto">
                        <RadioButton
                          checked={values.completeness === '5'}
                          name="completeness"
                          type="radio"
                          value="5"
                          label="Sin referencias"
                          onChange={(event) => {
                            handleChange(event);
                            setFieldValue('otherCompleteness', '');
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                    style={{ marginTop: '2rem' }}
                  >
                    <Grid item md={2}>
                      <Typography className={classes.subFormTitle}>
                        Número mínimo de individuos:
                      </Typography>
                    </Grid>
                    <Grid item container spacing={4} md={9}>
                      <Grid item md={3}>
                        <InputText
                          variant="outlined"
                          required
                          fullWidth
                          name="numberIndividual"
                          autoComplete="numberIndividual"
                          value={values.numberIndividual}
                          placeholder={
                            values.numberIndividualNoApply ? 'No aplica' : ''
                          }
                          disabled={values.numberIndividualNoApply}
                        />
                      </Grid>
                      <Grid
                        className={classes.checkboxLayout}
                        item
                        alignItems="center"
                        xs={6}
                        sm={3}
                        md={3}
                      >
                        <CheckboxCustom
                          checked={values.numberIndividualNoApply}
                          name="numberIndividualNoApply"
                          label="No aplica"
                          onChange={(e) => {
                            setFieldValue('numberIndividual', '');
                            handleChange(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                    style={{ marginTop: '2rem' }}
                  >
                    <Grid item md={2}>
                      <Typography className={classes.subFormTitle}>
                        Sexo:
                      </Typography>
                    </Grid>
                    <Grid item container spacing={4} md={9}>
                      <Grid item md={3}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <SelectText
                            required
                            fullWidth
                            value={values.sexIndividual}
                            name="sexIndividual"
                            list={genderList}
                            onChange={(event) => {
                              handleChange(event);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.gridLayout}
                    style={{ marginTop: '2rem' }}
                  >
                    <Grid item md={2}>
                      <Typography className={classes.subFormTitle}>
                        Edad:
                      </Typography>
                    </Grid>
                    <Grid item container spacing={4} md={9}>
                      <Grid item md={3}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <SelectText
                            value={values.ageIndividual}
                            disabled={values.ageIndividualNoApply}
                            required
                            fullWidth
                            name="ageIndividual"
                            list={ageList}
                            onChange={(event) => {
                              handleChange(event);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        className={classes.checkboxLayout}
                        item
                        alignItems="center"
                        xs={6}
                        sm={3}
                        md={3}
                      >
                        <CheckboxCustom
                          checked={values.ageIndividualNoApply}
                          name="ageIndividualNoApply"
                          label="No aplica"
                          onChange={(e) => {
                            setFieldValue('ageIndividual', '');
                            handleChange(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </SubSection>
                <SubSection title="PDF asociados al registro bioantropológico">
                  <Box sx={{ width: '50%' }}>
                    <TableComponent rows={files}></TableComponent>
                  </Box>
                  <PdfContainer>
                    <Typography
                      variant="h6"
                      component="div"
                      style={{ marginBottom: '4px' }}
                    >
                      Subir PDF: {selectedFile && selectedFile[0]?.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      style={{ fontStyle: 'italic', marginBottom: '1rem' }}
                    >
                      Cambiar nombre del documento
                    </Typography>
                    <InputText
                      variant="outlined"
                      required
                      fullWidth
                      name="pdfNameBio"
                      value={values.pdfNameBio}
                    />
                    <Typography
                      variant="body2"
                      component="div"
                      style={{ fontStyle: 'italic', marginTop: '10px' }}
                    >
                      El archivo solo puede ser en formato .pdf y no puede
                      superar 1GB (se puede demorar hasta 60 segundos en subir
                      el archivo).
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        marginTop: '1rem',
                        justifyContent: 'space-between',
                      }}
                    >
                      <label htmlFor="uploadPhotoBio">
                        <input
                          style={{ display: 'none' }}
                          id="uploadPhotoBio"
                          name="uploadPhotoBio"
                          type="file"
                          onChange={(e) => {
                            setSelectedFiles(e.target.files);
                          }}
                        />
                        <Fab
                          style={{ zIndex: 0 }}
                          color="primary"
                          component="span"
                          aria-label="add"
                          variant="extended"
                        >
                          <UploadFileIcon sx={{ mr: 1 }} /> Examinar archivo
                        </Fab>
                      </label>
                      <Button
                        variant="contained"
                        endIcon={<AddIcon />}
                        onClick={() => {
                          uploadPdfBio(
                            selectedFile,
                            setSelectedFiles,
                            getFilesByArticle
                          );
                        }}
                      >
                        Agregar pdf
                      </Button>
                    </Box>
                  </PdfContainer>
                </SubSection>
              </>
            )}
          </>
        )}
      </Section>
    </Box>
  );
};

export default MaterialIdentification;
