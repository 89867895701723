import React, { useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import './App.css';
import Toolbar from './components/Toolbar/Toolbar';
import Museum from './views/Museum';
import Register from './views/Register/Register';
import Home from './views/Home/Home';
import {
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box } from '@mui/system';
import Activate from 'views/Activate/Activate';
import Recovery from 'views/Recovery/Recovery';
import Verify from 'views/Register/Verify';

export const Footer = () => {
  const [value, setValue] = useState(0);
  const history = useHistory();

  return (
    <Box
      sx={{
        display: { sm: 'none', md: 'none' },
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          if (newValue === 0) {
            history.push('/museo/catalogo');
          } else if (newValue === 1) {
            localStorage.clear();
            sessionStorage.clear();
            history.push('/');
          }
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Catálogo" icon={<HomeIcon />} />
        <BottomNavigationAction label="Cerrar sesión" icon={<LogoutIcon />} />
      </BottomNavigation>
    </Box>
  );
};

function App() {
  const match = useRouteMatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Switch>
      <Route exact path="/registro">
        <Register />
      </Route>

      <Route exact path="/activar-cuenta">
        <Activate isMobile={isMobile} />
      </Route>

      <Route exact path="/verificar">
        <Verify isMobile={isMobile} />
      </Route>

      <Route exact path="/recuperar">
        <Recovery isMobile={isMobile} />
      </Route>

      <Route path={`${match.url}museo`}>
        <Toolbar isMobile={isMobile} />
        <Museum isMobile={isMobile} />
      </Route>

      <Route path="/">
        <Home />
      </Route>

      <Redirect to="/" />
    </Switch>
  );
}

export default App;
