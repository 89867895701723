import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { styled } from '@mui/material/styles';
import theme from 'components/Theme/theme';
import Skeleton from '@mui/material/Skeleton';
import emptyState from 'assets/images/empty_state.png';
import { useArticleDataContext } from 'contexts/ArticleDataContext';
import { useUserDataContext } from 'contexts/UserDataContext';
import {
  Alert,
  Avatar,
  Backdrop,
  CircularProgress,
  IconButton,
  Snackbar,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  arqueoSvg,
  bioSvg,
  etnoSvg,
  histoSvg,
  defaultSvg,
} from 'views/Catalog/collectionSvgs';
import { getData } from 'api/queries';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ReactPaginate from 'react-paginate';
import './pagination.css';
import ModalContext from 'components/Modal/Modal';
import {
  useArticleUpdateMutation,
  useArticleDeleteMutation,
} from 'api/mutations';

const Tab = styled(TabUnstyled)`
  color: white;
  cursor: pointer;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  transition: 0.3s;

  &:hover {
    background-color: white;
    color: ${theme.palette.primary.main};
  }

  &:focus {
    color: ${theme.palette.primary.main};
    border-radius: 3px;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: white;
    color: ${theme.palette.primary.main};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  background-color: #fcf9f9;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const TabsList = styled(TabsListUnstyled)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignContent: 'space-between',
}));

export const Card = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
  transition: '0.3s',
  cursor: 'pointer',
  backgroundColor: 'white',
  borderRadius: '4px',

  '&:hover': {
    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
  },
});

export const collectionStyle = {
  1: {
    name: 'C. arqueológica',
    svg: arqueoSvg,
    color: '#FEB460',
  },
  2: {
    name: 'C. histórica',
    svg: histoSvg,
    color: '#bc0b0b',
  },
  3: {
    name: 'C. bioantropológica',
    svg: bioSvg,
    color: '#A9C3B6',
  },
  4: {
    name: 'C. etnográfica',
    svg: etnoSvg,
    color: '#8FB8CA',
  },
  5: {
    name: 'Otra colección',
    svg: defaultSvg,
    color: '#6D8B74',
  },
  6: {
    name: 'Sin colección',
    svg: defaultSvg,
    color: '#CCCCCC',
  },
};

const CardComponent = ({
  article,
  setOpenBackdrop,
  setOpenStateModal,
  setOpenCallbackModal,
  setMessageAlert,
  setOpenAlert,
  setStateArticle,
  getArticlesActive,
  getArticlesInactive,
  setOpenDeleteModal,
}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const {
    id,
    collection,
    inventaryNumber,
    isActive,
    materialDetails,
    photography,
  } = article;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const { setArticleData, setArticleAttribute } = useArticleDataContext();
  const [articleUpdateMutation] = useArticleUpdateMutation(id);
  const [articleDeleteMutation] = useArticleDeleteMutation(id);

  const getArticle = async () => {
    setOpenBackdrop(true);
    await getData(`/articles/${id}?view=edit`).then((response) => {
      setArticleData(response);
      setArticleAttribute('id', response.id);
      setOpenBackdrop(false);
      history.push({
        pathname: `${match.url}/editar/${response.id}`,
      });
    });
  };

  const updateStateArticle = async () => {
    setOpenStateModal(false);
    setOpenBackdrop(true);
    await articleUpdate()
      .then((response) => {
        if (response.statusCode) {
          setOpenBackdrop(false);
          return false;
        }
        setArticleData((prevState) => ({
          ...prevState,
          ...response,
        }));
        setArticleAttribute('id', response.id);
        getArticlesActive(0);
        getArticlesInactive(0);
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'La colección se ha actualizado correctamente.',
          type: 'success',
        });
        setOpenAlert(true);
        setTimeout(() => {
          setOpenAlert(false);
        }, 5000);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'Ha ocurrido un error al guardar la colección.',
          type: 'error',
        });
      });
  };

  const articleUpdate = () => {
    return articleUpdateMutation({
      isActive: !isActive,
    });
  };

  const articleDelete = async () => {
    setOpenDeleteModal(false);
    setOpenBackdrop(true);
    await deleteMutation()
      .then(() => {
        setMessageAlert({
          message: `La ficha se ha eliminado correctamente.`,
          type: 'success',
        });
        setOpenBackdrop(false);
        setOpenAlert(true);
        if (isActive) {
          getArticlesActive(0);
        } else {
          getArticlesInactive(0);
        }
        setTimeout(() => {
          setOpenAlert(false);
        }, 5000);
      })
      .catch((e) => {
        setMessageAlert({
          message: 'Ha ocurrido un error al guardar la colección.',
          type: 'error',
        });
      });
  };

  const deleteMutation = () => {
    return articleDeleteMutation({});
  };

  let isValidImage = false;
  let mainImage = '';
  let format = '';

  if (photography) {
    mainImage = photography.split('.')[0];
    format = photography.split('.')[1];
    if (
      format === 'jpg' ||
      format === 'JPG' ||
      format === 'png' ||
      format === 'PNG' ||
      format === 'jpeg' ||
      format === 'JPEG'
    ) {
      isValidImage = true;
    }
  }

  return (
    <Grid
      item
      xs={12}
      sm={3}
      md={3}
      sx={{
        paddingRight: '1.5rem',
        paddingBottom: '1.5rem',
      }}
    >
      <Card
        onClick={() => {
          history.push({
            pathname: `catalogo/articulo/${id}`,
            state: {
              fromHome: true,
            },
          });
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '1.25rem',
            paddingRight: '8px',
          }}
        >
          <Tooltip
            title={
              collection !== null
                ? collectionStyle[collection].name
                : 'Sin colección'
            }
            placement="top"
          >
            <Avatar
              sx={{
                bgcolor:
                  collection !== null
                    ? collectionStyle[collection].color
                    : collectionStyle[6].color,
                marginRight: '12px',
              }}
            >
              {collection !== null
                ? collectionStyle[collection].svg
                : collectionStyle[6].svg}
            </Avatar>
          </Tooltip>
          <Box
            sx={{
              flex: '1 1 auto',
            }}
          >
            <span
              style={{
                margin: 0,
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
                display: 'block',
              }}
            >
              {inventaryNumber}
            </span>
            <span
              style={{
                margin: '0px',
                fontWeight: 400,
                fontSize: '0.875rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {!collection && 'Sin colección'}
              {collection === 3 && 'Individuo'}
              {collection !== 3 &&
                materialDetails.map((material, index) => {
                  const rowLen = materialDetails.length;
                  return (
                    <>
                      {rowLen === index + 1
                        ? `${material.name}`
                        : `${material.name}, `}
                    </>
                  );
                })}
            </span>
          </Box>
          <IconButton
            aria-label="settings"
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(event) => {
              event.stopPropagation();
              handleClick(event);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={(e) => {
              handleClose(e);
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              onClick={(e) => {
                handleClose(e);
                setStateArticle(isActive);
                setOpenCallbackModal(() => updateStateArticle);
                setOpenStateModal(true);
              }}
            >
              {isActive ? 'Desactivar ficha' : 'Activar ficha'}
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                handleClose(e);
                getArticle();
              }}
            >
              Editar
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                handleClose(e);
                setOpenCallbackModal(() => articleDelete);
                setOpenDeleteModal(true);
              }}
            >
              Eliminar
            </MenuItem>
          </Menu>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '180px',
            justifyContent: 'center',
            backgroundColor:
              collection !== null
                ? collectionStyle[collection].color
                : collectionStyle[6].color,
            padding: '1rem',
          }}
        >
          <img
            style={{ height: '100%', borderRadius: '4px', maxWidht: '100%' }}
            src={
              isValidImage
                ? `${process.env.REACT_APP_URL_STORAGE}${mainImage}_medium.${format}`
                : emptyState
            }
            alt="Imagen de objeto"
          />
        </Box>
      </Card>
    </Grid>
  );
};

const ArticlesHome = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const [isLoadingActive, setIsLoadingActive] = useState(true);
  const [isLoadingInactive, setIsLoadingInactive] = useState(true);
  const { clear } = useArticleDataContext();
  const { firstName } = useUserDataContext();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState({
    message: '',
    type: 'success',
  });
  const [openStateModal, setOpenStateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCallbackModal, setOpenCallbackModal] = useState(false);
  const [stateArticle, setStateArticle] = useState(false);
  const onCloseAlert = () => setOpenAlert(false);

  const [paginationActive, setPaginationActive] = useState({
    data: '',
    perPage: 12,
    currentPage: 0,
  });

  const [paginationInactive, setPaginationInactive] = useState({
    data: '',
    perPage: 12,
    currentPage: 0,
  });

  const getArticlesActive = async (selectedPage) => {
    setIsLoadingActive(true);
    await getData(
      `/articles?is_active=1&page=${selectedPage + 1}&view=full`
    ).then((data) => {
      setPaginationActive({
        ...paginationActive,
        currentPage: selectedPage + 1,
        pageCount: Math.ceil(data.total / paginationActive.perPage),
        data: data.results,
      });
      setIsLoadingActive(false);
    });
  };

  const getArticlesInactive = async (selectedPage) => {
    setIsLoadingInactive(true);
    await getData(
      `/articles?is_active=0&page=${selectedPage + 1}&view=full`
    ).then((data) => {
      setPaginationInactive({
        ...paginationInactive,
        currentPage: selectedPage + 1,
        pageCount: Math.ceil(data.total / paginationInactive.perPage),
        data: data.results,
      });
      setIsLoadingInactive(false);
    });
  };

  useEffect(() => {
    getArticlesActive(0);
    getArticlesInactive(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageClickActive = (e) => {
    const selectedPage = e.selected;

    setPaginationActive({
      ...paginationActive,
      currentPage: selectedPage,
      forcePage: selectedPage,
    });

    getArticlesActive(selectedPage);
  };

  const handlePageClickInactive = (e) => {
    const selectedPage = e.selected;

    setPaginationInactive({
      ...paginationInactive,
      currentPage: selectedPage,
      forcePage: selectedPage,
    });

    getArticlesInactive(selectedPage);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        backgroundColor: '#FCF6EC',
        padding: '1rem 1rem',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'end',
        }}
      >
        <div>
          <Typography
            variant="h4"
            color="#999388"
            style={{ textTransform: 'capitalize' }}
          >
            Bienvenido {firstName}
          </Typography>
          <Typography variant="h5" color="initial">
            ¿Que deseas hacer?
          </Typography>
        </div>
        <div>
          <Button
            onClick={() => {
              clear();
              history.push(`${match.url}/crear`);
            }}
            variant="contained"
            color="third"
          >
            Realizar un nuevo registro
          </Button>
        </div>
      </Box>
      <TabsUnstyled defaultValue={0} style={{ marginTop: '2rem' }}>
        <TabsList>
          <Tab>Fichas completadas</Tab>
          <Tab>Fichas Incompletas</Tab>
        </TabsList>
        <TabPanel value={0}>
          {isLoadingActive && (
            <Box
              sx={{
                paddingTop: '24px',
                paddingLeft: '24px',
                paddingRight: '8px',
              }}
            >
              <Grid
                container
                sx={{
                  overflow: 'scroll',
                  maxHeight: '37rem',
                  paddingBottom: '1rem',
                }}
              >
                {Array.from(Array(8)).map((_, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    md={3}
                    key={index}
                    sx={{
                      paddingRight: '1.5rem',
                      paddingBottom: '1.5rem',
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      height={240}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          {!isLoadingActive &&
            paginationActive.data &&
            paginationActive.data.length > 0 && (
              <Box
                sx={{
                  paddingTop: '24px',
                  paddingLeft: '24px',
                  paddingRight: '8px',
                }}
              >
                <Grid
                  container
                  sx={{
                    overflow: 'scroll',
                    maxHeight: '37rem',
                    paddingBottom: '1rem',
                  }}
                >
                  {paginationActive.data.map((article, index) => (
                    <CardComponent
                      key={index}
                      setOpenBackdrop={setOpenBackdrop}
                      setOpenStateModal={setOpenStateModal}
                      setOpenCallbackModal={setOpenCallbackModal}
                      setStateArticle={setStateArticle}
                      setMessageAlert={setMessageAlert}
                      setOpenAlert={setOpenAlert}
                      article={article}
                      getArticlesActive={getArticlesActive}
                      getArticlesInactive={getArticlesInactive}
                      setOpenDeleteModal={setOpenDeleteModal}
                    />
                  ))}
                </Grid>
              </Box>
            )}
          {!isLoadingActive &&
            paginationActive.data &&
            paginationActive.data.length === 0 && (
              <Box sx={{ padding: '2rem 2rem 0rem 2rem' }}>
                <Box
                  sx={{
                    height: '9rem',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'center',
                    backgroundColor: '#CCCCCC',
                    color: '#fff',
                    borderRadius: '8px',
                    fontSize: '18px',
                    fontWeight: '500',
                  }}
                >
                  Aún no se han agregado fichas activas, prueba cambiando el
                  estado de una inactiva.
                </Box>
              </Box>
            )}
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={paginationActive.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClickActive}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            forcePage={paginationActive.forcePage}
          />
        </TabPanel>
        <TabPanel value={1}>
          {isLoadingInactive && (
            <Box
              sx={{
                paddingTop: '24px',
                paddingLeft: '24px',
                paddingRight: '8px',
              }}
            >
              <Grid
                container
                sx={{
                  overflow: 'scroll',
                  maxHeight: '37rem',
                  paddingBottom: '1rem',
                }}
              >
                {Array.from(Array(8)).map((_, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    md={3}
                    key={index}
                    sx={{
                      paddingRight: '1.5rem',
                      paddingBottom: '1.5rem',
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      height={240}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          {!isLoadingInactive &&
            paginationInactive.data &&
            paginationInactive.data.length > 0 && (
              <Box
                sx={{
                  paddingTop: '24px',
                  paddingLeft: '24px',
                  paddingRight: '8px',
                }}
              >
                <Grid
                  container
                  sx={{
                    overflow: 'scroll',
                    maxHeight: '37rem',
                    paddingBottom: '1rem',
                  }}
                >
                  {paginationInactive.data.map((article, index) => (
                    <CardComponent
                      key={index}
                      setOpenBackdrop={setOpenBackdrop}
                      setOpenStateModal={setOpenStateModal}
                      setOpenCallbackModal={setOpenCallbackModal}
                      setStateArticle={setStateArticle}
                      setMessageAlert={setMessageAlert}
                      setOpenAlert={setOpenAlert}
                      article={article}
                      getArticlesActive={getArticlesActive}
                      getArticlesInactive={getArticlesInactive}
                      setOpenDeleteModal={setOpenDeleteModal}
                    />
                  ))}
                </Grid>
              </Box>
            )}

          {!isLoadingInactive &&
            paginationInactive.data &&
            paginationInactive.data.length === 0 && (
              <Box sx={{ padding: '2rem 2rem 0rem 2rem' }}>
                <Box
                  sx={{
                    height: '9rem',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'center',
                    backgroundColor: '#CCCCCC',
                    color: '#fff',
                    borderRadius: '8px',
                    fontSize: '18px',
                    fontWeight: '500',
                  }}
                >
                  Aún no se han agregado fichas inactivas, crea una nueva ficha
                  para ver este apartado.
                </Box>
              </Box>
            )}
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={paginationInactive.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClickInactive}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </TabPanel>
      </TabsUnstyled>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={onCloseAlert}
      >
        <Alert
          severity={messageAlert.type}
          sx={{ width: '100%' }}
          onClose={onCloseAlert}
        >
          {messageAlert.message}
        </Alert>
      </Snackbar>
      <ModalContext
        open={openStateModal}
        callback={() => openCallbackModal()}
        onClose={() => setOpenStateModal(false)}
        title={`Cambiando estado de ficha a ${
          stateArticle ? 'activo' : 'inactivo'
        }`}
        buttonTitle={'Cambiar estado'}
        content={
          <>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              ¿Estás seguro que deseas actualizar el estado de la ficha?
            </Typography>
          </>
        }
      />
      <ModalContext
        open={openDeleteModal}
        callback={() => openCallbackModal()}
        onClose={() => setOpenDeleteModal(false)}
        title={`Eliminando ficha`}
        buttonTitle={'Eliminar'}
        content={
          <>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              ¿Estás seguro que deseas eliminar la ficha? Si eliminas la ficha
              seleccionada no habrá vuelta atrás.
            </Typography>
          </>
        }
      />
    </Box>
  );
};

export default ArticlesHome;
