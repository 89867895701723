import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import styled from 'styled-components';
import css from '@styled-system/css';
import { variant } from 'styled-system';
import { motion } from 'framer-motion';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  title: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 20,
    paddingLeft: '16px',
  },
  formTitle: {
    paddingRight: '16px',
    textAlign: 'right',
  },
  gridLayout: {
    marginBottom: '32px',
  },
  checkboxLayout: {
    display: 'flex',
  },
  divider: {
    backgroundColor: '#EB8242',
  },
  subSectionTitle: {
    fontSize: 18,
    color: 'white',
  },
  subFormTitle: {
    paddingRight: '16px',
  },
  subTitle: {
    fontWeight: '500',
    fontSize: 18,
  },
}));

export const Section = ({ children, title, style }) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'white',
        borderRadius: 8,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        marginTop: '32px',
        marginBottom: '6rem',
      }}
      style={style}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '60px',
          borderRadius: '16px',
          backgroundColor: '#CCCCCC',
        }}
      >
        <Typography className={classes.title} variant="h6">
          {title}
        </Typography>
      </Box>
      <Box sx={{ padding: '30px' }}>{children}</Box>
    </Box>
  );
};

export const SubSection = ({ children, title, style, boxStyle }) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        width: '100%',
        padding: '0 2rem',
      }}
      style={style}
    >
      <Divider className={classes.divider} variant="middle" />
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          backgroundColor: '#EB8242',
          padding: '12px',
          marginLeft: '2rem',
          boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        }}
      >
        <Box className={classes.subSectionTitle}>{title}</Box>
      </Box>
      <Box sx={{ padding: '30px' }} style={boxStyle}>
        {children}
      </Box>
    </Box>
  );
};

export const ContainerBox = ({ children, title, style, styleBox }) => {
  return (
    <Box
      sx={{
        width: '100%',
        border: '1px solid #EB8242',
        paddingTop: '2rem',
        paddingBottom: '2rem',
        borderRadius: '1rem',
        marginTop: '2rem',
        position: 'relative',
      }}
      style={style}
    >
      <Box
        sx={{
          position: 'absolute',
          minHeight: '2rem',
          minWidth: '9rem',
          borderRadius: '8px',
          background: '#EF4B4B',
          color: 'white',
          top: '-1rem',
          zIndex: '1',
          left: '1rem',
          padding: '8px 1rem',
          boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        }}
        style={styleBox}
      >
        {title}
      </Box>
      {children}
    </Box>
  );
};

export const CardLabel = styled('label')(
  css({
    padding: '4px 0px',
    borderRadius: '16px',
    color: 'white',
    fontSize: '18px',
    marginTop: '8px',
    textAlign: 'center',
    maxWidth: '10rem',
  }),
  variant({
    prop: 'type',
    variants: {
      1: {
        backgroundColor: '#EB8242',
      },
      2: {
        backgroundColor: '#EF4B4B',
      },
      3: {
        backgroundColor: '#3B637E',
      },
      4: {
        backgroundColor: '#999388',
      },
      5: {
        backgroundColor: '#FFB22B',
      },
    },
  })
);

export const MotionPage = ({ children, direction, height }) => {
  const pageVariant = () => {
    return {
      initial: {
        opacity: 0.5,
        x: `${direction * 50}vw`,
      },
      in: {
        opacity: 1,
        x: 0,
      },
      out: {
        opacity: 0.5,
        x: `${direction * -50}vw`,
      },
    };
  };

  const pageTransition = {
    x: { type: 'spring', duration: 0.333, bounce: 0.4 },
  };
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariant()}
      transition={pageTransition}
      style={{}}
    >
      {children}
    </motion.div>
  );
};
