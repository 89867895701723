import React, { useState, useEffect } from 'react';
import {
  Alert,
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Skeleton,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Form, Formik } from 'formik';
import SelectText from 'components/Select/Select';
import { materials } from 'views/Articles/articlesArray';
import SearchIcon from '@mui/icons-material/Search';
import InputText from 'components/Input/InputText';
import logo from 'assets/images/logo.png';
import communeLogo from 'assets/images/commune_logo.png';
import gobiernoLogo from 'assets/images/gobierno_logo.png';
import { getData } from 'api/queries';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useArticleDataContext } from 'contexts/ArticleDataContext';
import {
  useArticleUpdateMutation,
  useArticleDeleteMutation,
} from 'api/mutations';
import { collectionStyle } from 'views/Articles/ArticlesHome';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import emptyState from 'assets/images/empty_state.png';
import ReactPaginate from 'react-paginate';
import {
  arqueoCollectionSvg,
  bioCollectionSvg,
  etnoCollectionSvg,
  histoCollectionSvg,
} from 'views/Catalog/collectionSvgs';
import ModalContext from 'components/Modal/Modal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useUserDataContext } from 'contexts/UserDataContext';

const CardComponent = ({
  article,
  setOpenBackdrop,
  setOpenStateModal,
  setOpenCallbackModal,
  setMessageAlert,
  setOpenAlert,
  getArticlesActive,
  setOpenDeleteModal,
  currentUrlParams,
}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { id, collection, inventaryNumber, photography, materialDetails } =
    article;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const { setArticleData, setArticleAttribute } = useArticleDataContext();
  const [articleUpdateMutation] = useArticleUpdateMutation(id);
  const [articleDeleteMutation] = useArticleDeleteMutation(id);
  const { isStaff } = useUserDataContext();

  const getArticle = async () => {
    setOpenBackdrop(true);
    await getData(`/articles/${id}?view=edit`).then((response) => {
      setArticleData(response);
      setArticleAttribute('id', response.id);
      setOpenBackdrop(false);
      history.push(`articulos/crear`);
    });
  };

  const updateStateArticle = async () => {
    setOpenStateModal(false);
    setOpenBackdrop(true);
    await articleUpdate()
      .then((response) => {
        if (response.statusCode) {
          setOpenBackdrop(false);
          return false;
        }
        setArticleData((prevState) => ({
          ...prevState,
          ...response,
        }));
        setArticleAttribute('id', response.id);
        getArticlesActive(1);
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'La colección se ha actualizado correctamente.',
          type: 'success',
        });
        setOpenAlert(true);
        setTimeout(() => {
          setOpenAlert(false);
        }, 5000);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        setMessageAlert({
          message: 'Ha ocurrido un error al guardar la colección.',
          type: 'error',
        });
      });
  };

  const articleUpdate = () => {
    return articleUpdateMutation({
      isActive: false,
    });
  };

  const articleDelete = async () => {
    setOpenDeleteModal(false);
    setOpenBackdrop(true);
    await deleteMutation()
      .then(() => {
        setMessageAlert({
          message: `La ficha se ha eliminado correctamente.`,
          type: 'success',
        });
        setOpenBackdrop(false);
        setOpenAlert(true);
        getArticlesActive(1);
        setTimeout(() => {
          setOpenAlert(false);
        }, 5000);
      })
      .catch((e) => {
        setMessageAlert({
          message: 'Ha ocurrido un error al guardar la colección.',
          type: 'error',
        });
      });
  };

  const deleteMutation = () => {
    return articleDeleteMutation({});
  };

  let isValidImage = false;
  let mainImage = '';
  let format = '';

  if (photography) {
    mainImage = photography.split('.')[0];
    format = photography.split('.')[1];
    if (
      format === 'jpg' ||
      format === 'JPG' ||
      format === 'png' ||
      format === 'PNG' ||
      format === 'jpeg' ||
      format === 'JPEG'
    ) {
      isValidImage = true;
    }
  }

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      sx={{
        paddingRight: '1.5rem',
        paddingBottom: '1.5rem',
      }}
    >
      <Box
        onClick={() => {
          history.push({
            pathname: `${match.url}/articulo/${id}`,
            state: {
              currentUrlParams: {
                page: currentUrlParams.get('page'),
                query: currentUrlParams.get('query'),
                material: currentUrlParams.get('material'),
                collection: currentUrlParams.get('collection'),
                functionality: currentUrlParams.get('functionality'),
              },
            },
          });
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          transition: '0.3s',
          cursor: 'pointer',
          backgroundColor: 'white',
          borderRadius: '4px',

          '&:hover': {
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
          },
        }}
      >
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: '1rem',
              paddingRight: '8px',
            }}
          >
            <Tooltip
              title={
                collection !== null
                  ? collectionStyle[collection].name
                  : collectionStyle[5].name
              }
              placement="top"
            >
              <Avatar
                sx={{
                  bgcolor:
                    collection !== null
                      ? collectionStyle[collection].color
                      : collectionStyle[5].color,
                  marginRight: '12px',
                }}
              >
                {collection !== null
                  ? collectionStyle[collection].svg
                  : collectionStyle[5].svg}
              </Avatar>
            </Tooltip>
            <Box
              sx={{
                flex: '1 1 auto',
              }}
            >
              <span
                style={{
                  margin: 0,
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: 1.43,
                  letterSpacing: '0.01071em',
                  display: 'block',
                }}
              >
                {inventaryNumber}
              </span>
              <span
                style={{
                  margin: '0px',
                  fontWeight: 400,
                  fontSize: '0.875rem',
                  lineHeight: 1.43,
                  letterSpacing: '0.01071em',
                  display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {!collection && 'Sin colección'}
                {collection === 3 && 'Individuo'}
                {collection !== 3 &&
                  materialDetails.map((material, index) => {
                    const rowLen = materialDetails.length;
                    return (
                      <>
                        {rowLen === index + 1
                          ? `${material.name}`
                          : `${material.name}, `}
                      </>
                    );
                  })}
              </span>
            </Box>
            {isStaff && (
              <>
                <IconButton
                  aria-label="settings"
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClick(event);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={(e) => {
                    handleClose(e);
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    onClick={(e) => {
                      handleClose(e);
                      setOpenCallbackModal(() => updateStateArticle);
                      setOpenStateModal(true);
                    }}
                  >
                    Desactivar ficha
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      handleClose(e);
                      getArticle();
                    }}
                  >
                    Editar
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      handleClose(e);
                      setOpenCallbackModal(() => articleDelete);
                      setOpenDeleteModal(true);
                    }}
                  >
                    Eliminar
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: '180px',
              justifyContent: 'center',
              backgroundColor:
                collection !== null
                  ? collectionStyle[collection].color
                  : collectionStyle[5].color,
              padding: '1.25rem',
            }}
          >
            <img
              style={{ height: '100%', borderRadius: '4px', maxWidth: '100%' }}
              src={
                isValidImage
                  ? `${process.env.REACT_APP_URL_STORAGE}${mainImage}_medium.${format}`
                  : emptyState
              }
              alt="Imagen de objeto"
            />
          </Box>
        </>
      </Box>
    </Grid>
  );
};

const Collection = ({ id, image, name, collectionSelect, onClick, color }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        width: '140px',
        height: '120px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        opacity: id === collectionSelect ? 1 : 0.8,
        transition: '0.3s',
        backgroundColor: id === collectionSelect ? color : '#c7c7c7',
        borderRadius: '8px',

        '&:hover': {
          backgroundColor: color,
          opacity: 1,
        },
      }}
    >
      {image}
      <Typography
        variant="body1"
        style={{
          color: 'white',
          transition: '0.3s',
          marginTop: '8px',
        }}
      >
        {name}
      </Typography>
    </Box>
  );
};

const Catalog = ({
  pagination,
  setPagination,
  isLoadingActive,
  isFiltered,
  filters,
  setIsLoadingActive,
  setIsFiltered,
  setFilters,
}) => {
  const history = useHistory();
  let currentUrlParams = new URLSearchParams(window.location.search);
  const [collectionSelect, setCollection] = useState(
    currentUrlParams.get('collection')
      ? Number(currentUrlParams.get('collection'))
      : null
  );
  const collections = [
    {
      id: 1,
      image: arqueoCollectionSvg,
      name: 'Arqueológica',
      width: '4rem',
      color: '#FEB460',
    },
    {
      id: 2,
      image: histoCollectionSvg,
      name: 'Histórica',
      width: '4rem',
      color: '#bc0b0b',
    },
    {
      id: 3,
      image: bioCollectionSvg,
      name: 'Bioantropológica',
      width: '25px',
      color: '#A9C3B6',
    },
    {
      id: 4,
      image: etnoCollectionSvg,
      name: 'Etnográfica',
      width: '65px',
      color: '#8FB8CA',
    },
  ];
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openStateModal, setOpenStateModal] = useState(false);
  const [openCallbackModal, setOpenCallbackModal] = useState(false);
  const [messageAlert, setMessageAlert] = useState({
    message: '',
    type: 'success',
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const onCloseAlert = () => setOpenAlert(false);

  const getArticlesActive = async (selectedPage, collection = null) => {
    if (isLoadingActive) {
      return false;
    }

    let filtersQuery = '';

    if (filters.query.length > 0) {
      currentUrlParams.set('query', filters.query);
      filtersQuery = `&query=${filters.query}`;
    } else {
      currentUrlParams.delete('query');
    }

    if (filters.functionality.length !== 0) {
      currentUrlParams.set('functionality', filters.functionality);
      filtersQuery = `${filtersQuery}&functionality=${filters.functionality}`;
    } else {
      currentUrlParams.delete('functionality');
    }

    if (filters.material.length !== 0) {
      currentUrlParams.set('material', filters.material);
      filtersQuery = `${filtersQuery}&material=${filters.material}`;
    } else {
      currentUrlParams.delete('material');
    }

    if (collection !== null) {
      currentUrlParams.set('collection', collection);
      filtersQuery = `${filtersQuery}&collection=${collection}`;
    } else {
      currentUrlParams.delete('collection');
    }

    currentUrlParams.set('page', selectedPage);
    history.push(window.location.pathname + '?' + currentUrlParams.toString());

    setIsLoadingActive(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    await getData(
      `/articles?is_active=1&page=${selectedPage}&view=catalog${filtersQuery}`
    ).then((data) => {
      setPagination({
        ...pagination,
        currentPage: selectedPage - 1,
        forcePage: selectedPage - 1,
        pageCount: Math.ceil(data.total / pagination.perPage),
        data: data.results,
      });
      setIsLoadingActive(false);
    });
  };

  const handlePageClickActive = (e) => {
    if (isLoadingActive) {
      return false;
    }

    const selectedPage = e.selected;

    setPagination({
      ...pagination,
      currentPage: selectedPage,
      forcePage: selectedPage,
    });

    getArticlesActive(selectedPage + 1, collectionSelect);
  };

  const filterSearch = () => {
    if (isLoadingActive) {
      return false;
    }

    if (
      filters.query.length > 0 ||
      filters.material.length !== 0 ||
      filters.functionality.length !== 0 ||
      collectionSelect !== 0
    ) {
      setIsFiltered(true);
    }

    setPagination({
      ...pagination,
      currentPage: 0,
      forcePage: 0,
    });
    getArticlesActive(1, collectionSelect);
  };

  useEffect(() => {
    const handleEnter = (e) => {
      if (e.key === 'Enter') {
        filterSearch();
      }
    };
    window.addEventListener('keypress', handleEnter);

    return () => {
      window.removeEventListener('keypress', handleEnter);
    };
  });

  return (
    <>
      <Box>
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'space-around',
            padding: '2rem 0rem',
          }}
        >
          {collections.map(({ id, image, name, width, color }) => (
            <Collection
              onClick={() => {
                if (collectionSelect === id) {
                  setCollection(null);
                  getArticlesActive(1);
                } else {
                  setCollection(id);
                  getArticlesActive(1, id);
                }
              }}
              key={id}
              id={id}
              image={image}
              name={name}
              width={width}
              collectionSelect={collectionSelect}
              color={color}
            />
          ))}
        </Box>
        <Box>
          <Box
            sx={{
              backgroundColor: '#DDD4C5',
              padding: '2rem 3rem',
            }}
          >
            <Formik
              initialValues={{
                functionality: currentUrlParams.get('functionality')
                  ? currentUrlParams.get('functionality')
                  : '',
                material: currentUrlParams.get('material')
                  ? currentUrlParams.get('material')
                  : '',
                query: currentUrlParams.get('query')
                  ? currentUrlParams.get('query')
                  : '',
              }}
              validate={(values) => {
                const errors = {};

                return errors;
              }}
            >
              {({
                values,
                isSubmitting,
                isValid,
                setFieldValue,
                ...formikProps
              }) => {
                const handleChange = (event) => {
                  const { name, value } = event.target;
                  formikProps.setFieldTouched(event.target.name, false);
                  formikProps.handleChange(event);
                  setFilters({ ...filters, [name]: value });
                };

                const handleBlur = (event) => {
                  const { name } = event.target;
                  formikProps.handleBlur(event);
                  const trimmedValue = event.target.value.trim();
                  setFieldValue(event.target.name, trimmedValue);
                  setFilters({ ...filters, [name]: trimmedValue });
                };

                return (
                  <Form>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3}>
                        <Typography variant="body1" color="#FFF">
                          Función
                        </Typography>
                        <FormControl variant="outlined" fullWidth>
                          <SelectText
                            required
                            fullWidth
                            name="functionality"
                            list={[
                              { value: 1, label: 'Funerario' },
                              { value: 2, label: 'Doméstico' },
                              { value: 3, label: 'Tarea' },
                            ]}
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            sx={{ backgroundColor: 'white' }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body1" color="#FFF">
                          Material
                        </Typography>
                        <FormControl variant="outlined" fullWidth>
                          <SelectText
                            required
                            fullWidth
                            name="material"
                            list={materials}
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            sx={{ backgroundColor: 'white' }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs="auto">
                        <Button
                          variant="contained"
                          endIcon={<SearchIcon />}
                          sx={{ marginTop: '24px' }}
                          onClick={() => filterSearch()}
                        >
                          Buscar
                        </Button>
                      </Grid>
                      <Grid item xs="auto">
                        <Button
                          onClick={async () => {
                            history.push(window.location.pathname + '?page=1');
                            setFieldValue('query', '');
                            setFieldValue('functionality', '');
                            setFieldValue('material', '');
                            setFieldValue('collection', null);
                            setCollection(null);
                            setFilters({
                              query: '',
                              functionality: '',
                              material: '',
                              collection: '',
                            });
                            setIsLoadingActive(true);
                            await getData(
                              `/articles?is_active=1&page=${1}&view=catalog`
                            ).then((data) => {
                              setPagination({
                                ...pagination,
                                currentPage: 1,
                                pageCount: Math.ceil(
                                  data.total / pagination.perPage
                                ),
                                data: data.results,
                              });
                              setIsLoadingActive(false);
                            });
                          }}
                          sx={{ marginTop: '24px' }}
                          variant="contained"
                          color="third"
                          endIcon={<DeleteForeverIcon />}
                        >
                          Limpiar filtros
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <InputText
                          sx={{ backgroundColor: 'white' }}
                          variant="outlined"
                          placeholder="Palabras clave: Número de inventario, nombre del sitio, comuna, localidad, periodo, adcripción cultural, detalles de material"
                          required
                          fullWidth
                          name="query"
                          value={values.query}
                          onChange={(event) => {
                            handleChange(event);
                          }}
                          onBlur={handleBlur}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
        {isLoadingActive && (
          <Box
            sx={{
              paddingTop: '24px',
              paddingLeft: '24px',
              paddingRight: '8px',
              backgroundColor: '#F7F7F7',
            }}
          >
            <Grid
              container
              sx={{
                overflow: 'scroll',
                maxHeight: '37rem',
                paddingBottom: '1rem',
              }}
            >
              {Array.from(Array(8)).map((_, index) => (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={3}
                  key={index}
                  sx={{
                    paddingRight: '1.5rem',
                    paddingBottom: '1.5rem',
                  }}
                >
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={240}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {!isLoadingActive && pagination.data && pagination.data.length > 0 && (
          <Box
            sx={{
              paddingTop: '24px',
              paddingLeft: '24px',
              paddingRight: '8px',
              backgroundColor: '#F7F7F7',
            }}
          >
            <Grid
              container
              sx={{
                paddingBottom: '1rem',
              }}
            >
              {pagination.data.map((article, index) => (
                <CardComponent
                  key={index}
                  setOpenBackdrop={setOpenBackdrop}
                  setOpenStateModal={setOpenStateModal}
                  setOpenCallbackModal={setOpenCallbackModal}
                  setMessageAlert={setMessageAlert}
                  setOpenAlert={setOpenAlert}
                  article={article}
                  getArticlesActive={getArticlesActive}
                  setOpenDeleteModal={setOpenDeleteModal}
                  currentUrlParams={currentUrlParams}
                />
              ))}
            </Grid>
          </Box>
        )}

        {!isLoadingActive &&
          pagination.data &&
          pagination.data.length === 0 &&
          isFiltered && (
            <Box
              sx={{
                padding: '2rem 2rem 0rem 2rem',
                backgroundColor: '#F7F7F7',
              }}
            >
              <Box
                sx={{
                  height: '9rem',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'center',
                  backgroundColor: '#CCCCCC',
                  color: '#fff',
                  borderRadius: '8px',
                  fontSize: '18px',
                  fontWeight: '500',
                }}
              >
                No se encontraron fichas con los filtros seleccionados
              </Box>
            </Box>
          )}

        {!isLoadingActive &&
          pagination.data &&
          pagination.data.length === 0 &&
          !isFiltered &&
          filters.query.length === 0 &&
          filters.material.length === 0 &&
          filters.functionality.length === 0 && (
            <Box
              sx={{
                padding: '2rem 2rem 0rem 2rem',
                backgroundColor: '#F7F7F7',
              }}
            >
              <Box
                sx={{
                  height: '9rem',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'center',
                  backgroundColor: '#CCCCCC',
                  color: '#fff',
                  borderRadius: '8px',
                  fontSize: '18px',
                  fontWeight: '500',
                }}
              >
                Aún no se han agregado fichas al catálogo.
              </Box>
            </Box>
          )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pagination.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClickActive}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            forcePage={pagination.forcePage}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            backgroundColor: '#FCF6EC',
            padding: '3rem',
          }}
        >
          <img src={logo} alt="" style={{ width: '13rem' }} />
          <img src={communeLogo} alt="" style={{ width: '10rem' }} />
          <img src={gobiernoLogo} alt="" style={{ width: '7rem' }} />
        </Box>
        <Box sx={{ height: '50px', backgroundColor: '#999388' }}></Box>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={onCloseAlert}
      >
        <Alert
          severity={messageAlert.type}
          sx={{ width: '100%' }}
          onClose={onCloseAlert}
        >
          {messageAlert.message}
        </Alert>
      </Snackbar>
      <ModalContext
        open={openStateModal}
        callback={() => openCallbackModal()}
        onClose={() => setOpenStateModal(false)}
        title={`Cambiando estado de ficha a inactivo`}
        buttonTitle={'Cambiar estado'}
        content={
          <>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              ¿Estás seguro que deseas actualizar el estado de la ficha?
            </Typography>
          </>
        }
      />
      <ModalContext
        open={openDeleteModal}
        callback={() => openCallbackModal()}
        onClose={() => setOpenDeleteModal(false)}
        title={`Eliminando ficha`}
        buttonTitle={'Eliminar'}
        content={
          <>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              ¿Estás seguro que deseas eliminar la ficha? Si eliminas la ficha
              seleccionada no habrá vuelta atrás.
            </Typography>
          </>
        }
      />
    </>
  );
};

export default Catalog;
