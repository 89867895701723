import React, { useEffect, useState } from 'react';
import { makeStyles, styled } from '@mui/styles';
import { Box } from '@mui/system';
import logo from 'assets/images/logo.png';
import communeLogo from 'assets/images/commune_logo.png';
import gobiernoLogo from 'assets/images/gobierno_logo.png';
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import isValidEmail from 'utils/isValidEmail';
import InputText from 'components/Input/InputText';
import { useHistory } from 'react-router-dom';
import { patchData, postData } from 'api/queries';
import { useLocation } from 'react-router-dom';

const Toolbar = styled('div')({
  height: '74px',
  background: '#EB8242',
  boxShadow:
    '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  position: 'fixed',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '1rem',
});

const CardContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
  borderRadius: '8px',
  padding: '2rem',
  maxWidth: '640px',
  background: 'white',
});

const Container = styled('div')({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
  padding: '2rem',
  height: '100%',
  backgroundColor: '#fcf6ec',
  paddingTop: '106px',
});

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  gridLayout: {
    marginBottom: '24px',
  },
}));

const Recovery = ({ isMobile }) => {
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState(false);
  const [isSentPassword, setIsSentPassword] = useState(false);
  const [isSentAccount, setIsSentAccount] = useState(false);
  const [isRecoveryPassword, setIsRecoveryPassword] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [stepRecovery, setStepRecovery] = useState(null);
  const [isActivate, setIsActivate] = useState(null);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const [isVerify, setIsVerify] = useState(null);
  const [mailError, setMailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const verifyAccount = async () => {
    setOpenBackdrop(true);
    try {
      await postData('/users/validate_recovery_password/', {
        token,
      }).then((data) => {
        if (data.statusCode === 400) {
          setIsVerify(false);
          setOpenBackdrop(false);
          return false;
        }
        setIsVerify(true);
        setOpenBackdrop(false);
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (token) {
      setIsRecoveryPassword(true);
      verifyAccount();
    } else {
      setIsFirstStep(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Toolbar
        style={{
          justifyContent: isMobile ? 'center' : 'end',
        }}
      >
        <Box
          sx={{ display: 'flex' }}
          style={{
            width: isMobile ? '100%' : '',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={logo}
            alt=""
            style={{ width: '100px', marginRight: '1.5rem' }}
          />
          <img
            src={communeLogo}
            alt=""
            style={{ width: '85px', marginRight: '1.5rem' }}
          />
          <img src={gobiernoLogo} alt="" style={{ width: '60px' }} />
        </Box>
      </Toolbar>
      <Container>
        {stepRecovery === null &&
          !isRecoveryPassword &&
          !isChangePassword &&
          isFirstStep && (
            <CardContainer style={{ minHeight: '400px' }}>
              <p style={{ fontSize: '20px', marginBottom: '1rem' }}>
                Bienvenido a la recuperación de cuenta
              </p>
              <p>Elige entre las opciones que disponemos para ti:</p>
              <Box sx={{ margin: 'auto 0rem 0rem 0rem' }}>
                <Box sx={{ marginBottom: '1rem' }}>
                  <Button
                    onClick={() => setStepRecovery(1)}
                    variant="contained"
                    color="primary"
                  >
                    Recuperar contraseña
                  </Button>
                </Box>
                <Box sx={{ marginBottom: '1rem' }}>
                  <Button
                    onClick={() => setStepRecovery(2)}
                    variant="contained"
                    color="primary"
                  >
                    Recuperar/Activar cuenta
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => history.push('/')}
                    variant="outlined"
                    color="primary"
                  >
                    Volver al inicio
                  </Button>
                </Box>
              </Box>
            </CardContainer>
          )}
        {stepRecovery === 1 &&
          isSentPassword &&
          !isSentAccount &&
          !isRecoveryPassword &&
          !isChangePassword &&
          !isActivate && (
            <CardContainer style={{ minHeight: '400px' }}>
              <p style={{ fontSize: '20px', marginBottom: '1rem' }}>
                Revisa tu correo
              </p>
              <p style={{ marginBottom: '1rem' }}>
                Entra en tu correo electrónico <b>{email}</b> y haz clic en el
                enlace de
                <b> restablecimiento de contraseña</b> que te enviamos a tu
                cuenta.
              </p>
              <p>
                Podría tardar unos minutos en aparecer, asegúrate de revisar tus
                carpetas de spam y correos no deseados — ¡por si acaso!
              </p>
              <span
                style={{ position: 'absolute', bottom: '2rem', right: '2rem' }}
              >
                <Button
                  onClick={() => history.push('/')}
                  variant="contained"
                  color="primary"
                >
                  Listo
                </Button>
              </span>
            </CardContainer>
          )}
        {stepRecovery === 2 &&
          isSentAccount &&
          !isSentPassword &&
          !isRecoveryPassword &&
          !isChangePassword &&
          !isActivate && (
            <CardContainer>
              <p style={{ fontSize: '20px', marginBottom: '1rem' }}>
                Revisa tu correo
              </p>
              <p style={{ marginBottom: '1rem' }}>
                Entra en tu correo electrónico <b>{email}</b> y haz clic en el
                enlace de
                <b> activación de cuenta</b> que te enviamos.
              </p>
              <p>
                Podría tardar unos minutos en aparecer, asegúrate de revisar tus
                carpetas de spam y correos no deseados — ¡por si acaso!
              </p>
              <span
                style={{ position: 'absolute', bottom: '2rem', right: '2rem' }}
              >
                <Button
                  onClick={() => history.push('/')}
                  variant="contained"
                  color="primary"
                >
                  Listo
                </Button>
              </span>
            </CardContainer>
          )}
        {stepRecovery &&
          isActivate &&
          !isSentAccount &&
          !isSentPassword &&
          !isRecoveryPassword &&
          !isChangePassword && (
            <CardContainer>
              <p style={{ fontSize: '20px', marginBottom: '1rem' }}>
                Tu cuenta ya se encuentra activa
              </p>
              <p>
                La cuenta ingresada ya se encontraba activa, ingresa en el botón
                de a continuación para ingresar a tu cuenta.
              </p>
              <Box sx={{ marginTop: '2rem', textAlign: 'right' }}>
                <Button
                  onClick={() => history.push('/')}
                  variant="contained"
                  color="primary"
                >
                  Ingresar
                </Button>
              </Box>
            </CardContainer>
          )}
        {stepRecovery &&
          !isSentPassword &&
          !isSentAccount &&
          !isRecoveryPassword &&
          !isChangePassword &&
          !isActivate && (
            <CardContainer>
              <p style={{ fontSize: '20px', marginBottom: '1rem' }}>
                {stepRecovery === 1
                  ? 'Recuperar contraseña'
                  : 'Activar/Recuperar cuenta'}
              </p>
              <p style={{ marginBottom: '1rem' }}>
                Danos más información con respecto a tu cuenta.
              </p>
              <Formik
                initialValues={{
                  email: '',
                }}
                initialErrors={{
                  email: '',
                }}
                validate={(values) => {
                  const errors = {};

                  if (!values.email) {
                    errors.email = 'El correo es un campo requerido.';
                  } else if (!isValidEmail(values.email)) {
                    errors.email = 'No es un correo válido.';
                  }

                  return errors;
                }}
                onSubmit={async (values) => {
                  setEmail(values.email.trim());
                  try {
                    await postData(
                      `${
                        stepRecovery === 1
                          ? '/users/request_recovery_password/'
                          : '/users/resend/'
                      }`,
                      {
                        email: values.email.trim(),
                      }
                    ).then((data) => {
                      if (stepRecovery === 1) {
                        if (data.statusCode === 400) {
                          setMailError(true);
                          setOpenBackdrop(false);
                          return false;
                        }
                        setIsSentPassword(true);
                      } else {
                        if (data.statusCode === 400) {
                          if (
                            data.nonFieldErrors[0].includes(
                              'User has already been verified'
                            )
                          ) {
                            setIsActivate(true);
                          }
                          setMailError(true);
                          setOpenBackdrop(false);
                          return false;
                        }
                        setIsSentAccount(true);
                      }
                      setOpenBackdrop(false);
                    });
                  } catch (e) {}
                }}
              >
                {({ values, isSubmitting, handleSubmit, isValid }) => {
                  return (
                    <Form onSubmit={handleSubmit} className={classes.form}>
                      <InputText
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo electrónico"
                        name="email"
                        autoComplete="email"
                        value={values.email}
                      />
                      {mailError && (
                        <div style={{ color: '#D14343' }}>
                          Asegúrate de ingresar correctamente el correo,
                          inténtalo de nuevo.
                        </div>
                      )}
                      <Button
                        style={{ marginTop: '1rem' }}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isSubmitting || !isValid}
                      >
                        Continuar
                      </Button>
                      <Button
                        style={{ marginTop: '1rem' }}
                        fullWidth
                        variant="outlined"
                        color="primary"
                        className={classes.submit}
                        disabled={isSubmitting}
                        onClick={() => setStepRecovery(null)}
                      >
                        Volver
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </CardContainer>
          )}
        {isRecoveryPassword && !isChangePassword && (
          <>
            {isVerify && (
              <CardContainer>
                <p style={{ fontSize: '20px', marginBottom: '1rem' }}>
                  Restablecer contraseña
                </p>
                <p style={{ marginBottom: '1rem' }}>
                  Indícanos tu nueva contraseña.
                </p>
                <Formik
                  initialValues={{
                    password: '',
                    passwordConfirmation: '',
                  }}
                  initialErrors={{
                    password: '',
                    passwordConfirmation: '',
                  }}
                  validate={(values) => {
                    const errors = {};

                    if (!values.password) {
                      errors.password =
                        'Sin tu contraseña no podemos registrarte.';
                    } else if (values.password.length < 8) {
                      errors.password =
                        'La contraseña debe tener al menos 8 caracteres.';
                    }

                    if (!values.passwordConfirmation) {
                      errors.passwordConfirmation =
                        'Debes repetir tu contraseña.';
                    } else if (values.passwordConfirmation.length < 8) {
                      errors.passwordConfirmation =
                        'La contraseña debe tener al menos 8 caracteres.';
                    } else if (
                      values.password !== values.passwordConfirmation
                    ) {
                      errors.passwordConfirmation =
                        'La contraseña no es igual a la ingresada anteriormente.';
                    }

                    return errors;
                  }}
                  onSubmit={async (values) => {
                    setOpenBackdrop(true);
                    setPasswordError(false);
                    try {
                      await patchData(`/users/change_password/`, {
                        token,
                        password: values.password,
                        passwordConfirmation: values.passwordConfirmation,
                      }).then((data) => {
                        if (data.statusCode === 400) {
                          setPasswordError(true);
                          setOpenBackdrop(false);
                          return false;
                        }
                        setIsRecoveryPassword(false);
                        setIsVerify(null);
                        setIsChangePassword(true);
                        setOpenBackdrop(false);
                      });
                    } catch (e) {}
                  }}
                >
                  {({ values, isSubmitting, handleSubmit, isValid }) => {
                    return (
                      <Form onSubmit={handleSubmit} className={classes.form}>
                        <Grid
                          container
                          alignItems="center"
                          className={classes.gridLayout}
                        >
                          <Grid item md={12}>
                            <Typography className={classes.formTitle}>
                              Contraseña:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={12}>
                            <InputText
                              variant="outlined"
                              name="password"
                              value={values.password}
                              type="password"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required
                              inputProps={{
                                minLength: 6,
                                maxLength: 20,
                              }}
                              fullWidth
                            />
                          </Grid>
                          {passwordError && (
                            <div style={{ color: '#D14343' }}>
                              La contraseña indicada es muy común, intenta con
                              otra.
                            </div>
                          )}
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          className={classes.gridLayout}
                        >
                          <Grid item md={12}>
                            <Typography className={classes.formTitle}>
                              Repetir Contraseña:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={12}>
                            <InputText
                              name="passwordConfirmation"
                              value={values.passwordConfirmation}
                              type="password"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                minLength: 6,
                                maxLength: 20,
                              }}
                              required
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={isSubmitting || !isValid}
                        >
                          Restablecer contraseña
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </CardContainer>
            )}
            {isVerify === false && (
              <CardContainer>
                <p style={{ fontSize: '20px', marginBottom: '1rem' }}>
                  ¡La activación de contraseña ha expirado!
                </p>
                <p>
                  Entra al <b> apartado de recuperación</b> en caso de que
                  necesites generar un nuevo link de recuperación.
                </p>
                <Box
                  sx={{
                    marginTop: '2rem',
                    textAlign: 'right',
                  }}
                >
                  <Button
                    onClick={() => {
                      setIsRecoveryPassword(false);
                      setIsVerify(null);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Recuperar contraseña
                  </Button>
                </Box>
              </CardContainer>
            )}
          </>
        )}
        {!isRecoveryPassword && isChangePassword && (
          <CardContainer style={{ minHeight: '250px' }}>
            <p style={{ fontSize: '20px', marginBottom: '1rem' }}>
              ¡Se ha actualizado tu contraseña con éxito!
            </p>
            <p style={{ marginBottom: '1rem' }}>
              Ya puedes volver a ingresar a nuestro museo.
            </p>
            <span
              style={{ position: 'absolute', bottom: '2rem', right: '2rem' }}
            >
              <Button
                onClick={() => history.push('/')}
                variant="contained"
                color="primary"
              >
                Volver al inicio
              </Button>
            </span>
          </CardContainer>
        )}
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Recovery;
