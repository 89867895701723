import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import ArticlesHome from './ArticlesHome';
import ArticlesConfiguration from './ArticlesConfiguration';
import { Box } from '@mui/material';

const Container = styled.div`
  height: 100%;
`;

const Articles = () => {
  const match = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route exact path={`${match.url}`}>
          <Box sx={{ padding: '32px', height: '100%' }}>
            <ArticlesHome />
          </Box>
        </Route>

        <Route path={`${match.url}/crear`}>
          <Box sx={{ padding: '32px', backgroundColor: '#fcf6ec' }}>
            <ArticlesConfiguration articlesUrl={match.url} />
          </Box>
        </Route>

        <Route path={`${match.url}/editar/:articleId`}>
          <Box sx={{ padding: '32px', backgroundColor: '#fcf6ec' }}>
            <ArticlesConfiguration articlesUrl={match.url} />
          </Box>
        </Route>
      </Switch>
    </Container>
  );
};

export default Articles;
