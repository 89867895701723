import React, { useEffect } from 'react';
import { styled } from '@mui/styles';
import { Box } from '@mui/system';
import logo from 'assets/images/logo.png';
import communeLogo from 'assets/images/commune_logo.png';
import gobiernoLogo from 'assets/images/gobierno_logo.png';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useUserDataContext } from 'contexts/UserDataContext';

const Toolbar = styled('div')({
  height: '74px',
  background: '#EB8242',
  boxShadow:
    '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  position: 'fixed',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '1rem',
});

const CardContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
  borderRadius: '8px',
  padding: '2rem',
  maxWidth: '640px',
  minHeight: '400px',
  background: 'white',
});

const Container = styled('div')({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
  padding: '2rem',
  height: '100%',
  backgroundColor: '#fcf6ec',
  paddingTop: '106px',
});

const Activate = ({ isMobile }) => {
  const history = useHistory();
  const { email } = useUserDataContext();

  useEffect(() => {
    if (!email) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Toolbar
        style={{
          justifyContent: isMobile ? 'center' : 'end',
        }}
      >
        <Box
          sx={{ display: 'flex' }}
          style={{
            width: isMobile ? '100%' : '',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={logo}
            alt=""
            style={{ width: '100px', marginRight: '1.5rem' }}
          />
          <img
            src={communeLogo}
            alt=""
            style={{ width: '100px', marginRight: '1.5rem' }}
          />
          <img src={gobiernoLogo} alt="" style={{ width: '60px' }} />
        </Box>
      </Toolbar>
      <Container>
        <CardContainer>
          <p style={{ fontSize: '20px', marginBottom: '1rem' }}>
            ¡Registro exitoso! - Revisa tu correo
          </p>
          <p style={{ marginBottom: '1rem' }}>
            Entra en tu correo electrónico <b>{email}</b> y haz clic en el
            enlace de
            <b> activación de cuenta</b> que te enviamos.
          </p>
          <p>
            Podría tardar unos minutos en aparecer, asegúrate de revisar tus
            carpetas de spam y correos no deseados — ¡por si acaso!
          </p>
          <span style={{ position: 'absolute', bottom: '2rem', right: '2rem' }}>
            <Button
              onClick={() => history.push('/')}
              variant="contained"
              color="primary"
            >
              Listo
            </Button>
          </span>
        </CardContainer>
      </Container>
    </>
  );
};

export default Activate;
