import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Title from './Title';

const styles = StyleSheet.create({
  container: {
    paddingTop: '32px',
  },
  leftColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  title: {
    fontSize: 11,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  description: {
    fontSize: 11,
    color: '#65748B',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '4px',
    flex: '1',
  },
});

const OriginalContext = ({ article }) => (
  <View style={styles.container}>
    <Title article={article}>Contexto Original</Title>
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.title}>Nombre del sitio - </Text>
        <Text style={styles.description}>
          {article.siteName && article.siteName.length > 0
            ? article.siteName
            : 'Sin datos'}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Comuna - </Text>
        <Text style={styles.description}>
          {article.comune && article.comune.length > 0
            ? article.comune
            : 'Sin datos'}
        </Text>
      </View>
    </View>
    <View style={styles.row}>
      <Text style={styles.title}>Localidad - </Text>
      <Text style={styles.description}>
        {article.locality && article.locality.length > 0
          ? article.locality
          : 'Sin datos'}
      </Text>
    </View>
  </View>
);

export default OriginalContext;
