import { Button } from '@mui/material';
import { styled } from '@mui/styles';
import { Box } from '@mui/system';
import { getData } from 'api/queries';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import ImageGallery from 'react-image-gallery';
import './image-gallery.scss';
import { useUserDataContext } from 'contexts/UserDataContext';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Index from './Pdfs/public/Index';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const CardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '2rem',
  boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
  borderRadius: '8px',
});

const Title = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontSize: '18px',
});

const Table = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
});

const Tr = styled('tr')({
  borderBottom: '3px solid #F3F4F6',

  '&:first-child': {
    borderTop: '3px solid #F3F4F6',
  },

  '&:last-child': {
    borderBottom: '0',
  },
});

const Td = styled('td')({
  textAlign: 'left',
  padding: '1.5rem',
  verticalAlign: 'middle',
});

export const collectionStyle = {
  1: {
    name: 'Colección Arqueológica',
    color: '#FEB460',
  },
  2: {
    name: 'Colección Histórica',
    color: '#bc0b0b',
  },
  3: {
    name: 'Colección Bioantropológica',
    color: '#A9C3B6',
  },
  4: {
    name: 'Colección Etnográfica',
    color: '#8FB8CA',
  },
  5: {
    name: 'Otra colección',
    color: '#6D8B74',
  },
  6: {
    name: 'Sin colección',
    color: '#CCCCCC',
  },
};

export const materialsIndividual = {
  1: 'Esqueleto',
  2: 'Momia',
};

export const completeness = {
  1: 'Completo',
  2: 'Semicompleto',
  3: 'Incompleto',
};

export const genderList = {
  1: 'Masculino',
  2: 'Femenino',
  3: 'Indeterminado',
  4: 'Probablemente femenino',
  5: 'Probablemente masculino',
  6: 'No observable',
  7: 'Sin información',
};

export const ageList = {
  1: 'Indeterminado',
  2: 'Feto (En útero)',
  3: 'Infante (0-3 años)',
  4: 'Niño (3-12 años)',
  5: 'Adolescente (12-20 años)',
  6: 'Adulto Joven (20-35 años)',
  7: 'Adulto Medio (35-50 años)',
  8: 'Adulto Maduro (50-100 años)',
  9: 'Otro',
  10: 'No observable',
};

const ArticleCatalog = () => {
  const history = useHistory();
  const location = useLocation();
  const { articleId } = useParams();
  const [article, setArticle] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { isStaff } = useUserDataContext();
  const [imagesPhotography, setImagesPhotography] = useState([]);
  const [currentUrlParams, setCurrentUrlParams] = useState({});

  const getImagesPhotography = (images) => {
    let array = [];
    if (images.length > 0) {
      images.forEach((image) => {
        let mainImage = '';
        let format = '';
        mainImage = image.url.split('.')[0];
        format = image.url.split('.')[1];
        if (
          format === 'jpg' ||
          format === 'JPG' ||
          format === 'png' ||
          format === 'PNG' ||
          format === 'jpeg' ||
          format === 'JPEG'
        ) {
          array.push({
            original: `${process.env.REACT_APP_URL_STORAGE}${mainImage}_large.${format}`,
            thumbnail: `${process.env.REACT_APP_URL_STORAGE}${mainImage}_large.${format}`,
          });
        }
      });
    } else {
      array.push({
        original: `${process.env.REACT_APP_URL_PUBLIC_STORAGE}/empty_state.png`,
        thumbnail: `${process.env.REACT_APP_URL_PUBLIC_STORAGE}/empty_state.png`,
      });
    }
    setImagesPhotography(array);
  };

  const getArticleData = async () => {
    setIsLoading(true);
    await getData(
      `/articles/${articleId}?view=${isStaff ? 'full' : 'single'}`
    ).then((data) => {
      setArticle(data);
      getImagesPhotography(data.uploads);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (history.location?.state?.currentUrlParams) {
      setCurrentUrlParams(history.location?.state?.currentUrlParams);
    }
    window.scrollTo(0, 0);
    if (articleId) {
      getArticleData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        padding: '2rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            if (location.state?.fromHome) {
              history.push('/museo/articulos');
            } else {
              let filtersQuery = '';
              if (currentUrlParams) {
                const params = currentUrlParams;

                if (params.page) {
                  filtersQuery = `page=${params.page}`;
                }

                if (params.query) {
                  filtersQuery = `${filtersQuery}&query=${params.query}`;
                }

                if (params.functionality) {
                  filtersQuery = `${filtersQuery}&functionality=${params.functionality}`;
                }

                if (params.material) {
                  filtersQuery = `${filtersQuery}&material=${params.material}`;
                }

                if (params.collection) {
                  filtersQuery = `${filtersQuery}&collection=${params.collection}`;
                }
              }
              history.push(`/museo/catalogo?${filtersQuery}`);
            }
          }}
          sx={{ marginBottom: '2rem' }}
        >
          Volver
        </Button>
        {isLoading ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={36}
            width={140}
            sx={{ borderRadius: '8px' }}
          />
        ) : (
          <PDFDownloadLink
            document={<Index article={article} />}
            fileName={`Museo_quillota_articulo_${articleId}`}
            style={{ textDecoration: 'none' }}
          >
            {({ loading }) =>
              loading ? (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginBottom: '2rem' }}
                >
                  Cargando...
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginBottom: '2rem' }}
                  endIcon={<PictureAsPdfIcon />}
                >
                  Descargar
                </Button>
              )
            }
          </PDFDownloadLink>
        )}
      </Box>
      <Box sx={{}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span
            style={{
              fontSize: '20px',
              color: '#444444',
            }}
          >
            {isLoading ? (
              <Skeleton variant="text" animation="wave" width="16rem" />
            ) : (
              `Nº: ${article.inventaryNumber} - ${article.name}`
            )}
          </span>
          <span
            style={{
              color: '#9a9a9a',
              fontSize: '16px',
            }}
          >
            {isLoading ? (
              <Skeleton variant="text" animation="wave" width="16rem" />
            ) : (
              `${
                article.collection
                  ? collectionStyle[article.collection].name
                  : collectionStyle[6].name
              }`
            )}
          </span>
        </Box>
        <Box
          sx={{
            border: `2px solid ${
              article.collection
                ? collectionStyle[article.collection].color
                : collectionStyle[6].color
            }`,
            marginTop: '8px',
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CardContainer style={{ width: '40rem', padding: '1rem' }}>
          {isLoading ? (
            <Skeleton animation="wave" variant="rectangular" height={400} />
          ) : (
            <ImageGallery items={imagesPhotography} />
          )}
        </CardContainer>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <CardContainer style={{ flex: 'auto', marginRight: '2rem' }}>
          <Box
            sx={{
              padding: '1.5rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Title>Contexto Original</Title>
          </Box>
          <Table>
            <Tr>
              <Td style={{ width: '30%' }}>Nombre del sitio</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.siteName && article.siteName.length > 0 ? (
                  article.siteName
                ) : (
                  'Sin datos'
                )}
              </Td>
            </Tr>
            <Tr>
              <Td style={{ width: '30%' }}>Comuna</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.comune && article.comune.length > 0 ? (
                  article.comune
                ) : (
                  'Sin datos'
                )}
              </Td>
            </Tr>
            <Tr>
              <Td style={{ width: '30%' }}>Localidad</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.locality && article.locality.length > 0 ? (
                  article.locality
                ) : (
                  'Sin datos'
                )}
              </Td>
            </Tr>
          </Table>
        </CardContainer>
        <CardContainer style={{ flex: 'auto' }}>
          <Box
            sx={{
              padding: '1.5rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Title>Temporalidad</Title>
          </Box>
          <Table>
            <Tr>
              <Td style={{ width: '30%' }}>Periodo</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.period && article.period.length > 0 ? (
                  article.period
                ) : article.periodNoRef ? (
                  'Sin periodo asociado'
                ) : (
                  'Sin datos'
                )}
              </Td>
            </Tr>
            <Tr>
              <Td style={{ width: '30%' }}>Adscripción cultural</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.culturalAscription &&
                  article.culturalAscription.length > 0 ? (
                  article.culturalAscription
                ) : article.culturalAscriptionNoRef ? (
                  'Sin adscripción cultural asociada'
                ) : (
                  'Sin datos'
                )}
              </Td>
            </Tr>
            <Tr>
              <Td style={{ width: '30%' }}>Cronología absoluta</Td>
              <Td style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.absoluteChronology ? (
                  `${
                    article.temporalityYearsBase
                      ? article.temporalityYearsBase
                      : '-'
                  } ${
                    article.temporalityType ? article.temporalityType : '-'
                  } ± ${
                    article.temporalityYearsSigma
                      ? article.temporalityYearsSigma
                      : '-'
                  }`
                ) : (
                  'Sin cronología'
                )}
              </Td>
            </Tr>
          </Table>
        </CardContainer>
      </Box>
      <CardContainer>
        <Box
          sx={{
            padding: '1.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title>
            Identificación {article.collection !== 3 ? 'del material' : ''}
          </Title>
        </Box>
        {article.collection !== 3 ? (
          <>
            <Table>
              <Tr>
                <Td style={{ width: '20%' }}>Material</Td>
                <Td style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    <>
                      {article.collection &&
                        article.collection !== 3 &&
                        article.material.map((m, index) => {
                          const rowLen = article.material.length;
                          return (
                            <>
                              {rowLen === index + 1
                                ? `${m.name}`
                                : `${m.name}, `}
                            </>
                          );
                        })}
                      {!article.collection && 'Sin colección'}
                    </>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td style={{ width: '20%' }}>Tipo de manufactura</Td>
                <Td style={{ color: '#65748B' }} colSpan="2">
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.typeManufacture ? (
                    article.typeManufacture
                  ) : (
                    'Sin datos'
                  )}
                </Td>
              </Tr>
            </Table>
          </>
        ) : (
          <>
            <Table>
              <Tr>
                <Td style={{ width: '20%' }}>Material</Td>
                <Td style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    <>
                      {article.materialIndividual !== '3'
                        ? materialsIndividual[article.materialIndividual]
                        : article.materialIndividual === '3'
                        ? article.otherMaterialIndividual
                        : 'Sin datos'}
                    </>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td style={{ width: '20%' }}>Completitud</Td>
                <Td style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    <>
                      {article.completeness !== '4'
                        ? completeness[article.completeness]
                        : article.completeness === '4'
                        ? article.otherCompleteness
                        : 'Sin datos'}
                    </>
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td style={{ width: '20%' }}>Sexo</Td>
                <Td style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    article.sexIndividual && genderList[article.sexIndividual]
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td style={{ width: '20%' }}>Edad</Td>
                <Td style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    article.ageIndividual && ageList[article.ageIndividual]
                  )}
                </Td>
              </Tr>
            </Table>
          </>
        )}
      </CardContainer>
    </Box>
  );
};

export default ArticleCatalog;
