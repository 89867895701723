import React from 'react';
import { Text } from '@react-pdf/renderer';

const Subtitle = ({ children }) => (
  <Text
    style={{
      fontFamily: 'Lato Bold',
      fontSize: 11,
      marginBottom: 10,
      marginTop: 8,
      textTransform: 'uppercase',
      borderTopWidth: 2,
      borderTopColor: '#65748B',
      borderTopStyle: 'solid',
      borderBottomWidth: 2,
      borderBottomColor: '#65748B',
      borderBottomStyle: 'solid',
      paddingBottom: '4px',
      paddingTop: '6px',
    }}
  >
    {children}
  </Text>
);

export default Subtitle;
