import React from 'react';
import { Avatar, Divider, Grid, Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import logo from 'assets/images/logo.png';
import communeLogo from 'assets/images/commune_logo.png';
import gobiernoLogo from 'assets/images/gobierno_logo.png';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { collectionStyle } from 'views/Articles/ArticlesHome';
import emptyState from 'assets/images/empty_state.png';
import ReactPaginate from 'react-paginate';
import { MotionPage } from 'components/StylesForm/StylesForm';
import { useUserDataContext } from 'contexts/UserDataContext';
import { Footer } from 'App';

export const CardComponent = ({ article, withoutAvatar, currentUrlParams }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { id, collection, inventaryNumber, photography, material } = article;

  let isValidImage = false;
  let mainImage = '';
  let format = '';

  if (photography) {
    mainImage = photography.split('.')[0];
    format = photography.split('.')[1];
    if (
      format === 'jpg' ||
      format === 'JPG' ||
      format === 'png' ||
      format === 'PNG' ||
      format === 'jpeg' ||
      format === 'JPEG'
    ) {
      isValidImage = true;
    }
  }

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      sx={{
        paddingRight: '0.5rem',
        paddingBottom: '0.5rem',
      }}
    >
      <Box
        onClick={() => {
          history.push({
            pathname: `${match.url}/articulo/${id}`,
            state: {
              currentUrlParams: {
                page: currentUrlParams.get('page'),
                query: currentUrlParams.get('query'),
                material: currentUrlParams.get('material'),
                collection: currentUrlParams.get('collection'),
                functionality: currentUrlParams.get('functionality'),
              },
            },
          });
        }}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          transition: '0.3s',
          cursor: 'pointer',
          backgroundColor: 'white',
          borderRadius: '4px',

          '&:hover': {
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
          },
        }}
      >
        <>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: '90px',
              width: '192px',
              justifyContent: 'center',
              backgroundColor:
                collection !== null
                  ? collectionStyle[collection].color
                  : collectionStyle[5].color,
              padding: '0.5rem',
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
            }}
          >
            <img
              style={{ height: '100%', borderRadius: '4px', maxWidth: '100%' }}
              src={
                isValidImage
                  ? `${process.env.REACT_APP_URL_STORAGE}${mainImage}_medium.${format}`
                  : emptyState
              }
              alt="Imagen de objeto"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0.5rem',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', marginBottom: '6px' }}>
              {!withoutAvatar && (
                <Avatar
                  sx={{
                    bgcolor:
                      collection !== null
                        ? collectionStyle[collection].color
                        : collectionStyle[5].color,
                    marginRight: '12px',
                  }}
                >
                  {collection !== null
                    ? collectionStyle[collection].svg
                    : collectionStyle[5].svg}
                </Avatar>
              )}
              <Box
                sx={{
                  flex: '1 1 auto',
                }}
              >
                <span
                  style={{
                    margin: 0,
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                    display: 'block',
                  }}
                >
                  {inventaryNumber}
                </span>
                <span
                  style={{
                    margin: '0px',
                    fontWeight: 400,
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {collection !== null
                    ? collectionStyle[collection].name
                    : collectionStyle[6].name}
                </span>
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                fontWeight: 400,
                fontSize: '0.875rem',
                display: 'block',
                marginTop: '4px',
                marginLeft: '4px',
                color: '#65748b',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {!collection && 'Sin colección'}
              {collection === 3 && 'Individuo'}
              {collection !== 3 &&
                material.map((m, index) => {
                  const rowLen = material.length;
                  return (
                    <>{rowLen === index + 1 ? `${m.name}` : `${m.name}, `}</>
                  );
                })}
            </Box>
          </Box>
        </>
      </Box>
    </Grid>
  );
};

const CatalogListMobile = ({
  pagination,
  setPagination,
  getArticlesActive,
  isLoadingActive,
  isFiltered,
  filters,
}) => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  const { direction } = useUserDataContext();
  const handlePageClickActive = (e) => {
    if (isLoadingActive) {
      return false;
    }

    const selectedPage = e.selected;

    setPagination({
      ...pagination,
      currentPage: selectedPage,
      forcePage: selectedPage,
    });

    getArticlesActive(selectedPage + 1);
  };

  return (
    <>
      <Box>
        <MotionPage direction={direction}>
          <Box>
            {isLoadingActive && (
              <Box
                sx={{
                  paddingTop: '0.5rem',
                  paddingLeft: '0.5rem',
                  backgroundColor: '#F7F7F7',
                }}
              >
                <Grid
                  container
                  sx={{
                    overflow: 'scroll',
                    paddingBottom: '1rem',
                  }}
                >
                  {Array.from(Array(8)).map((_, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={3}
                      key={index}
                      sx={{
                        paddingRight: '0.5rem',
                        paddingBottom: '0.5rem',
                      }}
                    >
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height={90}
                        sx={{ borderRadius: '4px' }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            {!isLoadingActive && pagination.data && pagination.data.length > 0 && (
              <Box
                sx={{
                  paddingTop: '0.5rem',
                  paddingLeft: '0.5rem',
                  backgroundColor: '#F7F7F7',
                }}
              >
                <Grid
                  container
                  sx={{
                    paddingBottom: '1rem',
                  }}
                >
                  {pagination.data.map((article, index) => (
                    <CardComponent
                      key={index}
                      article={article}
                      getArticlesActive={getArticlesActive}
                      currentUrlParams={currentUrlParams}
                    />
                  ))}
                </Grid>
              </Box>
            )}

            {!isLoadingActive &&
              pagination.data &&
              pagination.data.length === 0 &&
              isFiltered && (
                <Box
                  sx={{
                    padding: '1rem 1rem 0rem 1rem',
                    backgroundColor: '#F7F7F7',
                  }}
                >
                  <Box
                    sx={{
                      height: '9rem',
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      justifyContent: 'center',
                      backgroundColor: '#CCCCCC',
                      color: '#fff',
                      borderRadius: '8px',
                      fontSize: '18px',
                      fontWeight: '500',
                      padding: '1rem',
                      textAlign: 'center',
                    }}
                  >
                    No se encontraron fichas con los filtros seleccionados
                  </Box>
                </Box>
              )}

            {!isLoadingActive &&
              pagination.data &&
              pagination.data.length === 0 &&
              !isFiltered &&
              filters.query.length === 0 &&
              filters.material.length === 0 &&
              filters.functionality.length === 0 && (
                <Box
                  sx={{
                    padding: '2rem 2rem 0rem 2rem',
                    backgroundColor: '#F7F7F7',
                  }}
                >
                  <Box
                    sx={{
                      height: '9rem',
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      justifyContent: 'center',
                      backgroundColor: '#CCCCCC',
                      color: '#fff',
                      borderRadius: '8px',
                      fontSize: '18px',
                      fontWeight: '500',
                    }}
                  >
                    Aún no se han agregado fichas al catálogo.
                  </Box>
                </Box>
              )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pagination.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClickActive}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={pagination.forcePage}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                backgroundColor: '#FCF6EC',
                padding: '2rem',
              }}
            >
              <Box>
                <div>
                  <img src={logo} alt="" style={{ width: '7rem' }} />
                </div>
                <div>
                  <img src={communeLogo} alt="" style={{ width: '7rem' }} />
                </div>
              </Box>
              <img src={gobiernoLogo} alt="" style={{ width: '8rem' }} />
            </Box>
            <Box sx={{ height: '100px', backgroundColor: '#999388' }}></Box>
          </Box>
        </MotionPage>
      </Box>
      <Footer />
    </>
  );
};

export default CatalogListMobile;
