import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #EB8242',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

export default function ModalContext({
  open,
  onClose,
  callback,
  title,
  content,
  buttonTitle,
}) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            {content}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '2rem',
              }}
            >
              <Button variant="contained" color="primary" onClick={callback}>
                {buttonTitle}
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
