import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Title from './Title';

const styles = StyleSheet.create({
  container: {
    paddingTop: '32px',
  },
  leftColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  title: {
    fontSize: 11,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  description: {
    fontSize: 11,
    color: '#65748B',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '4px',
    flex: '1',
  },
});

const Temporality = ({ article }) => {
  return (
    <>
      <View style={styles.container}>
        <Title article={article}>Temporalidad</Title>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Periodo - </Text>
            <Text style={styles.description}>
              {article.period && article.period.length > 0
                ? article.period
                : article.periodNoRef
                ? 'Sin periodo asociado'
                : 'Sin datos'}
            </Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Adscripción cultural - </Text>
            <Text style={styles.description}>
              {article &&
              article.culturalAscription &&
              article.culturalAscription.length > 0
                ? article.culturalAscription
                : article.culturalAscriptionNoRef
                ? 'Sin adscripción cultural asociada'
                : 'Sin datos'}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <Text style={styles.title}>Cronología absoluta - </Text>
          <Text style={styles.description}>
            {article && article.absoluteChronology
              ? `${article.temporalityYearsBase} ${article.temporalityType} ± ${article.temporalityYearsSigma}`
              : 'Sin cronología'}
          </Text>
        </View>
      </View>
    </>
  );
};

export default Temporality;
