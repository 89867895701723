import { FormControlLabel, Checkbox } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

const CheckboxCustom = ({ label, onChange, value, disabled, ...props }) => {
  const { checked } = props;
  const [field] = useField(props);
  return (
    <FormControlLabel
      {...field}
      control={
        <Checkbox
          color="primary"
          checked={checked}
          value={value}
          disabled={disabled}
        />
      }
      label={label}
      onChange={onChange}
    ></FormControlLabel>
  );
};

export default CheckboxCustom;
