import { motion } from 'framer-motion';

const fromPage = (page, value, stepNumber, directionPage) => {
  let direction = directionPage;
  switch (page) {
    case 'fromHome':
      direction = value && stepNumber === 1 ? 1 : directionPage;
      break;
    default:
      break;
  }
  return direction;
};

export const Motion = ({
  children,
  stepNumber,
  fromPageValue,
  page,
  directionPage,
}) => {
  const pageVariant = (direction) => {
    return {
      initial: {
        opacity: 0.5,
        x: `${direction * 50}vw`,
      },
      in: {
        opacity: 1,
        x: 0,
      },
      out: {
        opacity: 0.5,
        x: `${direction * -50}vw`,
      },
    };
  };

  const pageTransition = {
    x: { type: 'spring', duration: 0.333, bounce: 0.4 },
  };

  const direction = fromPage(page, fromPageValue, stepNumber, directionPage);

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariant(direction)}
      transition={pageTransition}
    >
      {children}
    </motion.div>
  );
};
