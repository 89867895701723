import { Box } from '@mui/system';
import React from 'react';
import { useUserDataContext } from 'contexts/UserDataContext';
import { Motion } from 'utils/motion';
import { styled } from '@mui/styles';
import { Form, Formik } from 'formik';
import { Fab, FormControl, Grid, InputAdornment } from '@mui/material';
import SelectText from 'components/Select/Select';
import { collections, materials } from 'views/Articles/articlesArray';
import SearchIcon from '@mui/icons-material/Search';
import InputText from 'components/Input/InputText';
import TuneIcon from '@mui/icons-material/Tune';
import { useHistory } from 'react-router-dom';

const Title = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '18px',
  color: '#545c68',
  marginBottom: '1rem',
});

const Subtitle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '16px',
  padding: '4px 12px',
  color: '#65748B',
  backgroundColor: '#eeeeee',
});

const HelperText = styled('div')({
  fontSize: '12px',
  color: '#65748B',
  paddingLeft: '4px',
  paddingTop: '6px',
  fontStyle: 'italic',
});

const CatalogFiltersMobile = ({
  filters,
  setFilters,
  setIsFiltered,
  pagination,
  setPagination,
}) => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  const { direction, setUserAttribute } = useUserDataContext();
  const history = useHistory();

  const filterSearch = () => {
    if (
      filters.query.length > 0 ||
      filters.material.length !== 0 ||
      filters.functionality.length !== 0 ||
      filters.collection.length !== 0
    ) {
      setIsFiltered(true);
    }

    let filtersQuery = '';

    if (filters.query.length > 0) {
      filtersQuery = `&query=${filters.query}`;
    } else {
      currentUrlParams.delete('query');
    }

    if (filters.material.length !== 0) {
      filtersQuery = `${filtersQuery}&material=${filters.material}`;
    } else {
      currentUrlParams.delete('material');
    }

    if (filters.functionality.length !== 0) {
      filtersQuery = `${filtersQuery}&functionality=${filters.functionality}`;
    } else {
      currentUrlParams.delete('functionality');
    }

    if (filters.collection.length !== 0) {
      filtersQuery = `${filtersQuery}&collection=${filters.collection}`;
    } else {
      currentUrlParams.delete('collection');
    }

    setPagination({
      ...pagination,
      currentPage: 0,
      forcePage: 0,
    });
    setUserAttribute('direction', -1);
    history.push(`/museo/catalogo?page=1${filtersQuery}`);
  };

  return (
    <>
      <Motion directionPage={direction}>
        <Box sx={{ padding: '1rem' }}>
          <Formik
            initialValues={{
              query: currentUrlParams.get('query')
                ? currentUrlParams.get('query')
                : '',
              functionality: currentUrlParams.get('functionality')
                ? currentUrlParams.get('functionality')
                : '',
              material: currentUrlParams.get('material')
                ? currentUrlParams.get('material')
                : '',
              collection: currentUrlParams.get('collection')
                ? currentUrlParams.get('collection')
                : '',
            }}
            validate={(values) => {
              const errors = {};

              return errors;
            }}
          >
            {({
              values,
              isSubmitting,
              isValid,
              setFieldValue,
              ...formikProps
            }) => {
              const handleChange = (event) => {
                const { name, value } = event.target;
                formikProps.setFieldTouched(event.target.name, false);
                formikProps.handleChange(event);
                setFilters({ ...filters, [name]: value });
              };

              const handleBlur = (event) => {
                const { name } = event.target;
                formikProps.handleBlur(event);
                const trimmedValue = event.target.value.trim();
                setFieldValue(event.target.name, trimmedValue);
                setFilters({ ...filters, [name]: trimmedValue });
              };

              const clearValue = (name) => {
                setFieldValue(name, '');
                setFilters({ ...filters, [name]: '' });
                currentUrlParams.delete(name);
              };

              return (
                <Form style={{ display: 'flex', flexDirection: 'column' }}>
                  <Title>
                    <span style={{ fontWeight: 500 }}>Ordenar fichas por</span>
                    <span
                      onClick={() => {
                        currentUrlParams.delete('query');
                        currentUrlParams.delete('functionality');
                        currentUrlParams.delete('material');
                        currentUrlParams.delete('collection');
                        setFieldValue('query', '');
                        setFieldValue('functionality', '');
                        setFieldValue('material', '');
                        setFieldValue('collection', '');
                        setFilters({
                          query: '',
                          functionality: '',
                          material: '',
                          collection: '',
                        });
                      }}
                      style={{ fontSize: '12px' }}
                    >
                      Limpiar todo
                    </span>
                  </Title>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <InputText
                        sx={{ backgroundColor: 'white' }}
                        variant="outlined"
                        placeholder="Palabras clave *"
                        required
                        fullWidth
                        name="query"
                        value={values.query}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        onBlur={handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <HelperText>
                        * Número de inventario, nombre del sitio, comuna,
                        localidad, periodo, adcripción cultural, detalles de
                        material
                      </HelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <Subtitle>
                        <span style={{ fontWeight: 500 }}>Funcionalidad</span>
                        <span
                          onClick={() => clearValue('functionality')}
                          style={{ fontSize: '12px' }}
                        >
                          Limpiar
                        </span>
                      </Subtitle>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <SelectText
                          required
                          fullWidth
                          name="functionality"
                          list={[
                            { value: 1, label: 'Funerario' },
                            { value: 2, label: 'Doméstico' },
                            { value: 3, label: 'Tarea' },
                          ]}
                          onChange={(event) => {
                            handleChange(event);
                          }}
                          sx={{ backgroundColor: 'white' }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Subtitle>
                        <span style={{ fontWeight: 500 }}>Material</span>
                        <span
                          onClick={() => clearValue('material')}
                          style={{ fontSize: '12px' }}
                        >
                          Limpiar
                        </span>
                      </Subtitle>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <SelectText
                          required
                          fullWidth
                          name="material"
                          list={materials}
                          onChange={(event) => {
                            handleChange(event);
                          }}
                          sx={{ backgroundColor: 'white' }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Subtitle>
                        <span style={{ fontWeight: 500 }}>Colección</span>
                        <span
                          onClick={() => clearValue('collection')}
                          style={{ fontSize: '12px' }}
                        >
                          Limpiar
                        </span>
                      </Subtitle>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <SelectText
                          required
                          fullWidth
                          name="collection"
                          list={collections}
                          onChange={(event) => {
                            handleChange(event);
                          }}
                          sx={{ backgroundColor: 'white' }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Fab
                    variant="extended"
                    color="primary"
                    aria-label="add"
                    sx={{ marginTop: '1rem' }}
                    onClick={() => filterSearch()}
                  >
                    <TuneIcon sx={{ mr: 1 }} />
                    Aplicar filtros
                  </Fab>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Motion>
    </>
  );
};

export default CatalogFiltersMobile;
