import { useMutation, useDeleteMutation } from './index';

export const useArticleCreateMutation = () => {
  return useMutation('/articles/');
};

export const useArticleUpdateMutation = (articleId) => {
  return useMutation(`/articles/${articleId}/`, {
    method: 'PATCH',
  });
};

export const useArticleDeleteMutation = (articleId) => {
  return useDeleteMutation(`/articles/${articleId}`, {
    method: 'DELETE',
  });
};

export const useDeletePhotoMutation = (photoId) => {
  return useDeleteMutation(`/uploads/${photoId}/`, {
    method: 'DELETE',
  });
};
