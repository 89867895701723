import { styled } from '@mui/styles';
import { Box } from '@mui/system';
import { getData } from 'api/queries';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import ImageGallery from 'react-image-gallery';
import './image-gallery.scss';
import { useUserDataContext } from 'contexts/UserDataContext';
import {
  ageList,
  completeness,
  genderList,
  materialsIndividual,
} from './ArticleCatalog';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Index from './Pdfs/public/Index';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const CardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '2rem',
  boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
  borderRadius: '8px',
});

const Title = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
});

const Table = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
});

const Tr = styled('tr')({
  borderBottom: '3px solid #F3F4F6',

  '&:first-child': {
    borderTop: '3px solid #F3F4F6',
  },

  '&:last-child': {
    borderBottom: '0',
  },
});

const Td = styled('td')({
  textAlign: 'left',
  padding: '1rem',
  verticalAlign: 'middle',
});

export const collectionStyle = {
  1: {
    name: 'Colección Arqueológica',
    color: '#FEB460',
  },
  2: {
    name: 'Colección Histórica',
    color: '#bc0b0b',
  },
  3: {
    name: 'Colección Bioantropológica',
    color: '#A9C3B6',
  },
  4: {
    name: 'Colección Etnográfica',
    color: '#8FB8CA',
  },
  5: {
    name: 'Otra colección',
    color: '#6D8B74',
  },
  6: {
    name: 'Sin colección',
    color: '#CCCCCC',
  },
};

const ArticleMobileCatalog = () => {
  const { articleId } = useParams();
  const [article, setArticle] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { isStaff } = useUserDataContext();
  const [imagesPhotography, setImagesPhotography] = useState([]);

  const getImagesPhotography = (images) => {
    let array = [];
    if (images && images.length > 0) {
      images.forEach((image) => {
        let mainImage = '';
        let format = '';
        mainImage = image.url.split('.')[0];
        format = image.url.split('.')[1];
        if (
          format === 'jpg' ||
          format === 'JPG' ||
          format === 'png' ||
          format === 'PNG' ||
          format === 'jpeg' ||
          format === 'JPEG'
        ) {
          array.push({
            original: `${process.env.REACT_APP_URL_STORAGE}${mainImage}_large.${format}`,
            thumbnail: `${process.env.REACT_APP_URL_STORAGE}${mainImage}_large.${format}`,
          });
        }
      });
    } else {
      array.push({
        original: `${process.env.REACT_APP_URL_PUBLIC_STORAGE}/empty_state.png`,
        thumbnail: `${process.env.REACT_APP_URL_PUBLIC_STORAGE}/empty_state.png`,
      });
    }
    setImagesPhotography(array);
  };

  const getArticleData = async () => {
    setIsLoading(true);
    await getData(
      `/articles/${articleId}?view=${isStaff ? 'full' : 'single'}`
    ).then((data) => {
      setArticle(data);
      getImagesPhotography(data.uploads);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (articleId) {
      getArticleData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        padding: '2rem',
      }}
    >
      <Box sx={{}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              style={{
                fontSize: '16px',
                color: '#444444',
              }}
            >
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : (
                `Nº: ${article.inventaryNumber} - ${article.name}`
              )}
            </span>
            <span
              style={{
                color: '#9a9a9a',
                fontSize: '14px',
              }}
            >
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="16rem" />
              ) : (
                `${
                  article.collection
                    ? collectionStyle[article.collection].name
                    : collectionStyle[6].name
                }`
              )}
            </span>
          </Box>
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={36}
              width={80}
              sx={{ borderRadius: '8px' }}
            />
          ) : (
            <PDFDownloadLink
              document={<Index article={article} />}
              fileName={`Museo_quillota_articulo_${articleId}`}
              style={{ textDecoration: 'none' }}
            >
              {({ loading }) =>
                loading ? (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginBottom: '2rem' }}
                  >
                    ...
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginBottom: '2rem' }}
                    endIcon={<DownloadIcon />}
                  >
                    PDF
                  </Button>
                )
              }
            </PDFDownloadLink>
          )}
        </Box>
        <Box
          sx={{
            border: `2px solid ${
              article.collection
                ? collectionStyle[article.collection].color
                : collectionStyle[6].color
            }`,
            marginTop: '8px',
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CardContainer style={{ width: '40rem', padding: '1rem' }}>
          {isLoading ? (
            <Skeleton animation="wave" variant="rectangular" height={186} />
          ) : (
            <ImageGallery items={imagesPhotography} />
          )}
        </CardContainer>
      </Box>
      <CardContainer>
        <Box
          sx={{
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title>Contexto Original</Title>
        </Box>
        <Table>
          <Tr>
            <Td>
              Nombre del sitio <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.siteName.length > 0 ? (
                  article.siteName
                ) : (
                  'Sin datos.'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Comuna <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.comune.length > 0 ? (
                  article.comune
                ) : (
                  'Sin datos.'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Localidad <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.locality.length > 0 ? (
                  article.locality
                ) : (
                  'Sin datos.'
                )}
              </span>
            </Td>
          </Tr>
        </Table>
      </CardContainer>
      <CardContainer>
        <Box
          sx={{
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title>Temporalidad</Title>
        </Box>
        <Table>
          <Tr>
            <Td>
              Periodo <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.period.length > 0 ? (
                  article.period
                ) : article.periodNoRef ? (
                  'Sin periodo asociado'
                ) : (
                  'Sin datos.'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Adscripción cultural <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.culturalAscription.length > 0 ? (
                  article.culturalAscription
                ) : article.culturalAscriptionNoRef ? (
                  'Sin adscripción cultural asociada'
                ) : (
                  'Sin datos.'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Cronología absoluta <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.absoluteChronology ? (
                  `${
                    article.temporalityYearsBase
                      ? article.temporalityYearsBase
                      : '-'
                  } ${
                    article.temporalityType ? article.temporalityType : '-'
                  } ± ${
                    article.temporalityYearsSigma
                      ? article.temporalityYearsSigma
                      : '-'
                  }`
                ) : (
                  'Sin cronología.'
                )}
              </span>
            </Td>
          </Tr>
        </Table>
      </CardContainer>
      <CardContainer>
        <Box
          sx={{
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title>
            Identificación {article.collection !== 3 ? 'del material' : ''}
          </Title>
        </Box>
        {article.collection !== 3 ? (
          <Table>
            <Tr>
              <Td style={{ width: '20%' }}>
                Material <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    <>
                      {article.collection &&
                        article.collection !== 3 &&
                        article.material.map((m, index) => {
                          const rowLen = article.material.length;
                          return (
                            <>
                              {rowLen === index + 1
                                ? `${m.name}`
                                : `${m.name}, `}
                            </>
                          );
                        })}
                      {!article.collection && 'Sin colección'}
                    </>
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Tipo de manufactura <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.typeManufacture ? (
                    article.typeManufacture
                  ) : (
                    'Sin datos'
                  )}
                </span>
              </Td>
            </Tr>
          </Table>
        ) : (
          <Table>
            <Tr>
              <Td style={{ width: '20%' }}>
                Material <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    <>
                      {article.materialIndividual !== '3'
                        ? materialsIndividual[article.materialIndividual]
                        : article.materialIndividual === '3'
                        ? article.otherMaterialIndividual
                        : 'Sin datos'}
                    </>
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td style={{ width: '20%' }}>
                Completitud <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    <>
                      {article.completeness !== '4'
                        ? completeness[article.completeness]
                        : article.completeness === '4'
                        ? article.otherCompleteness
                        : 'Sin datos'}
                    </>
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td style={{ width: '20%' }}>
                Sexo <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    article.sexIndividual && genderList[article.sexIndividual]
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td style={{ width: '20%' }}>
                Edad <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    article.ageIndividual && ageList[article.ageIndividual]
                  )}
                </span>
              </Td>
            </Tr>
          </Table>
        )}
      </CardContainer>
    </Box>
  );
};

export default ArticleMobileCatalog;
