import { styled } from '@mui/styles';
import { Box } from '@mui/system';
import { getData } from 'api/queries';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import ImageGallery from 'react-image-gallery';
import './image-gallery.scss';
import { useUserDataContext } from 'contexts/UserDataContext';
import {
  ageList,
  completeness,
  genderList,
  materialsIndividual,
} from './ArticleCatalog';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Index from './Pdfs/private/Index';
import { Button, Grid } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import {
  completenessIdentification,
  conservations,
  findings,
  functionalities,
  months,
  origins,
  records,
  setNumber,
} from './ArticlePrivateCatalog';
import { CardComponent } from './CatalogListMobile';
import TableComponent from 'components/Table/Table';
import { format, parseISO } from 'date-fns';

const CardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '2rem',
  boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
  borderRadius: '8px',
});

const Title = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontSize: '18px',
  fontWeight: 500,
});

const Table = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
});

const Tr = styled('tr')({
  borderBottom: '3px solid #F3F4F6',

  '&:first-child': {
    borderTop: '3px solid #F3F4F6',
  },

  '&:last-child': {
    borderBottom: '0',
  },
});

const Td = styled('td')({
  textAlign: 'left',
  padding: '1rem',
  verticalAlign: 'middle',
});

export const collectionStyle = {
  1: {
    name: 'Colección Arqueológica',
    color: '#FEB460',
  },
  2: {
    name: 'Colección Histórica',
    color: '#bc0b0b',
  },
  3: {
    name: 'Colección Bioantropológica',
    color: '#A9C3B6',
  },
  4: {
    name: 'Colección Etnográfica',
    color: '#8FB8CA',
  },
  5: {
    name: 'Otra colección',
    color: '#6D8B74',
  },
  6: {
    name: 'Sin colección',
    color: '#CCCCCC',
  },
};

const ArticlePrivateMobileCatalog = () => {
  const { articleId } = useParams();
  const [article, setArticle] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { isStaff } = useUserDataContext();
  const [imagesPhotography, setImagesPhotography] = useState([]);
  const [imagesLabel, setImagesLabel] = useState([]);
  const [skeletonAsociatedArticles, setSkeletonAsociatedArticles] = useState();
  const [asociatedArticles, setAsociatedArticles] = useState([]);

  const getIndividualAsociated = async (asociatedNumber) => {
    setSkeletonAsociatedArticles(true);
    await getData(`/articles/associated_guy/${asociatedNumber}`).then(
      (response) => {
        setAsociatedArticles(response);
        setSkeletonAsociatedArticles(false);
      }
    );
  };

  const getImagesPhotography = (images) => {
    let array = [];
    if (images && images.length > 0) {
      images.forEach((image) => {
        let mainImage = '';
        let format = '';
        mainImage = image.url.split('.')[0];
        format = image.url.split('.')[1];
        if (
          format === 'jpg' ||
          format === 'JPG' ||
          format === 'png' ||
          format === 'PNG' ||
          format === 'jpeg' ||
          format === 'JPEG'
        ) {
          array.push({
            original: `${process.env.REACT_APP_URL_STORAGE}${mainImage}_large.${format}`,
            thumbnail: `${process.env.REACT_APP_URL_STORAGE}${mainImage}_large.${format}`,
          });
        }
      });
    } else {
      array.push({
        original: `${process.env.REACT_APP_URL_PUBLIC_STORAGE}/empty_state.png`,
        thumbnail: `${process.env.REACT_APP_URL_PUBLIC_STORAGE}/empty_state.png`,
      });
    }
    setImagesPhotography(array);
  };

  const getImagesLabel = (images) => {
    let array = [];
    if (images && images.length > 0) {
      images.forEach((image) => {
        array.push({
          original: `${process.env.REACT_APP_URL_STORAGE}${image.url}`,
          thumbnail: `${process.env.REACT_APP_URL_STORAGE}${image.url}`,
        });
      });
    }
    setImagesLabel(array);
  };

  const getArticleData = async () => {
    setIsLoading(true);
    await getData(
      `/articles/${articleId}?view=${isStaff ? 'full' : 'single'}`
    ).then((data) => {
      setArticle(data);
      getImagesPhotography(data.uploads.materialPhotography);
      getImagesLabel(data.uploads.labelPhotography);
      if (
        data.isGuy &&
        data.isGuy === false &&
        data.numberGuy &&
        data.numberGuy.length > 0
      ) {
        getIndividualAsociated(data.numberGuy);
      } else if (
        data.isGuy &&
        data.isGuy === true &&
        data.inventaryNumber &&
        data.inventaryNumber.length > 0
      ) {
        getIndividualAsociated(data.inventaryNumber);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (articleId) {
      getArticleData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        padding: '2rem',
      }}
    >
      <Box sx={{}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              style={{
                fontSize: '16px',
                color: '#444444',
              }}
            >
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="170px" />
              ) : (
                `Nº: ${article.inventaryNumber} - ${article.name}`
              )}
            </span>
            <span
              style={{
                color: '#9a9a9a',
                fontSize: '14px',
              }}
            >
              {isLoading ? (
                <Skeleton variant="text" animation="wave" width="170px" />
              ) : (
                `${
                  article.collection
                    ? collectionStyle[article.collection].name
                    : collectionStyle[6].name
                }`
              )}
            </span>
          </Box>
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={36}
              width={80}
              sx={{ borderRadius: '8px' }}
            />
          ) : (
            <PDFDownloadLink
              document={<Index article={article} imagesLabel={imagesLabel} />}
              fileName={`Museo_quillota_articulo_${articleId}`}
              style={{ textDecoration: 'none' }}
            >
              {({ loading }) =>
                loading ? (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginBottom: '2rem' }}
                  >
                    ...
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginBottom: '2rem' }}
                    endIcon={<DownloadIcon />}
                  >
                    PDF
                  </Button>
                )
              }
            </PDFDownloadLink>
          )}
        </Box>
        <Box
          sx={{
            border: `2px solid ${
              article.collection
                ? collectionStyle[article.collection].color
                : collectionStyle[6].color
            }`,
            marginTop: '8px',
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CardContainer style={{ width: '40rem', padding: '1rem' }}>
          {isLoading ? (
            <Skeleton animation="wave" variant="rectangular" height={186} />
          ) : (
            <ImageGallery items={imagesPhotography} />
          )}
        </CardContainer>
      </Box>
      <CardContainer>
        <Box
          sx={{
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title>Información administrativa</Title>
        </Box>
        <Table>
          <Tr>
            <Td>
              Nombre responsable <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.responsibleNameNoRef ? (
                  'Sin responsable'
                ) : (
                  article.responsibleFirstname +
                  ' ' +
                  article.responsibleLastname
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Procedencia <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : (
                  origins[article.origin]
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Año/mes de procedencia <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.originDateNoRef ? (
                  'Sin referencia de fecha'
                ) : (
                  article.originYear + '/' + months[article.originMonth]
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Asociación a informes de sitio /contexto <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.associationContext ? (
                  'Sí'
                ) : (
                  'No'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Año de ingreso MHAQ <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.yearEntry ? (
                  article.yearEntry
                ) : (
                  'Sin referencia'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Nº de caja <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : (
                  article.inventaryBox
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Ubicación actual MHAQ <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : (
                  article.logUbications[0].observations
                )}
              </span>
            </Td>
          </Tr>
        </Table>
      </CardContainer>
      <CardContainer>
        <Box
          sx={{
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title>Contexto original</Title>
        </Box>
        <Table>
          <Tr>
            <Td>
              Nombre del sitio <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.siteName ? (
                  article.siteName
                ) : (
                  'Sin datos'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Código del sitio <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.siteCode ? (
                  article.siteCode
                ) : (
                  'Sin datos'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Comuna <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.comune ? (
                  article.comune
                ) : (
                  'Sin datos'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Localidad <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.locality ? (
                  article.locality
                ) : (
                  'Sin datos'
                )}
              </span>
            </Td>
          </Tr>
          {article.hasCoordinates && (
            <>
              {article.coordinateType === 1 && (
                <>
                  <Tr>
                    <Td>
                      UTM Este <br />
                      <span style={{ color: '#65748B' }}>
                        {isLoading ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            width="16rem"
                          />
                        ) : article.coordinateInfo.utmEast ? (
                          article.coordinateInfo.utmEast
                        ) : (
                          'Sin datos'
                        )}
                      </span>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      UTM Norte <br />
                      <span style={{ color: '#65748B' }}>
                        {isLoading ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            width="16rem"
                          />
                        ) : article.coordinateInfo.utmNorth ? (
                          article.coordinateInfo.utmNorth
                        ) : (
                          'Sin datos'
                        )}
                      </span>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      Datum <br />
                      <span style={{ color: '#65748B' }}>
                        {isLoading ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            width="16rem"
                          />
                        ) : article.coordinateInfo.datum ? (
                          article.coordinateInfo.datum
                        ) : article.coordinateInfo.datumNoRef ? (
                          'Sin referencias'
                        ) : (
                          'Sin datos'
                        )}
                      </span>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      Altitud <br />
                      <span style={{ color: '#65748B' }}>
                        {isLoading ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            width="16rem"
                          />
                        ) : article.coordinateInfo.altitude ? (
                          article.coordinateInfo.altitude
                        ) : article.coordinateInfo.altitudeNoRef ? (
                          'Sin referencias'
                        ) : (
                          'Sin datos'
                        )}
                      </span>
                    </Td>
                  </Tr>
                </>
              )}
              {article.coordinateType === 2 && (
                <>
                  <Tr>
                    <Td>
                      Latitud <br />
                      <span style={{ color: '#65748B' }}>
                        {isLoading ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            width="16rem"
                          />
                        ) : article.coordinateInfo.latitude ? (
                          article.coordinateInfo.latitude
                        ) : (
                          'Sin datos'
                        )}
                      </span>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      Longitud <br />
                      <span style={{ color: '#65748B' }}>
                        {isLoading ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            width="16rem"
                          />
                        ) : article.coordinateInfo.longitude ? (
                          article.coordinateInfo.longitude
                        ) : (
                          'Sin datos'
                        )}
                      </span>
                    </Td>
                  </Tr>
                </>
              )}
              {article.coordinateType === 3 && (
                <Tr>
                  <Td>
                    Coordenadas <br />
                    <span style={{ color: '#65748B' }}>
                      {isLoading ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          width="16rem"
                        />
                      ) : (
                        'Sin coodernadas'
                      )}
                    </span>
                  </Td>
                </Tr>
              )}
            </>
          )}
          <Tr>
            <Td>
              Funcionalidad del sitio <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : (
                  article.functionalities &&
                  article.functionalities.map((func, index) => {
                    const rowLen = article.functionalities.length;
                    return (
                      <>
                        {rowLen === index + 1
                          ? `${functionalities[func]}`
                          : `${functionalities[func]}, `}
                      </>
                    );
                  })
                )}
              </span>
            </Td>
          </Tr>
          {article.otherFunctionality && (
            <Tr>
              <Td>
                Otra funcionalidad <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    article.otherFunctionality
                  )}
                </span>
              </Td>
            </Tr>
          )}
          <Tr>
            <Td style={{ fontWeight: 500, backgroundColor: '#F3F4F6' }}>
              Localización estratégica:
            </Td>
          </Tr>
          <Tr>
            <Td>
              Unidad <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.localizationUnit &&
                  article.localizationUnit.length > 0 ? (
                  article.localizationUnit
                ) : article.localizationUnitNoRef ? (
                  'Sin referencias'
                ) : (
                  'Sin datos'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Capa <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.localizationLayer &&
                  article.localizationLayer.length > 0 ? (
                  article.localizationLayer
                ) : article.localizationLayerNoRef ? (
                  'Sin referencias'
                ) : (
                  'Sin datos'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Nivel <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.localizationLevel &&
                  article.localizationLevel.length > 0 ? (
                  article.localizationLevel
                ) : article.localizationLevelNoRef ? (
                  'Sin referencias'
                ) : (
                  'Sin datos'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Observaciones <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.localizationObservations ? (
                  article.localizationObservations
                ) : (
                  'Sin datos'
                )}
              </span>
            </Td>
          </Tr>
          {article.hasFuneralContext && (
            <>
              <Tr>
                <Td style={{ fontWeight: 500, backgroundColor: '#F3F4F6' }}>
                  Contexto funerario:
                </Td>
              </Tr>
              <Tr>
                <Td>
                  Tumba/enterratorio/conjunto funerario <br />
                  <span style={{ color: '#65748B' }}>
                    {isLoading ? (
                      <Skeleton variant="text" animation="wave" width="16rem" />
                    ) : article.grave !== null ? (
                      article.grave
                    ) : (
                      'Sin datos'
                    )}
                  </span>
                </Td>
              </Tr>
              {article.numberGuy && (
                <Tr>
                  <Td>
                    Nº individuo asociado <br />
                    <span style={{ color: '#65748B' }}>
                      {isLoading ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          width="16rem"
                        />
                      ) : article.numberGuy.length > 0 ? (
                        article.numberGuy
                      ) : (
                        'Sin datos'
                      )}
                    </span>
                  </Td>
                </Tr>
              )}
              <Tr>
                <Td>
                  Nombre responsable <br />
                  <span style={{ color: '#65748B' }}>
                    {isLoading ? (
                      <Skeleton variant="text" animation="wave" width="16rem" />
                    ) : article.responsibleFuneralName ? (
                      article.responsibleFuneralName
                    ) : (
                      'Sin datos'
                    )}
                  </span>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  Fecha obtención <br />
                  <span style={{ color: '#65748B' }}>
                    {isLoading ? (
                      <Skeleton variant="text" animation="wave" width="16rem" />
                    ) : (
                      <>
                        {!article.obtainingDateDay &&
                        !article.obtainingDateDay &&
                        !article.obtainingDateDay
                          ? 'Sin datos'
                          : `${
                              article.obtainingDateDay
                                ? setNumber(article.obtainingDateDay)
                                : 'Sin día'
                            } - ${
                              article.obtainingDateMonth
                                ? setNumber(article.obtainingDateMonth)
                                : 'Sin mes'
                            } - ${
                              article.obtainingDateYear
                                ? article.obtainingDateYear
                                : 'Sin año'
                            }`}
                      </>
                    )}
                  </span>
                </Td>
              </Tr>
            </>
          )}
          <Tr>
            <Td style={{ fontWeight: 500, backgroundColor: '#F3F4F6' }}>
              Temporalidad:
            </Td>
          </Tr>
          <Tr>
            <Td>
              Periodo <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.period && article.period.length > 0 ? (
                  article.period
                ) : article.periodNoRef ? (
                  'Sin periodo asociado'
                ) : (
                  'Sin datos'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Adscripción cultural <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.culturalAscription &&
                  article.culturalAscription.length > 0 ? (
                  article.culturalAscription
                ) : article.culturalAscriptionNoRef ? (
                  'Sin adscripción cultural asociada'
                ) : (
                  'Sin datos'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Cronología absoluta <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.absoluteChronology ? (
                  `${
                    article.temporalityYearsBase
                      ? article.temporalityYearsBase
                      : '-'
                  } ${
                    article.temporalityType ? article.temporalityType : '-'
                  } ± ${
                    article.temporalityYearsSigma
                      ? article.temporalityYearsSigma
                      : '-'
                  }`
                ) : (
                  'Sin cronología'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Código lab. <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.temporalityCodeLab ? (
                  article.temporalityCodeLab
                ) : article.temporalityCodeLabNoRef ? (
                  'Sin código asociado'
                ) : (
                  'Sin datos'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Material fechado <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.temporalityMaterialDated ? (
                  article.temporalityMaterialDated
                ) : article.temporalityMaterialDatedNoRef ? (
                  'Sin material asociado'
                ) : (
                  'Sin datos'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Referencias <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.temporalityReferences ? (
                  article.temporalityReferences
                ) : article.temporalityReferencesNoRef ? (
                  'Sin referencias asociadas'
                ) : (
                  'Sin datos'
                )}
              </span>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Observaciones contexto original <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.observationsContext ? (
                  article.observationsContext
                ) : (
                  'Sin datos'
                )}
              </span>
            </Td>
          </Tr>
        </Table>
      </CardContainer>
      {article.collection && article.collection !== 3 && (
        <CardContainer>
          <Box
            sx={{
              padding: '1rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Title>Identificación del material</Title>
          </Box>
          <Table>
            <Tr>
              <Td>
                Condición de registro <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.recordCondition ? (
                    records[article.recordCondition]
                  ) : (
                    'Sin datos'
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Material <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    <>
                      {article.collection &&
                        article.collection !== 3 &&
                        article.material.map((m, index) => {
                          const rowLen = article.material.length;
                          return (
                            <>
                              {rowLen === index + 1
                                ? `${m.name}`
                                : `${m.name}, `}
                            </>
                          );
                        })}
                      {article.collection &&
                        article.collection !== 3 &&
                        article.material.length === 0 &&
                        'Material no registrado'}
                      {!article.collection && 'Sin colección'}
                    </>
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Tipo de manufactura <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.typeManufacture ? (
                    article.typeManufacture
                  ) : (
                    'Sin datos'
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td style={{ fontWeight: 500, backgroundColor: '#F3F4F6' }}>
                Dimensiones
              </Td>
            </Tr>
            <Tr>
              <Td>
                Alto <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.dimensions.height ? (
                    article.dimensions.height
                  ) : (
                    'Sin datos'
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Ancho <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.dimensions.width ? (
                    article.dimensions.width
                  ) : (
                    'Sin datos'
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Largo <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.dimensions.lenght ? (
                    article.dimensions.lenght
                  ) : (
                    'Sin datos'
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Espesor <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.dimensions.thickness ? (
                    article.dimensions.thickness
                  ) : (
                    'Sin datos'
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Diámetro <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.dimensions.diameter ? (
                    article.dimensions.diameter
                  ) : (
                    'Sin datos'
                  )}
                </span>
              </Td>
            </Tr>
          </Table>
        </CardContainer>
      )}
      {article.collection && article.collection === 3 && (
        <CardContainer>
          <Box
            sx={{
              padding: '1rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Title>Identificación</Title>
          </Box>
          <Table>
            <Tr>
              <Td>
                Contexto de hallazgo <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.typeFinding ? (
                    findings[article.typeFinding]
                  ) : (
                    'Sin datos'
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Observaciones <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.observationsFinding ? (
                    article.observationsFinding
                  ) : (
                    'Sin datos'
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Individuo <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.individual ? (
                    article.individual
                  ) : (
                    'Sin datos'
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Material <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    <>
                      {article.materialIndividual !== '3'
                        ? materialsIndividual[article.materialIndividual]
                        : article.materialIndividual === '3'
                        ? article.otherMaterialIndividual
                        : 'Sin datos'}
                      {article.materialIndividual === null && 'Sin datos'}
                    </>
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Completitud <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.completeness ? (
                    completeness[article.completeness]
                  ) : (
                    'Sin datos'
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Número de individuos <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    <>
                      {article.numberIndividual
                        ? article.numberIndividual
                        : 'Sin datos'}
                    </>
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Sexo <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.sexIndividual ? (
                    genderList[article.sexIndividual]
                  ) : (
                    'Sin datos'
                  )}
                </span>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Edad <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : article.ageIndividual ? (
                    ageList[article.ageIndividual]
                  ) : (
                    'Sin datos'
                  )}
                </span>
              </Td>
            </Tr>
          </Table>
        </CardContainer>
      )}
      <CardContainer style={{ marginBottom: '1rem', padding: '1rem' }}>
        <Box
          sx={{
            paddingBottom: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title>Ajuar / Ofrenda asociados</Title>
        </Box>
        <Box>
          {asociatedArticles.length > 0 && !skeletonAsociatedArticles && (
            <Box
              sx={{
                paddingTop: '0.5rem',
                paddingLeft: '0.5rem',
                backgroundColor: '#F7F7F7',
              }}
            >
              <Grid
                container
                sx={{
                  paddingBottom: '1rem',
                }}
              >
                {asociatedArticles.map((article, index) => (
                  <CardComponent
                    key={index}
                    article={article}
                    withoutAvatar={true}
                    redirectMobile={true}
                  />
                ))}
              </Grid>
            </Box>
          )}
          {asociatedArticles.length === 0 && !skeletonAsociatedArticles && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '16px',
                color: '#8d8d8d',
                fontWeight: '500',
                padding: '1rem',
              }}
            >
              No se han encontrado ajuares asociados con el número de individuo.
            </Box>
          )}
          {skeletonAsociatedArticles && (
            <Box
              sx={{
                paddingTop: '0.5rem',
                paddingLeft: '0.5rem',
                backgroundColor: '#F7F7F7',
              }}
            >
              <Grid
                container
                sx={{
                  overflow: 'scroll',
                  paddingBottom: '1rem',
                }}
              >
                {Array.from(Array(8)).map((_, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    key={index}
                    sx={{
                      paddingRight: '0.5rem',
                      paddingBottom: '0.5rem',
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      height={90}
                      sx={{ borderRadius: '4px' }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      </CardContainer>
      {article.collection &&
        article.collection === 3 &&
        article.uploads.bioantropologic && (
          <CardContainer style={{ padding: '1rem' }}>
            <Box
              sx={{
                paddingBottom: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Title>PDF asociados al registro biantropológico</Title>
            </Box>
            <Box>
              <TableComponent
                rows={article.uploads.bioantropologic}
              ></TableComponent>
            </Box>
          </CardContainer>
        )}
      <CardContainer>
        <Box
          sx={{
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title>Identificación del objeto</Title>
        </Box>
        <Table>
          <Tr>
            <Td>
              Estado de conservación <br />
              <span style={{ color: '#65748B' }}>
                {isLoading ? (
                  <Skeleton variant="text" animation="wave" width="16rem" />
                ) : article.conservationState ? (
                  conservations[article.conservationState]
                ) : (
                  'Sin datos'
                )}
              </span>
            </Td>
          </Tr>
          {article.collection && article.collection !== 3 && (
            <Tr>
              <Td>
                Completitud <br />
                <span style={{ color: '#65748B' }}>
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="16rem" />
                  ) : (
                    <>
                      {article.completenessIdentification !== '4' && (
                        <>
                          {
                            completenessIdentification[
                              article.completenessIdentification
                            ]
                          }
                        </>
                      )}
                      {article.completenessIdentification === '4' &&
                        (article.otherCompleteness
                          ? article.otherCompleteness
                          : 'Sin datos')}
                      {article.completenessIdentification === null &&
                        'Sin datos'}
                    </>
                  )}
                </span>
              </Td>
            </Tr>
          )}
          {article.sampling && (
            <>
              <Tr>
                <Td style={{ fontWeight: 500, backgroundColor: '#F3F4F6' }}>
                  Toma de muestras:
                </Td>
              </Tr>
              <Tr>
                <Td>
                  Proyecto asociado <br />
                  <span style={{ color: '#65748B' }}>
                    {isLoading ? (
                      <Skeleton variant="text" animation="wave" width="16rem" />
                    ) : (
                      article.samplingAssociateProject &&
                      article.samplingAssociateProject
                    )}
                  </span>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  Investigador responsable <br />
                  <span style={{ color: '#65748B' }}>
                    {isLoading ? (
                      <Skeleton variant="text" animation="wave" width="16rem" />
                    ) : (
                      article.samplingResponsibleInvestigator &&
                      article.samplingResponsibleInvestigator
                    )}
                  </span>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  Fecha <br />
                  <span style={{ color: '#65748B' }}>
                    {isLoading ? (
                      <Skeleton variant="text" animation="wave" width="16rem" />
                    ) : (
                      article.samplingDate &&
                      format(parseISO(article.samplingDate), 'dd/MM/yyyy')
                    )}
                  </span>
                </Td>
              </Tr>
              <Tr>
                <Td style={{ fontWeight: 500, backgroundColor: '#F3F4F6' }}>
                  Bitacora de toma de muestras
                </Td>
              </Tr>
              {article.uploads.sampling && (
                <Tr>
                  <Td>
                    <Box>
                      <TableComponent
                        rows={article.uploads.sampling}
                      ></TableComponent>
                    </Box>
                  </Td>
                </Tr>
              )}
              <Tr>
                <Td>
                  Observaciones generales <br />
                  <span style={{ color: '#65748B' }}>
                    {isLoading ? (
                      <Skeleton variant="text" animation="wave" width="16rem" />
                    ) : article.observationsIdentification ? (
                      article.observationsIdentification
                    ) : (
                      'Sin datos'
                    )}
                  </span>
                </Td>
              </Tr>
            </>
          )}
        </Table>
      </CardContainer>
      {imagesLabel.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CardContainer style={{ width: '40rem', padding: '1rem' }}>
            {isLoading ? (
              <Skeleton animation="wave" variant="rectangular" height={186} />
            ) : (
              <ImageGallery items={imagesLabel} />
            )}
          </CardContainer>
        </Box>
      )}
    </Box>
  );
};

export default ArticlePrivateMobileCatalog;
