import { createTheme } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#EB8242',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#edf2ff',
    },
    third: {
      main: '#EF4B4B',
      contrastText: '#ffffff',
      dark: '#ea2b2b',
    },
  },
});

export default theme;
