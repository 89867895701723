import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Title from './Title';
import Subtitle from './Subtitle';
import {
  functionalities,
  setNumber,
} from 'views/Catalog/ArticlePrivateCatalog';

const styles = StyleSheet.create({
  container: {
    paddingTop: '32px',
  },
  leftColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  title: {
    fontSize: 11,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  description: {
    fontSize: 11,
    color: '#65748B',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '4px',
    flex: '1',
  },
});

const OriginalContext = ({ article }) => (
  <View style={styles.container}>
    <Title article={article}>Contexto Original</Title>
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.title}>Nombre del sitio - </Text>
        <Text style={styles.description}>
          {article.siteName && article.siteName.length > 0
            ? article.siteName
            : 'Sin datos'}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Código del sitio - </Text>
        <Text style={styles.description}>
          {article.siteCode ? article.siteCode : 'Sin datos'}
        </Text>
      </View>
    </View>
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.title}>Comuna - </Text>
        <Text style={styles.description}>
          {article.comune && article.comune.length > 0
            ? article.comune
            : 'Sin datos'}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Localidad - </Text>
        <Text style={styles.description}>
          {article.locality && article.locality.length > 0
            ? article.locality
            : 'Sin datos'}
        </Text>
      </View>
    </View>
    {article.hasCoordinates && (
      <>
        {article.coordinateType === 1 && (
          <>
            <View style={styles.table}>
              <View style={styles.row}>
                <Text style={styles.title}>UTM Este - </Text>
                <Text style={styles.description}>
                  {article.coordinateInfo.utmEast
                    ? article.coordinateInfo.utmEast
                    : 'Sin datos'}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.title}>UTM Norte - </Text>
                <Text style={styles.description}>
                  {article.coordinateInfo.utmNorth
                    ? article.coordinateInfo.utmNorth
                    : 'Sin datos'}
                </Text>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.row}>
                <Text style={styles.title}>Datum - </Text>
                <Text style={styles.description}>
                  {article.coordinateInfo.datum
                    ? article.coordinateInfo.datum
                    : article.coordinateInfo.datumNoRef
                    ? 'Sin referencias'
                    : 'Sin datos'}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.title}>Altitud - </Text>
                <Text style={styles.description}>
                  {article.coordinateInfo.altitude
                    ? article.coordinateInfo.altitude
                    : article.coordinateInfo.altitudeNoRef
                    ? 'Sin referencias'
                    : 'Sin datos'}
                </Text>
              </View>
            </View>
          </>
        )}
        {article.coordinateType === 2 && (
          <View style={styles.table}>
            <View style={styles.row}>
              <Text style={styles.title}>Latitud - </Text>
              <Text style={styles.description}>
                {article.coordinateInfo.latitude
                  ? article.coordinateInfo.latitude
                  : 'Sin datos'}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.title}>Longitud - </Text>
              <Text style={styles.description}>
                {article.coordinateInfo.longitude
                  ? article.coordinateInfo.longitude
                  : 'Sin datos'}
              </Text>
            </View>
          </View>
        )}
        {article.coordinateType === 3 && (
          <View style={styles.table}>
            <View style={styles.row}>
              <Text style={styles.title}>Coordenadas - </Text>
              <Text style={styles.description}>Sin coodernadas</Text>
            </View>
          </View>
        )}
      </>
    )}
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.title}>Funcionalidad del sitio - </Text>
        <Text style={styles.description}>
          {article.functionalities &&
            article.functionalities.map((func, index) => {
              const rowLen = article.functionalities.length;
              return (
                <>
                  {rowLen === index + 1
                    ? `${functionalities[func]}`
                    : `${functionalities[func]}, `}
                </>
              );
            })}
        </Text>
      </View>
      {article.otherFunctionality !== null &&
        article.otherFunctionality.length > 0 && (
          <View style={styles.row}>
            <Text style={styles.title}>Otra funcionalidad - </Text>
            <Text style={styles.description}>{article.otherFunctionality}</Text>
          </View>
        )}
    </View>
    <Subtitle>Localización estratégica:</Subtitle>
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.title}>Unidad - </Text>
        <Text style={styles.description}>
          {article.localizationUnit && article.localizationUnit.length > 0
            ? article.localizationUnit
            : article.localizationUnitNoRef
            ? 'Sin referencias'
            : 'Sin datos'}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Capa - </Text>
        <Text style={styles.description}>
          {article.localizationLayer && article.localizationLayer.length > 0
            ? article.localizationLayer
            : article.localizationLayerNoRef
            ? 'Sin referencias'
            : 'Sin datos'}
        </Text>
      </View>
    </View>
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.title}>Nivel - </Text>
        <Text style={styles.description}>
          {article.localizationLevel && article.localizationLevel.length > 0
            ? article.localizationLevel
            : article.localizationLevelNoRef
            ? 'Sin referencias'
            : 'Sin datos'}
        </Text>
      </View>
    </View>
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.title}>Observaciones - </Text>
        <Text style={styles.description}>
          {article.localizationObservations
            ? article.localizationObservations
            : 'Sin datos'}
        </Text>
      </View>
    </View>
    {article.hasFuneralContext && (
      <>
        <Subtitle>Contexto funerario:</Subtitle>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>
              Tumba/enterratorio/conjunto funerario -{' '}
            </Text>
            <Text style={styles.description}>
              {article.grave !== null ? article.grave : 'Sin datos'}
            </Text>
          </View>
          {article.numberGuy !== null && article.numberGuy.length > 0 && (
            <View style={styles.row}>
              <Text style={styles.title}>Nº individuo asociado - </Text>
              <Text style={styles.description}>
                {article.numberGuy.length > 0 ? article.numberGuy : 'Sin datos'}
              </Text>
            </View>
          )}
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.title}>Nombre responsable - </Text>
            <Text style={styles.description}>
              {article.responsibleFuneralName
                ? article.responsibleFuneralName
                : 'Sin datos'}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>Fecha obtención - </Text>
            <Text style={styles.description}>
              {!article.obtainingDateDay &&
              !article.obtainingDateDay &&
              !article.obtainingDateDay
                ? 'Sin datos'
                : `${
                    article.obtainingDateDay
                      ? setNumber(article.obtainingDateDay)
                      : 'Sin día'
                  } - ${
                    article.obtainingDateMonth
                      ? setNumber(article.obtainingDateMonth)
                      : 'Sin mes'
                  } - ${
                    article.obtainingDateYear
                      ? article.obtainingDateYear
                      : 'Sin año'
                  }`}
            </Text>
          </View>
        </View>
      </>
    )}
    <Subtitle>Temporalidad:</Subtitle>
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.title}>Periodo - </Text>
        <Text style={styles.description}>
          {article.period && article.period.length > 0
            ? article.period
            : article.periodNoRef
            ? 'Sin periodo asociado'
            : 'Sin datos'}
        </Text>
      </View>
    </View>
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.title}>Adscripción cultural - </Text>
        <Text style={styles.description}>
          {article.culturalAscription && article.culturalAscription.length > 0
            ? article.culturalAscription
            : article.culturalAscriptionNoRef
            ? 'Sin adscripción cultural asociada'
            : 'Sin datos'}
        </Text>
      </View>
    </View>
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.title}>Cronología absoluta - </Text>
        <Text style={styles.description}>
          {article.absoluteChronology
            ? `${
                article.temporalityYearsBase
                  ? article.temporalityYearsBase
                  : '-'
              } ${article.temporalityType ? article.temporalityType : '-'} ± ${
                article.temporalityYearsSigma
                  ? article.temporalityYearsSigma
                  : '-'
              }`
            : 'Sin cronología'}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Código lab. - </Text>
        <Text style={styles.description}>
          {article.temporalityCodeLab
            ? article.temporalityCodeLab
            : article.temporalityCodeLabNoRef
            ? 'Sin código asociado'
            : 'Sin datos'}
        </Text>
      </View>
    </View>
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.title}>Material fechado - </Text>
        <Text style={styles.description}>
          {article.temporalityMaterialDated
            ? article.temporalityMaterialDated
            : article.temporalityMaterialDatedNoRef
            ? 'Sin material asociado'
            : 'Sin datos'}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Referencias - </Text>
        <Text style={styles.description}>
          {article.temporalityReferences
            ? article.temporalityReferences
            : article.temporalityReferencesNoRef
            ? 'Sin referencias asociadas'
            : 'Sin datos'}
        </Text>
      </View>
    </View>
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.title}>Observaciones contexto original - </Text>
      </View>
    </View>
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.description}>
          {article.observationsContext
            ? article.observationsContext
            : 'Sin datos'}
        </Text>
      </View>
    </View>
  </View>
);

export default OriginalContext;
